import { GlToastrCategory } from "../toastr-appendix";

export const RECORDS_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    save: {
      autosave: {
        error:
          "Record autosave failed, data might be lost if not manually saved.",
      },
      error_try_again:
        "Could not save record, data might be lost if you leave the page. Please try again",
    },
    sign: {
      error_try_again:
        "Could not sign record, data might be lost if you leave the page. Please try again",
    },
    re_open: {
      error_try_again: "Could not re-open record. Please try again",
    },
    state: {
      error_try_again:
        "Could not change record state, data might be lost if you leave the page. Please try again",
    },
    access_lock: {
      override: {
        error_try_again: "Could not override error lock. Please try again",
      },
    },
    injection: {
      create: {
        error_try_again: "Error creating new injection, please try again.",
      },
    },
    dilation_record: {
      sign: {
        error: "Error signing dilation review, please try again",
      },
      re_open: {
        create_before_sign:
          "Dilation record must be created first before signing, please try again.",
      },
    },
    consent_form: {
      save: {
        signed: 'Consent form is already signed, please ensure both patient and surgeon unsigns record before saving'
      },
      delete: {
        no_form: "Cannot delete consent form, please try again.",
        signed: 'Cannot delete a signed consent form, please unsign then try again.'
      }
    }
  },
  info: {},
};
