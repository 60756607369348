import { AddressHelperService } from "app/core/services/AddressHelperService/address.helper.service";
import { AlertHelperService } from "app/core/services/alert-helper.service";
import { AuthService } from "app/core/services/auth.service";
import { isNil } from "lodash";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { Patient } from "../../../../../models/user.model";
import { IPatientResource } from "../../../../core/services/patient.service";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import moment = require("moment");

export const FIELDS = [
  "first name",
  "last name",
  "preferred name",
  "dob",
  "gender",
  "address",
  "phone",
  "email",
  "medicare",
  "insurance",
  "email_authority",
  "consent_to_record",
];

export class PatientDemographicsFormController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Input()

  patient: Patient | IPatientResource;

  // for medical history
  allergiesList: string;

  // Controller Properties
  dateOfBirth: Date;
  // Datepicker settings
  // Set to track date popup state
  dobDatePopIsOpen = false;
  // Toggles the state
  datepickerOptions = {
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;
  editPatientInfo: angular.IFormController;
  /**
   * This restricts auto completion results to australia only
   */
  gPlaceAutoCompleteOptions = {
    componentRestrictions: { country: "au" },
  };

  // https://stackoverflow.com/questions/3589345/how-do-i-validate-an-australian-medicare-number
  medicareValidationRegex = /^\d{4}[ ]?\d{5}[ ]?\d{1}[- ]?\d?$/;

  // patient alert selections
  patientAlertCategoryOptions =
    this.AlertHelperService.getAlertCategoryOptionsByKey("patient");
  patientAlertSeverityOptions =
    this.AlertHelperService.getGenericAlertLevelValues();

  constructor(
    private AlertHelperService: AlertHelperService,
    private AddressHelperService: AddressHelperService,
    private AuthService: AuthService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.patient && this.patient) {
      if ("$promise" in this.patient) {
        this.patient.$promise.then(() => this.getBirthDateFromPatient());
      } else {
        this.getBirthDateFromPatient();
      }

      // settings patient notes defaults
      if (this.patient.data && isNil(this?.patient?.data?.alerts)) {
        this.patient.data.alerts = [];
      }
    }
  }

  openDatePicker() {
    this.dobDatePopIsOpen = true;
  }

  updateDob() {
    if (this.dateOfBirth) {
      this.patient.data.birth_date = this.dateOfBirth.getTime() / 1000;
    }
  }

  addressDidChange() {
    if (this.patient.data?.address?.address_components) {
      const { line_1, postal_code, suburb, state } =
        this.AddressHelperService.getAddressFromGoogleAddress(
          this.patient.data.address
        );
      this.patient.data.address_line1 = line_1;
      this.patient.data.postcode = postal_code;
      this.patient.data.suburb = suburb;
      this.patient.data.state = state;
    } else {
      delete this.patient.data.address_line1;
      delete this.patient.data.postcode;
      delete this.patient.data.suburb;
      delete this.patient.data.state;
    }
  }

  getField(googleAddress: google.maps.places.PlaceResult, fieldName: string) {
    const component = (googleAddress?.address_components ?? []).find((c) => {
      return c.types.includes(fieldName);
    });

    return component?.short_name;
  }

  // allows dev's to edit file_no or anything else
  isAdminDevAccount() {
    return this.AuthService.isAdminDevAccount();
  }

  handleFormUpdate() {
    // if any addition/deletion are added, have to set as dirty
    if (!this.editPatientInfo.$dirty) {
      this.editPatientInfo.$setDirty();
    }
  }

  // show patient alerts?
  showPatientAlerts() {
    return (
      this.AlertHelperService.shouldShowAlerts(this.patient) ||
      // or if there are any allergies
      this.allergiesList
    );
  }

  private getBirthDateFromPatient() {
    if (["number", "string"].includes(typeof this.patient?.data?.birth_date)) {
      this.dateOfBirth = moment(this.patient.data.birth_date, "X").toDate();
    }
  }
}

export class PatientDemographicsFormComponent
  implements angular.IComponentOptions
{
  static selector = "patientDemographicsForm";
  static template = require("./patient-demographics-form.html");
  static controller = PatientDemographicsFormController;
  static bindings = {
    patient: "<",
    allergiesList: "<",
    ...GlFormControllerBindings,
  };
}
