import { IOrthancDicomTags } from "app/core/services/orthanc-api/orthanc.models";


/**
 * @deprecated
 * most likely to be legacy code before Pliny existed
 */
export class DicomFieldController implements angular.IController {
  dicomTags: IOrthancDicomTags;
  dicomId: string;

  constructor() {
    "ngInject";
  }
}

/**
 * @deprecated
 * most likely to be legacy code before Pliny existed
 */
export class DicomFieldComponent implements angular.IComponentOptions {
  static selector = "dicomField";
  static template = require("./dicom-field.html");
  static controller = DicomFieldController;
  static bindings = {
    dicomTags: "<",
    dicomId: "<"
  };
}
