import { GenderType } from "models/user.model";

export const pipeName = "gender";
export function genderPipe() {
  "ngInject";
  return (gender: GenderType) => {
    switch (gender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "O":
        return "Other";
      default:
        return "";
    }
  };
}
