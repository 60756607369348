import * as angular from "angular";
import { IPromise } from "angular";
import { omit } from "lodash";
import { Contact } from "models/contact.model";
import { GlProvider } from "models/patient-record.model";
import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { Letter } from "../../../models/letter.model";
import { API_PATH_v2 } from "./api-paths";
import { PdfPrintService } from "./pdf-print.service";

export class LetterService {
  static injectionName = "LetterService";
  private cache: angular.ICacheObject;
  private patientId: number;
  private recordId: number;
  private letters: Letter[];
  private letterHistory: Letter[];

  private letterContainerTemplate: string = require("../../../app/pages/print-letter/components/letter-conatainer/letter-container.component.html");

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $http: angular.IHttpService,
    private $window: angular.IWindowService,
    private $state: angular.ui.IStateService,
    private $rootScope: angular.IRootScopeService,
    private $compile: angular.ICompileService,
    private $timeout: angular.ITimeoutService,
    private PdfPrintService: PdfPrintService
  ) {
    "ngInject";
    this.cache = this.$cacheFactory.get("$http");
  }

  init(patientId: number, recordId?: number) {
    this.patientId = patientId;
    this.recordId = recordId;
    this.letters = this.letterHistory = [];
    return this.fetchAllForPatient();
  }

  getLetters() {
    return this.letters;
  }

  getLetterHistory() {
    return this.letterHistory;
  }

  fetchAllForPatient() {
    return this.getAll(this.patientId).then((letters) => {
      if (this.recordId) {
        this.letters = letters.filter(
          (l) => l.patient_record_id === this.recordId
        );
      }
      this.letterHistory = this.recordId
        ? letters.filter((l) => l.patient_record_id !== this.recordId)
        : this.letters;
      return letters;
    });
  }

  createForPatient(letter: Partial<Letter>) {
    return this.create(this.patientId, letter);
  }

  getAll(patientId: number, recordId?: number) {
    const params = this.recordId ? { record_id: recordId } : null;
    return this.$http
      .get<IGlApiResponse<Letter[]>>(this.getPath(patientId), { params })
      .then((response) => response.data.data);
  }

  get(patientId: number, letterId: number) {
    return this.$http
      .get<Letter>(this.getPath(patientId, letterId))
      .then((response) => response.data);
  }

  getLettersFromClinic({ clinicId, date }: { clinicId: number; date: string }) {
    const params: any = {};
    params.date = date;
    params.clinicId = clinicId;
    return this.$http
      .get<IGlApiResponse<Letter[]>>(
        `${this.API_URL}${API_PATH_v2}/clinic_letters`,
        { params }
      )
      .then((response) => response.data.data);
  }

  create(patientId: number, data: Partial<Letter>) {
    return this.$http
      .post<IGlApiResponse<Letter>>(this.getPath(patientId), data)
      .then((response) => response.data.data);
  }

  sendEmail(
    letter: Letter,
    emailTo: string,
    emailFrom: string,
    referrer: string,
    subject: string,
    emailBody: string,
    attachment: string
  ) {
    return this.$http
      .post(`${this.API_URL}${API_PATH_v2}/send_emails`, {
        email_to: emailTo,
        email_from: emailFrom,
        addressee: referrer,
        clinic_id: letter.clinic_id,
        user_id: letter.patient_id,
        subject: subject,
        email_body: emailBody,
        attachment: attachment.replace(/^data:.+;base64,/, ""),
      })
      .then((response) => response.data);
  }

  update(letter: Letter) {
    return this.$http
      .put<IGlApiResponse<Letter>>(
        this.getPath(letter.patient_id, letter.id),
        letter
      )
      .then((response) => response.data.data);
  }

  delete(letter: Letter) {
    return this.$http
      .delete(this.getPath(letter.patient_id, letter.id))
      .then((response) => response.data);
  }

  getPath(patientId: number, letterId?: number) {
    const basePath = `${this.API_URL}${API_PATH_v2}/patients/${patientId}/letters`;
    if (letterId) {
      return `${basePath}/${letterId}`;
    }
    return basePath;
  }

  getProviderNameToDisplay(referrer: GlProvider) {
    if (referrer == null) {
      return;
    }
    const { firstName, lastName, clinicName } = referrer;
    if (firstName || lastName) {
      let name = `${firstName} ${lastName}`;
      if (clinicName) {
        name += ` (${clinicName})`;
      }
      return name;
    } else {
      return clinicName;
    }
  }

  contactAddresseeNameFormat(contact: Contact) {
    if (contact?.first_name || contact?.last_name) {
      let name = `${contact?.first_name} ${contact?.last_name}`;
      if (contact?.clinic_name) {
        name += ` (${contact?.clinic_name})`;
      }
      return name;
    } else {
      return contact?.clinic_name;
    }
  }

  openPrintLetterWindow(
    patientId: number,
    letterIds: number[],
    printOnLoad: boolean = false,
    addressees: string
  ) {
    const url = this.$state.href(
      "print-letter",
      {
        patientId,
        letterId: letterIds.join("-"),
        print: printOnLoad,
        addressees,
      },
      { absolute: false }
    );
    this.$window.open(
      url,
      "letter_printer",
      "width=740,height=700,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
    );
  }

  openPrintLetterWindowMultipleRecipients(
    patientId: number,
    letterIds: number[],
    printOnLoad: boolean = false,
    addressees: object
  ) {
    const addresseesToPrint = Object.keys(addressees)
      .filter((k) => k !== "referrer")
      .join(",");
    const url = this.$state.href(
      "print-letter",
      {
        patientId,
        letterId: letterIds.join("-"),
        print: printOnLoad,
        addressees: addresseesToPrint,
      },
      { absolute: false }
    );
    this.$window.open(
      url,
      "letter_printer",
      "width=740,height=700,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
    );
  }

  openPdfLetterWindow(
    patientId: number,
    letterIds: number[],
    pdfOnLoad: boolean = false,
    addressees?: string
  ) {
    const url = this.$state.href(
      "print-letter",
      {
        patientId,
        letterId: letterIds.join("-"),
        pdf: pdfOnLoad,
        addressees,
      },
      { absolute: false }
    );
    this.$window.open(url, "_blank");
  }

  openPdfForEmail(
    patientId: number,
    letterIds: number[],
    email: boolean = false,
    addressees?: string
  ) {
    const url = this.$state.href(
      "print-letter",
      {
        patientId,
        letterId: letterIds.join("-"),
        email: email,
        addressees,
      },
      { absolute: false }
    );
    this.$window.open(url, "_blank");
  }

  // fetches the primary CC recipeint
  getLetterCC(letter: Letter, key: string) {
    return Object.keys(omit(letter?.addressees, [key, "referrer"]))[0];
  }

  // fetches referrer for letter aka primary addresee if existing
  getLetterReferrer(letter: Letter) {
    return letter?.addressees?.find((a) => a.referrer);
  }

  // given some letter data, generate a printable letter
  generateLetterBase64FromElementReference({
    headerData,
    footerData,
    pageData,
    background = false,
  }: {
    headerData: string;
    footerData: string;
    pageData: string;
    background: boolean;
  }) {
    return this.PdfPrintService.print({
      headerData,
      footerData,
      pageData,
      background,
    }).then((letterPdf: Blob) => {
      // convert to base64
      return this.blobToBase64String(letterPdf);
    });
  }

  private blobToBase64String(blob: Blob): IPromise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    }).then((b64String: string) => b64String);
  }
}
