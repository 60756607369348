import { GlModelDefaultMode } from "app/core/directive/gl-model";
import { isEmpty, isFunction, isNil, isUndefined } from "lodash";
import { Appendix, IGlOption } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

export class GlSelectController
  extends GlFormController
  implements angular.IController, angular.IOnChanges, angular.IOnInit
{
  copy: boolean;
  default: IGlOption;
  enable: boolean;
  field: any;
  key: string;
  optionKey: string;
  options: IGlOption[];
  customOptions: IGlOption[];
  useCustomOptions: boolean = false;
  other = true;
  otherKey: string;
  path: string;
  ngRequired = true;
  title: string;
  // gl-model default mode
  defaultMode?: GlModelDefaultMode;
  // @Output
  glChange: (arg: { path: string; key: string; oldValue: any }) => void;
  onChange: (arg: { option: IGlOption }) => void;

  // undoAutofillDiagnosisSingle: (arg: { key: string;  }) => void;
  // confirmAutofillDiagnosisSingle: (arg: {
  //   diagnosis: IGlOption;
  //   key: string;
  // }) => void;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  $onInit(): void {
    if (this.optionKey) {
      this.options = this.appendix.get(this.optionKey);
    } else {
      this.options = this.appendix.get(this.key);
    }
  }

  $onChanges(): void {
    if (isUndefined(this.other)) {
      this.other = true;
    }

    if (isEmpty(this.otherKey)) {
      this.otherKey = this.defaultOtherKey();
    }
  }

  defaultOtherKey(): string {
    return `${this.key}_other`;
  }

  getPath(): string {
    if (this.path) {
      return `${this.path}.${this.key}`;
    }
    return this.key;
  }

  getOtherPath(): string {
    if (this.path) {
      return `${this.path}.${this.otherKey}`;
    }
    return this.otherKey;
  }

  // custom options will only be used if not empty and toggled
  getOptions() {
    return this.useCustomOptions &&
      !isNil(this.customOptions) &&
      !isEmpty(this.customOptions)
      ? this.customOptions
      : this.options;
  }

  // if custom options in use then use custom options
  // else follow gl-model
  getDefaultOption() {
    // if use custom options enabled, always use default left
    // otherwise do left or right as usual
    return this?.default ?? null;
  }

  shouldShowDescription(val: IGlOption) {
    if (val) {
      const keyOpt = this.options.find((opt) => opt.key === val.key);
      const shouldShowOther = (keyOpt && keyOpt.showOther) || false;
      return (shouldShowOther || val.key === "other") && this.other === true
        ? true
        : false;
    } else {
      return false;
    }
  }

  // for display mode
  shouldDisplayOption(option: IGlOption) {
    // as long as it has a name and key isnt part of the hidden ones
    return this.appendix.shouldDisplayOption(option);
  }

  // on changes
  handleOnChange(option: IGlOption) {
    if (isFunction(this.onChange)) {
      this.onChange({ option });
    }
  }
}

export class GlSelect implements angular.IComponentOptions {
  static selector = "glSelect";
  static template = require("./gl-select.html");
  static controller = GlSelectController;
  static bindings = {
    copy: "<",
    default: "<?",
    /**
     * This sets the default-mode for gl-model directive. For description of
     * modes, see gl-model.directive.
     */
    defaultMode: "@?",
    enable: "<",
    field: "<",
    isEditable: "<?",
    key: "@",
    mode: "@?",
    ngRequired: "<?",
    optionKey: "@",
    other: "<",
    otherKey: "@",
    glChange: "&?",
    /**
     * if this key is not at the root level of the field object, then use the
     * path input to set a relative path. ie:
     * {management:{diagnosis_progression}} has a key 'diagnosis_progression'
     * and a path 'management'
     **/
    path: "@?",
    title: "@",
    undoAutofillDiagnosis: "&",
    confirmAutofillDiagnosis: "&",

    // toggle state between custom options or regular
    useCustomOptions: "<?",
    customOptions: "<?",

    // callback
    onChange: "&?",
  };
}
