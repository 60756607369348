import { IPromise } from "angular";
import { isEmpty, isNil, set } from "lodash";
import { GlAlert } from "models/alert.model";
import { Patient, User } from "../../../models/user.model";
import { API_PATH, API_PATH_v2 } from "./api-paths";
import { AuthService } from "./auth.service";
export interface IGlBraintreeToken {
  token: string;
}
export type IPatientResourceClass =
  angular.resource.IResourceClass<IPatientResource>;

export interface IPatientResource
  extends Patient,
    angular.resource.IResource<Patient> {
  $updateCreditCard: (params: { token: string }) => IPromise<IPatientResource>;
  $update(): IPromise<User>;
}

export class PatientService {
  static injectionName = "PatientService";
  apiBase = `${this.API_URL}${API_PATH}`;
  patientPath = `${this.apiBase}/patient/:id`;
  apiBaseV2 = `${this.API_URL}${API_PATH_v2}`;
  patientPathV2 = `${this.apiBaseV2}/patients/:id`;
  patientResource = this.$resource(
    this.patientPath,
    { id: "@id" },
    {
      save: { method: "POST", url: this.patientPathV2 },
      update: { method: "PUT", url: `${this.apiBase}/user/:id` },
      updateCreditCard: {
        method: "POST",
        url: `${this.patientPath}/credit-card`,
      },
    }
  ) as IPatientResourceClass;
  private cache = this.$cacheFactory.get("$http");

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService,
    private $http: angular.IHttpService,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  get(id: number) {
    // sometimes undefined is fetched, on page navigation out, we try to avoid these
    if (!id) {
      return;
    }
    const patient = this.patientResource.get({ id }, (patient) => {
      patient = this.mapPatientAlertsResource(patient);
      return patient;
    });

    return patient;
  }

  async getAllPatients() {
    const user = await this.AuthService.getUser();
    const url = this.apiBase + "/patients/" + user.clinic_id;
    // Request to the API for the user associated with the token
    return this.$http.get<User[]>(url).then((response) => response.data);
  }

  // allow for multi-search
  async searchPatientsByName(params: Record<string, string | number>) {
    return this.AuthService.getUser()
      .then((user: User) => {
        return this.$http.get<User[]>(
          `${this.apiBase}/patients/${user?.clinic_id}`,
          { params }
        );
      })
      .then((response) => {
        return response.data;
      });
  }

  setVaccineStatus(user: User, vaccineStatus: string) {
    return this.$http
      .post(`${this.apiBase}/user/set_vaccine`, {
        vaccine_status: vaccineStatus,
        user_id: user.id,
      })
      .then((response) => response.data);
  }

  getBraintreeToken(params: { patient_id: number }) {
    const url = this.apiBase + "/braintree/token/" + params.patient_id;
    // Request to the API for the user associated with the token
    return this.$http
      .get<IGlBraintreeToken>(url)
      .then((response) => response.data);
  }

  // merge
  mergePatients(params: { old_patient_id: number; new_patient_id: number }) {
    const url = `${this.apiBaseV2}/patients/merge`;

    return this.$http.post(url, params).then((res) => res.data);
  }

  // to phase out medium alerts or anything related
  mapPatientAlertsResource(patient: IPatientResource): IPatientResource {
    // if no alerts dont bother
    if (isNil(patient?.data?.alerts) || isEmpty(patient?.data?.alerts)) {
      return patient;
    }

    // * map it out to check for any medium alerts to replace
    const newAlerts: GlAlert[] = (patient?.data?.alerts ?? []).map((a) => {
      // if any medium alerts are found, replace them with high
      if (a.level === "Medium") {
        a.level = "High";
      }

      // If the alert type is other, set its value to
      // what is in type_other or default to Other
      // this is as we are changing alert types to be generic
      if (a.type === "Other") {
        a.type = a.type_other ?? "Other";
        // remove type_other as well as thats mapped already
        delete a.type_other;
      }

      return a;
    });

    // set data and return
    set(patient, "data.alerts", newAlerts);
    return patient;
  }
}
