import { API_PATH } from "./api-paths";
import { GlPlan } from "../../../models/plan.model";
import { IGlApiResponse } from "../../../models/gl-api-response.model";

export class PlansService {
  static injectionName = "PlansService";
  private url = `${this.API_URL}${API_PATH}/plan`;
  constructor(private $http: angular.IHttpService, private API_URL: string) {
    "ngInject";
  }

  get() {
    return this.$http
      .get<IGlApiResponse<GlPlan[]>>(this.url)
      .then((response) => response.data.data);
  }
}
