import { PatientProcedureInHouse } from "../../../../../models/patient-procedure";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { IPatientResource } from "../../../../core/services/patient.service";
import {
  DocumentsService,
  IRecordDocuments,
} from "../../../services/documents-service/documents.service";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";

class AdminVisitSummaryController
  implements angular.IController, angular.IOnChanges {
  patient: IPatientResource;
  recordData: PatientRecordData;
  currentPatient: any;
  currentRecord: PatientRecord;
  recordHistory: PatientRecordData;
  documentsMap: IRecordDocuments;
  proceduresPerformed: PatientProcedureInHouse[];
  inHouseProcedures: PatientProcedureInHouse[];
  optomRecord: PatientRecord;
  loadingSubscription: boolean;
  toggleRecordsAppointments: string;

  constructor(
    private PatientRecordService: PatientRecordService,
    private DocumentsService: DocumentsService,
    private PatientProcedureService: PatientProcedureService,
    private $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.currentRecord && this.currentRecord) {
      this.documentsMap = this.DocumentsService.getNamedDocuments(
        this.currentRecord.documents
      );

      this.optomRecord = this.isVirtualReview()
        ? this.recordHistory.find(
          (d) => d.id === this.currentRecord.optom_record_id
        )
        : "";
    }
    if (this.inHouseProcedures && this.currentRecord) {
      // filter procedures performed for current record only
      this.proceduresPerformed = this.PatientProcedureService.getRecordInHouseProcedures(
        this.inHouseProcedures,
        this.currentRecord.id
      );
    }
  }

  getWhatText(data: PatientRecordData) {
    return this.PatientRecordService.getWhatStringFromRecord(data);
  }

  goToPatient(patientId: any) {
    this.$state.go("main.patient", {
      patient_id: patientId,
    });
  }

  isFirstVisit() {
    return this.recordHistory && this.recordHistory.length < 1;
  }

  isVirtualReview() {
    return !!this.currentRecord && this.currentRecord.type === "virtual_review";
  }

  isTechReview() {
    return !!this.currentRecord && this.currentRecord.type === "tech_record";
  }

  reviewDisagree() {
    return (
      this.isVirtualReview() &&
      this.currentRecord.data?.accept_review === "disagree"
    );
  }
}

export class AdminVisitSummaryComponent implements angular.IComponentOptions {
  static selector = "adminVisitSummary";
  static template = require("./admin-visit-summary.html");
  static controller = AdminVisitSummaryController;
  static bindings = {
    recordData: "<",
    patient: "<",
    currentPatient: "<",
    recordHistory: "<",
    currentRecord: "<",
    inHouseProcedures: "<",
    toggleRecordsAppointments: "<",
  };
}
