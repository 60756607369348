import { ConsentFormType, GlCataractForm, PatientConsentFormRecord, PatientRecord } from "models/patient-record.model";
import { PatientRecordService } from "../patient-record/patient-record.service";

export class ConsentFormHelperService {
  static injectionName: string = "ConsentFormHelperService";

  // consent form types extrapolated into an array
  private consentFormTypes: ConsentFormType[] = [
    "consent_form_cataract"
  ];

  constructor(
    private PatientRecordService: PatientRecordService,
  ) {
    "ngInject";
  }

  getConsentFormTypes(): ConsentFormType[] {
    return this.consentFormTypes;
  }

  getCataractConsentFormFields(): (keyof GlCataractForm)[] {
    return [
      "cataract_pre_op",
      "cataract_aim",
      "cataract_lens",
      "cataract_migs",
      "cataract_injectables",
      "cataract_steroid",
      "cataract_anaesthetic",
      "cataract_operation",
      "cataract_dry_eye",
      "cataract_post_op"
    ];
  }

  // given a list of consent forms, returns the first instance that matches 
  // the type
  findFirstInstanceOfConsentForm(
    consentForms: PatientConsentFormRecord[],
    type: ConsentFormType
  ): PatientConsentFormRecord {
    return (consentForms ?? []).find((r) => r.type === type);
  }

  // SIGNED?
  userSignedConsentForm(consentForm: PatientConsentFormRecord): boolean {
    return consentForm?.data_status === "SIGNED";
  }

  patientSignedConsentForm(consentForm: PatientConsentFormRecord): boolean {
    return (
      consentForm?.data?.signature_data?.patient?.status ===
      "SIGNED"
    );
  }

  // is type?
  consentFormIsType(consentForm: PatientConsentFormRecord, type: ConsentFormType) {
    return consentForm?.type === type;
  }

  // CONSENT FORM SIGNING/REOPENING
  reOpenConsentFormPatient(consentForm: PatientConsentFormRecord) {
    // reopen in progress
    // set as in progress again
    consentForm.status = "IN PROGRESS";
    return this.PatientRecordService.reopenAsPatient(consentForm)
      .then((_consentForm: PatientConsentFormRecord) => {
        return _consentForm;
      });
  }

  // re-open for user
  reOpenConsentFormUser(consentForm: PatientConsentFormRecord) {
    // reopen in progress
    consentForm.status = "IN PROGRESS";
    return this.PatientRecordService.reopen(consentForm)
      .then((_consentForm: PatientConsentFormRecord) => {
        return _consentForm;
      });
  }

  // fetch consent forms based on a parent id
  fetchConsentFormsForRecord(patientId: number, recordId: number) {
    return this.PatientRecordService.get(patientId, recordId)
      .then((parentRecord: PatientRecord) => {
        // return consent forms
        return parentRecord?.consent_forms ?? [];
      });
  }
}