import { GenderType } from "models/user.model";

export const pipeName = "pronounPipe";
export function pronounPipe() {
  "ngInject";
  return (gender: GenderType) => {
    if (typeof gender !== "string") {
      return gender;
    }

    // pronoun pipe, default is neutral
    switch (gender) {
      case "M":
        return "he";
      case "F":
        return "she";
      case "O":
        return "they";
      default:
        return "they";
    }
  };
}
