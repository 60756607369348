import { isNil } from "lodash";
import { GlAlert, GlAlertLevel } from "models/alert.model";
import { Patient } from "models/user.model";

export interface IGlAlertCategory {
  name: string;
  key: string;
}

const GENERIC_ALERT_LEVEL_VALUES: GlAlertLevel[] = [
  "Low",
  // "Medium",
  "High",
];

// colour coding
const ALERT_LEVEL_CLASS_MAP = {
  low: "color-green-alert", // black for default
  // medium: "color-yellow-alert", // yellow like amber
  high: "color-red-danger", // red severe
};

// different alert category types
const ALERT_CATEGORY_OPTIONS: {
  [key: string]: IGlAlertCategory[];
} = {
  patient: [
    {
      name: "Allergy",
      key: "allergy",
    },
    {
      name: "Other",
      key: "other",
    },
  ],
};

export class AlertHelperService {
  static injectionName = "AlertHelperService";

  constructor() {
    "ngInject";
  }

  getGenericAlertLevelValues() {
    return GENERIC_ALERT_LEVEL_VALUES;
  }

  getAlertLevelClassMapping() {
    return ALERT_LEVEL_CLASS_MAP;
  }

  getAlertCategoryOptionsByKey(key: string) {
    const res: IGlAlertCategory[] = ALERT_CATEGORY_OPTIONS[key];
    return !isNil(res) ? res : [];
  }

  filterAllergyAlerts(alerts: GlAlert[]): GlAlert[] {
    return (alerts ?? []).filter(
      (a) =>
        a.type?.toLowerCase() === "allergy" ||
        // this is for legacy in case
        (a?.type?.toLowerCase() === "other" &&
          a?.type_other?.toLowerCase()?.includes("allergy"))
    );
  }

  // given an allergy message, convert it
  convertAllergyToAlert(allergy: string): GlAlert {
    return {
      message: allergy,
      level: "High",
      type: "Allergy",
    };
  }

  // is there an alert level of this type amongst the alerts?
  hasAlertLevelType(alerts: GlAlert[], level: GlAlertLevel): boolean {
    return alerts?.filter((a) => a.level === level)?.length > 0;
  }

  hasAlertLevelTypes(alerts: GlAlert[], levels: GlAlertLevel[]): boolean {
    return alerts?.filter((a) => levels.includes(a.level))?.length > 0;
  }

  // basic guard for showing alerts without any filtering
  shouldShowAlerts(patient: Patient, allergies: string = "") {
    // only show if alerts exist
    // or if patient has rejected to recording consent
    // or if patient has rejected to email authority
    // or if there are any allergies present
    return (
      patient?.data?.alerts?.length ||
      patient?.data?.consent_to_record === false ||
      patient?.data?.email_authority === false ||
      allergies?.length > 0
    );
  }

  // same as above but by level instead
  shouldShowAlertsByAlertLevel(
    patient: Patient,
    levels: GlAlertLevel[],
    allergies: string = ""
  ) {
    const hasAlertsOfLevels: boolean = this.hasAlertLevelTypes(
      patient?.data?.alerts ?? [],
      levels ?? []
    );
    return (
      hasAlertsOfLevels ||
      patient?.data?.consent_to_record === false ||
      patient?.data?.email_authority === false ||
      allergies?.length > 0
    );
  }
}
