import { get, has, isNil } from "lodash";
import { GlPatientRecordType, PatientRecord } from "models/patient-record.model";

export class RecordButtonController
  implements angular.IController, angular.IOnChanges {
  record: PatientRecord;
  selected: boolean;
  dateFormat: string;
  onClick: any;
  class = "";
  tooltip = "";
  dateFilter = this.$filter("date");
  ngDisabled: boolean = false;
  isOptomReview: boolean = false;

  constructor(
    private $sce: angular.ISCEService,
    private $filter: angular.IFilterService
  ) {
    "ngInject";
  }

  $onChanges(changesObj: angular.IOnChangesObject) {
    if (has(changesObj, "record.currentValue")) {
      this.updateToolTipForRecord();
    }
  }

  // formats key information for the record as a tooltip
  // since its injected as html, we can style it and 
  // break it up for clarity with <br />
  updateToolTipForRecord() {
    // our final array that we will concat later
    const textArray: string[] = [];

    // 1. type of record
    const type: GlPatientRecordType = get(
      this.record,
      // any custom title
      "data.meta.current_state.type.title",
      // else default type of record by type
      this.getRecordType()
    );
    // our header
    textArray.push(`<b>${type}</b>`);

    // 2. appointment date or date created at (optional)
    // if no appointment date exists, we will
    // determine the appt date to be on the date it was created
    const apptCreationDate: string =
      this.dateFilter(
        get(this.record, 'appointment_date') ??
        get(this.record, 'created_at')
      );

    // if there is an appointment date, use that else use
    // the date it was created at
    textArray.push(`Created At: ${apptCreationDate}`);

    // 3. last updated at?
    // prioritise signed at date else use updated at
    const updatedAt: string = this.dateFilter(
      get(this.record, "updated_at")
    );
    textArray.push(`Last Updated: ${updatedAt}`);

    // 4. who last updated it?
    // also takes into account who signed it as sign is an update
    // or any proxy users
    const updatedByArray: string = this.getLastUpdatedBy();
    textArray.push(updatedByArray);

    // 5. (optional) proxy signed?
    const proxySignee: string = get(this.record, 'data_proxy_signed_by.name');
    // if there is a proxy signee, add it in
    if (!isNil(proxySignee)) {
      textArray.push(`Proxied By: ${proxySignee}`);
    }

    // set as tooltip html
    this.tooltip = this.$sce.trustAsHtml(textArray.join("<br/>"));
  }

  getRecordTypeClass() {
    const recordType = this.getRecordType();
    if (recordType === "Ophthal Record") {
      return "btn-ophthal-review";
    } else if (recordType === "Optom Record") {
      return "btn-optom-review";
    } else if (recordType === "History Record") {
      return "btn-record-history";
    } else {
      return "btn-tech-review";
    }
  }

  getButtonStyleClass() {
    return this.selected ? "btn-solid" : "btn-inverse";
  }

  getRecordType() {
    switch (this.record.type) {
      // TODO: do they care about differentiating between optom/ophthal records anymore
      case "patient_record":
        return this.record.virtual_review
          ? "Optom Record"
          : "Ophthal Record";
      case "virtual_review":
        return "Virtual Review";
      case "history":
        return "History Record";
      case "tech_record":
        return "Tech Record";
      default:
        return;
    }
  }

  // return as array to concat with main one
  private getLastUpdatedBy(): string {
    // find out who executed the update
    // original updatee
    const updatedBy: string = get(this.record, "data_updated_by.name");
    // who signed?
    const actualSignee: string = get(this.record, 'data_signed_by.name');
    // determine last updated by individual
    // default is whoever last updated it else go by who signed it
    const primaryUser: string =
      !isNil(actualSignee)
        ? actualSignee
        : updatedBy;



    // return as an array
    // to be added in later
    return `${!isNil(actualSignee) ? 'Signed By:' : 'Updated By:'} ${primaryUser}`;

  }
}
export class RecordButton implements angular.IComponentOptions {
  static selector = "buttonRecord";
  static bindings = {
    record: "<",
    selected: "<",
    dateFormat: "@",
    onClick: "&",
    class: "@",
    ngDisabled: "<?",
  };
  static controller = RecordButtonController;
  static template = require('./button-record.html');
}
