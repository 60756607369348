import { Appendix, IGlOption } from "app/core/services/appendix";
import { AppointmentService } from "app/core/services/appointments/appointments.service";
import { defaults } from "lodash";
import { PatientRecordData } from "models/patient-record.model";
import { Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

export class MedicalHistoryController
  extends GlFormController
  implements angular.IController, angular.IOnChanges, angular.IPostLink
{
  // @Inputs()
  showReasonForReferral = true;
  appointmentNotes?: string;

  public patient: Patient;
  public record: PatientRecordData;
  public consolidatedRecord: PatientRecordData;

  public medicalHistoryOptions = [
    {
      key: "current_smoker",
      name: "Current Smoker",
    },
    {
      key: "asthma",
      name: "Asthma",
    },
    {
      key: "hypertension",
      name: "Hypertension",
    },
    {
      key: "diabetes",
      name: "Diabetes",
    },
    {
      key: "sleep_apnea",
      name: "Sleep Apnea",
    },
    {
      key: "has_other",
      name: "Other",
    },
  ];

  public ocularHistoryOptions = [
    {
      key: "diabetic_retinopathy",
      name: "Diabetic Retinopathy",
    },
    {
      key: "macular_degeneration",
      name: "Macular Degeneration",
    },
    {
      key: "Cataract",
      name: "Cataract",
    },

    {
      key: "has_other",
      name: "Other",
    },
  ];

  constructor(
    private appendix: Appendix,
    private AppointmentService: AppointmentService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (
      (changes.record && this.record) ||
      (changes.consolidatedRecord && this.consolidatedRecord)
    ) {
      this._recordDidChange();
    }
  }

  // extra change to attempt to solve medical history not showing up issue
  // this time on full link
  $postLink(): void {
    this._recordDidChange();
  }

  hasHistory(obj: any) {
    return Object.keys(obj || {}).reduce(
      (hasHistory: boolean, k) => hasHistory || !!obj[k],
      false
    );
  }

  getNameFor(key: string, list: { key: string; name: string }[]) {
    const { name } = list.find((item) => item.key === key);
    return name;
  }

  /* getters for each field, record data is prioritised first over anything */
  getMedicalHistory() {
    return (
      this.record?.medical_history ?? this.consolidatedRecord?.medical_history
    );
  }

  getMedicationsList() {
    return (
      this.record?.medications_list ?? this.consolidatedRecord?.medications_list
    );
  }

  getHasFamilyHistory() {
    return (
      this.record?.has_family_history ??
      this.consolidatedRecord?.has_family_history
    );
  }

  getFamilyHistory() {
    return (
      this.record?.family_history ?? this.consolidatedRecord?.family_history
    );
  }

  getHasFamilyHistoryEyeDisease() {
    return (
      this.record?.has_family_history_eye_disease ??
      this.consolidatedRecord?.has_family_history_eye_disease
    );
  }

  getFamilyHistoryEyeDisease() {
    return (
      this.record?.family_history_eye_disease ??
      this.consolidatedRecord?.family_history_eye_disease
    );
  }

  getOcularHistory() {
    return (
      this.record?.ocular_history ?? this.consolidatedRecord?.ocular_history
    );
  }

  private _recordDidChange() {
    // this sets the defaults for the core fields related to
    // medical history
    // these are only get when editable and toggled
    // (e.g. when viewing in a record)
    // if not editable or just in regular display mode it will
    // only pull from the record data
    defaults(this.record, {
      presenting_complaint: this.getDefaultPresentingComplaint(),
      medications_list: this.getMedicationsList(),
      medical_history: this.getMedicalHistory(),
      has_family_history: this.getHasFamilyHistory(),
      family_history: this.getFamilyHistory(),
      has_family_history_eye_disease: this.getHasFamilyHistoryEyeDisease(),
      family_history_eye_disease: this.getFamilyHistoryEyeDisease(),
      ocular_history: this.getOcularHistory(),
    });
  }

  private getDefaultPresentingComplaint(): IGlOption {
    const presentingComplaintFromAppointmentNotes =
      this.determinePresentingComplaintFromAppointment(this.appointmentNotes);
    return (
      presentingComplaintFromAppointmentNotes ??
      (this.appendix.getDefaultKey("presentingComplaint") as IGlOption)
    );
  }

  private determinePresentingComplaintFromAppointment(
    appointmentNotes: string
  ): IGlOption {
    if (!appointmentNotes || typeof appointmentNotes !== "string") {
      return;
    }

    // determine by appointment notes
    const lowercaseAppointmentNotes = appointmentNotes.toLocaleLowerCase();
    /**
     * 104R 105R 105IJ OPINJ
     */
    const allOptions = this.appendix.get("presentingComplaint");

    if (
      this.AppointmentService.isRetinaAppointmentByRegex(
        lowercaseAppointmentNotes
      )
    ) {
      return allOptions.find((o) => o.key === "retina");
    } else if (
      this.AppointmentService.isGlaucomaAppointmentByRegex(
        lowercaseAppointmentNotes
      )
    ) {
      return allOptions.find((o) => o.key === "glaucoma");
    } else if (
      this.AppointmentService.isCataractAppointmentByRegex(
        lowercaseAppointmentNotes
      )
    ) {
      return allOptions.find((o) => o.key === "cataract");
    } else if (
      this.AppointmentService.isDryEyeAppointmentByRegex(
        lowercaseAppointmentNotes
      )
    ) {
      return allOptions.find((o) => o.key === "dry_eye");
    } else {
      return allOptions.find((o) => o.key === "cataract");
    }
  }
}

export class MedicalHistory implements angular.IComponentOptions {
  static selector = "medicalHistory";
  static template = require("./medical-history.html");
  static controller = MedicalHistoryController;
  static bindings = {
    isEditable: "<?",
    mode: "@?",
    record: "<",
    consolidatedRecord: "<",
    patient: "<",
    enableLeft: "<",
    enableRight: "<",
    showReasonForReferral: "<?",
    appointmentNotes: "<?",
  };
}
