import { GenderType } from "models/user.model";

export const pipeName = "genderTitle";
export function genderTitlePipe() {
  "ngInject";
  return (gender: GenderType) => {
    switch (gender) {
      case "M":
        return "Mr.";
      case "F":
        return "Ms.";
      case "O":
        return "Mx.";
      default:
        return "";
    }
  };
}
