/**
 * standardise how record dates are shown
 */
import { PatientRecord } from "models/patient-record.model";

export const pipeName: string = "recordDatePipe";
export const recordDatePipe: angular.Injectable<
  angular.FilterFactory
> =
  () => {
    "ngInject";
    return (record: PatientRecord) =>
      record?.appointment_date ??
      record?.data_signed_at ??
      record?.updated_at;
  };