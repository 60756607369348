/**
 * Import the Component styles
 */
import { AppointmentService } from "app/core/services/appointments/appointments.service";
import { AuthService } from "app/core/services/auth.service";
import { PageTitleService } from "app/core/services/page-title.service";
import { PatientDocumentService } from "app/core/services/patient-document.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import {
  IGlSignature,
  SignatureService,
} from "app/core/services/signature-service/signature.service";
import { SubscriptionService } from "app/core/services/subscription.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { get, isNil } from "lodash";
import { Appointment } from "models/appointment.model";
import { Contact } from "models/contact.model";
import { IGlFormMode } from "models/gl-form-mode";
import { PatientDocument } from "models/patient-document.model";
import { Subscription } from "models/subscription.model";
import { CAN_CREATE_LETTERS } from "../../../config/environment";
import { IGlInjectionRecord } from "../../../models/injection";
import { Letter } from "../../../models/letter.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../models/patient-record.model";
import { GlUserTypeString, User } from "../../../models/user.model";
import { getActiveReferral } from "../../core/pipes/active-referral.pipe";
import { ContactService } from "../../core/services/contact.service";
import { GlModelService } from "../../core/services/gl-model.service";
import { LetterService } from "../../core/services/letter.service";
import { PatientClinicService } from "../../core/services/patient-clinic.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import "./patient.scss";

import angular = require("angular");
import moment = require("moment");

export class PatientPageController
  implements
    angular.IController,
    angular.IOnInit,
    angular.IOnDestroy,
    angular.IOnChanges
{
  // @Input()
  user: User;
  // Controller Properties
  appointments: Appointment[];
  letter: Letter;
  contacts: Contact[];
  record: PatientRecord | object;
  createNewHistoryRecordInProgress = false;
  createNewInjectionInProgress = false;
  createNewRecordInProgress = false;
  createNewTechRecordInProgress = false;
  patient: IPatientResource;
  recordsToDisplay: PatientRecord[] = [];
  patientAllLetters: Letter[];
  patientId = this.$stateParams.patient_id;
  providersForm: angular.IFormController;
  providersUpdateRecordData: PatientRecordData = {};
  providersViewMode: IGlFormMode = "display";
  patientViewMode: IGlFormMode = "display";
  providerForNewRecord: number;
  // referralUrl: string;
  records: PatientRecord[];
  saveProvidersInProgress = false;
  patientDocuments: PatientDocument[];
  currentRecord: PatientRecord | object;
  selectedRecord: PatientRecord;
  dateFilter = this.$filter("date");
  signature: IGlSignature;
  activeReferral: { url: string; expiryStatus: string; klass: string };

  loadingSubscription: boolean = false;
  subscriptionDetails: Subscription;

  constructor(
    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $stateParams: angular.ui.IStateParamsService,
    private $window: angular.IWindowService,
    private AppointmentService: AppointmentService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private ContactService: ContactService,
    private PageTitleService: PageTitleService,
    private PatientProcedureService: PatientProcedureService,
    private PatientRecordService: PatientRecordService,
    private PatientService: PatientService,
    private PatientDocumentService: PatientDocumentService,
    public PatientClinicService: PatientClinicService,
    public toastr: angular.toastr.IToastrService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $filter: angular.IFilterService,
    private SignatureService: SignatureService,
    private PrescriptionsService: PrescriptionsService,
    private UserFavouriteDrugsService: UserFavouriteDrugsService,
    private SubscriptionService: SubscriptionService,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.user && this.canCreateLetter()) {
      this.SignatureService.fetchAll().then(
        ([signature] = []) => (this.signature = signature)
      );
    }
    if (changes.patientId && this.patientId) {
      this.PrescriptionsService.fetchPrescriptions(this.patientId);
    }

    if (changes.user && this.user) {
      this.updatePrescriberFavouritesCombinedHandler(this.user.id);
    }
  }

  $onInit() {
    const patient = this.updatePatientDetails();
    moment();
    patient.$promise.then((patient) =>
      this.PageTitleService.set(
        `${patient.data.first_name} ${patient.data.last_name} - History - GlaucoNet`
      )
    );
    this.PatientClinicService.initForPatient({
      patientId: this.patientId,
    });
    this.updatePatientRecordHistory();

    this.PatientProcedureService.getAllForPatient({
      patientId: this.patientId,
    });
    this.updatePatientSubscriptionDetails(this.patientId);

    this.updatePatientProcedures(this.patientId);
    this.updatePatientPrescriptions(this.patientId);
    this.updatePrescriberFavouritesCombinedHandler(this.user.id);

    this.AppointmentService.get({
      patientId: this.patientId,
      date: moment().toISOString(),
    }).then((appointments) => (this.appointments = appointments));
    this.updatePatientLetters();
    this.updateDocuments();
    this.getPatientContacts();
  }

  updatePatientDetails() {
    const p = this.PatientService.get(this.patientId);
    p.$promise.then(() => {
      this.patient = p;
    });
    return p;
  }

  $onDestroy() {
    this.PatientProcedureService.reset();
    this.PrescriptionsService.reset();
    this.UserFavouriteDrugsService.reset();
  }

  isInjectionRecord(record: IGlInjectionRecord | PatientRecord) {
    return record.type === "procedure" && (record as IGlInjectionRecord);
  }

  updateDocuments() {
    return this.PatientDocumentService.getAllForPatient(this.patientId)
      .then((documents) => {
        this.patientDocuments = documents.filter(
          (d) => d.patient_record_id == null && d?.data?.field === "generic"
        );
      })

      .then(() => {
        this.activeReferral = getActiveReferral(
          this.records,
          this.patientDocuments
        );
        // const indexOfReferral =
        //   this.PatientDocumentService.getDocumentReferralsUrl(
        //     this.patientDocuments
        //   );
        // this.referralUrl = this.patientDocuments[indexOfReferral]?.url;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePatientLetters(letter?: Letter) {
    this.LetterService.init(this.patientId).then(
      (letters) => (this.patientAllLetters = letters)
    );
    this.letter = null;
    this.currentRecord = null;
  }

  getPatientContacts() {
    return this.ContactService.getAllPatientContacts(this.patientId).then(
      (c) => {
        this.contacts = c;
      }
    );
  }

  canCreateLetter() {
    return CAN_CREATE_LETTERS.includes(this.user.type.name);
  }

  changeActive() {
    this.currentRecord =
      this.currentRecord === this.selectedRecord
        ? null
        : (this.currentRecord = this.selectedRecord);
  }

  createNewLetter() {
    this.letter = null;
  }

  getLetter(letter: Letter) {
    this.letter = letter;
  }

  createLetterWithoutRecord() {
    this.currentRecord = {};
  }

  setLetter(selectedLetter: Letter) {
    this.letter = selectedLetter;
  }

  createNewInjection(args?: { appointment: Appointment }) {
    this.createNewInjectionInProgress = true;
    this.PatientRecordService.create(this.patientId, {
      type: "procedure",
      appointment_id: args?.appointment.id,
      workflowState: "Arrived (INJ)",
    })
      .then((record) => {
        this.toastr.success("Successfully created new injection");
        return this.$state.go("main.injections", {
          patientId: this.patientId,
          recordId: record.id,
        });
      })
      .catch((error) => {
        this.toastr.error("Unable to create a new injection record");
        console.error(error);
      })
      .finally(() => {
        this.createNewInjectionInProgress = false;
      });
  }

  /*
    applies to only regular records
    the patient before that must have made a decision to whether they consent to recording or not
    this applies to all users
  */
  determineProvider() {
    // first check if the patient has made a decision for the recording
    // if nil (aka no decision), ask the user to set their status first before
    // creating a record
    // we do this by getting a list of whats missing
    // if it is empty (i.e. all answered, then we can continue)
    const unansweredQuestions: string = this.getUnansweredPatientQuestions();
    if (unansweredQuestions?.length) {
      return this.$window.alert(
        `The following responses from the patient must be confirmed before a record can be created:\n\n${unansweredQuestions}.`
      );
    }

    // else continue with creating a record by determining the provider
    if (
      this.user.type.name === "ophthalmologist" ||
      this.user.type.name === "optometrist"
    ) {
      // else just createa  record
      this.createNewRecord({
        appointment:
          this.appointments?.length > 0 ? this.appointments[0] : null,
        provider_id: this.user.id,
      });
    } else {
      const updateModal = this.$uibModal.open({
        component: "setProviderForRecord",
        resolve: {
          user: () => {
            return this.user;
          },
          appointment: () => {
            return this.appointments[0];
          },
        },
      });
      updateModal.result.then((result) => {
        if (result) {
          this.createNewRecord({
            appointment:
              this.appointments?.length > 0 ? this.appointments[0] : null,
            provider_id: result.id,
          });
        }
      });
    }
  }

  createNewRecord(args?: { appointment?: Appointment; provider_id?: number }) {
    this.createNewRecordInProgress = true;
    this.PatientRecordService.create(this.patientId, {
      appointment_id: args?.appointment?.id,
      workflowState: "Arrived (C)",
      provider_id: args?.provider_id,
    })
      .then((record) => {
        this.toastr.success("Successfully created new visit");
        return this.$state.go("main.record", {
          patientId: this.patientId,
          recordId: record.id,
        });
      })
      .catch((error) => {
        this.toastr.error("Unable to create a new record");
        console.error(error);
      })
      .finally(() => {
        this.createNewRecordInProgress = false;
      });
  }

  getConsolidatedRecord() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  createNewTechRecord() {
    this.createNewTechRecordInProgress = true;
    this.PatientRecordService.create(this.patientId, { type: "tech_record" })
      .then((record) => {
        this.toastr.success("Successfully created new visit");
        return this.$state.go("main.record-tech", {
          patientId: this.patientId,
          recordId: record.id,
        });
      })
      .catch((error) => {
        this.toastr.error("Unable to create a new record");
        console.error(error);
      })
      .finally(() => {
        this.createNewTechRecordInProgress = false;
      });
  }

  createNewHistoryRecord() {
    this.$q
      .resolve()
      .then(() => {
        this.createNewHistoryRecordInProgress = true;
        return this.PatientRecordService.create(this.patientId, {
          type: "history",
        });
      })
      .then((record) => {
        this.toastr.success("Successfully created new visit");
        return this.$state.go("main.record", {
          patientId: this.patientId,
          recordId: record.id,
          type: "history",
        });
      })
      .catch((error) => {
        this.toastr.error("Unable to create a new record");
        console.error(error);
      })
      .finally(() => {
        this.createNewHistoryRecordInProgress = false;
      });
  }

  recordHistory() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  hasAppointment() {
    return !!this.appointments?.[0];
  }

  canCreateRecord() {
    return true;
  }

  canCreateTechRecord() {
    const userTypes: GlUserTypeString[] = ["administrator", "technician"];
    return userTypes.includes(this.user.type.name);
  }

  canCreateInjection() {
    return this.user.type.name === "ophthalmologist";
  }

  mostRecentRecord() {
    return this.records?.[this.records?.length - 1];
  }

  toggleProvidersViewMode(mode: IGlFormMode) {
    this.providersViewMode = mode;
  }

  togglePatientViewMode(mode: IGlFormMode) {
    this.patientViewMode = mode;
  }

  formatRecordForSelect(record: PatientRecord) {
    const dateOfRecord = this.dateFilter(
      record.appointment_date || record.data_signed_at || record.updated_at
    );
    const createdBy = get(
      record,
      "data_signed_by.name",
      get(record, "data_updated_by.name")
    );
    const recordType = this.getRecordType(record);
    return [recordType, dateOfRecord, createdBy]
      .filter(Boolean)
      .join(" / ")
      .toString();
  }

  updateProviders() {
    this.$q
      .resolve()
      .then(() => {
        this.saveProvidersInProgress = true;
        return this.PatientRecordService.create(this.patientId, {
          type: "admin_update",
        });
      })
      .then((record) => {
        return this.PatientRecordService.update(
          {
            ...record,
            status: "COMPLETE",
            data: this.providersUpdateRecordData,
          },
          true
        );
      })
      .then(() => this.updatePatientRecordHistory())
      .then(() => {
        this.providersForm.$setPristine();
        this.toggleProvidersViewMode("display");
      })
      .finally(() => (this.saveProvidersInProgress = false));
  }

  updatePatientPrescriptions(patientId: number) {
    if (patientId !== undefined) {
      return this.PrescriptionsService.fetchPrescriptions(patientId);
    }
  }

  updatePatientProcedures(patientId: number) {
    if (patientId !== undefined) {
      return this.PatientProcedureService.getAllForPatient({
        patientId,
      });
    }
  }

  // FAVOURITES
  updatePrescriberFavouriteDrugs(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugs(userId);
    }
  }

  updatePrescriberFavouriteDrugGroups(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugGroups(
        userId
      );
    }
  }

  updatePrescriberFavouritesCombinedHandler(userId: number) {
    this.updatePrescriberFavouriteDrugs(userId);
    this.updatePrescriberFavouriteDrugGroups(userId);
  }

  updatePatientSubscriptionDetails(patient_id: number) {
    this.loadingSubscription = true;
    this.SubscriptionService.get({ patient_id })
      .then((subscription) => {
        this.subscriptionDetails = { ...subscription };
        this.loadingSubscription = false;
      })
      .finally(() => {
        this.loadingSubscription = false;
      });
  }

  // has the patient confirmed if they consent to recording?
  // this is different to testing for yes/no
  patientHasAnsweredRecordingConsentStatus() {
    return !isNil(this.patient?.data?.consent_to_record);
  }

  patientHasAnsweredEmailAuthorityStatus() {
    return !isNil(this.patient?.data?.email_authority);
  }

  // has patient answered all the necessary questions
  // before a record has been created?
  patientAnsweredAllQuestions() {
    // authority to email has been decided?
    const emailAuthority: boolean = !isNil(this.patient?.data?.email_authority);
    // authority to record consent has been decided?
    const recordingConsent: boolean = !isNil(
      this.patient?.data?.consent_to_record
    );

    // both must be answered
    return emailAuthority && recordingConsent;
  }

  // get missing statum for the patient to display
  getUnansweredPatientQuestions() {
    const text: string[] = [];
    if (!this.patientHasAnsweredRecordingConsentStatus()) {
      text.push("Recording consent");
    }
    if (!this.patientHasAnsweredEmailAuthorityStatus()) {
      text.push("Authority to email");
    }

    return text?.join(", ");
  }

  // fetch allergies list from patient history
  getAllergiesList() {
    const recordHistory: PatientRecordData = this.getConsolidatedRecord();
    return recordHistory?.allergies_list;
  }

  private updatePatientRecordHistory() {
    return this.PatientRecordService.getRecordHistoryForUser(
      this.patientId
    ).then((records) => {
      this.records = records;
      this.GlModelService.setRecordHistory(records);
    });
  }

  private getRecordType(record: PatientRecord) {
    if (record.type === "patient_record") {
      if (record.virtual_review) {
        return "Optom Record";
      } else {
        return "Ophthal Record";
      }
    } else if (record.type === "history") {
      return "History Record";
    } else if (record.type === "tech_record") {
      return "Tech Record";
    }
  }
}

export class PatientPage implements angular.IComponentOptions {
  static selector = "patient";
  static template = require("./patient.html");
  static controller = PatientPageController;
  static bindings = {
    user: "<",
  };
}
