import { MediaRecordingService } from "app/core/services/media-recording/media-recording.service";
import { isFunction, isNil } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { PatientRecord } from "models/patient-record.model";
import { GlStaff, Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

import { IAudioTranscriptTemplate } from "models/record-transcript.model";
import "./record-transcript.scss";
import angular = require("angular");

export class RecordTranscriptController
  extends GlFormController
  implements angular.IController
{
  user: GlStaff;
  patient: Patient;

  // initial state
  initialRecord: PatientRecord;

  // the actual record
  record: PatientRecord;

  mode: IGlFormMode;

  patientId: number = this.$stateParams.patientId;
  recordId: number = this.$stateParams.recordId;

  // for future: existing recording?
  existingTranscript: IAudioTranscriptTemplate;

  // a very simple flag to just indicate if the current
  // existing file has been successfully uploaded or not
  isUploaded: false;

  // recording for the record itselfx
  // partial so we can build it up
  transcriptModel: Partial<IAudioTranscriptTemplate>;
  transcriptUploadInProgress: boolean;
  currentTranscriptUploaded: boolean;

  // ref for audio visualiser
  audioVisualiserRef: JQLite;

  // to update
  updateTranscripts: () => void;

  // to upload
  uploadTranscript: (arg: { transcript: IAudioTranscriptTemplate }) => void;

  constructor(
    public $stateParams: angular.ui.IStateParamsService,
    private $window: angular.IWindowService,
    public MediaRecordingService: MediaRecordingService
  ) {
    "ngInject";
    super();
  }

  // patient has consent?
  patientConsentsToRecording() {
    return this?.patient?.data?.consent_to_record;
  }

  // start recording
  startRecording() {
    // if there is an existing recording that isnt uploaded
    // warn that re-recording will override
    // otherwise continue
    if (
      !isNil(this.transcriptModel?.recording) &&
      !this.currentTranscriptUploaded &&
      !this.$window.confirm(
        "The existing recording will be replaced if recording is started again and not uploaded, are you sure you want to do this?"
      )
    ) {
      return;
    }

    // reset recording
    this.transcriptModel = {};
    this.currentTranscriptUploaded = false;

    // start
    this.MediaRecordingService.startMediaRecorder({
      startCallback: this.onRecordingStart.bind(this),
      stopCallback: this.onRecordingStop.bind(this),
    });
  }

  // stop recording (the button click)
  stopRecording() {
    this.MediaRecordingService.stopMediaRecorder();
  }

  // clears everything out
  resetRecording() {
    const confirm: boolean = this.$window.confirm(
      "Are you sure you want to reset this recording?"
    );
    if (confirm) {
      // since its reset, that means nothing is uploaded
      this.transcriptModel = {};
      this.currentTranscriptUploaded = false;
    }
  }

  isRecording() {
    return this.MediaRecordingService.isRecording();
  }

  getLatestRecording() {
    return this.transcriptModel?.recording;
  }

  getLatestRecordingUrl() {
    return this.transcriptModel?.path ?? "";
  }

  getAudioStream() {
    return this.MediaRecordingService.audioStream;
  }

  testUpload() {
    this.MediaRecordingService.testUpload({
      patient: this.patient,
      recordId: this.record.id,
      recordDataDiff: this.record?.data,
    });
  }

  //  takes in an upload function which will handle the upload uniquely
  uploadLatestRecording() {
    if (isFunction(this.uploadTranscript)) {
      this.uploadTranscript({
        transcript: this.transcriptModel as IAudioTranscriptTemplate,
      });
    }
  }

  // CALLBACKS FOR FUNCTION
  private onRecordingStart() {
    this.MediaRecordingService.setStartingTimestamp(this.transcriptModel);
  }

  // callback to pass to media service to set the object
  private onRecordingStop(audioFile: Blob) {
    this.transcriptModel.recording = audioFile;
    // call to set the remaining parameters
    this.MediaRecordingService.finaliseRecording(this.transcriptModel);
  }
}

export class RecordTranscript implements angular.IComponentOptions {
  static selector: string = "recordTranscript";
  static controller = RecordTranscriptController;
  static template = require("./record-transcript.html");
  static bindings = {
    user: "<",
    patient: "<",
    initialRecord: "<",
    record: "<",

    mode: "@",
    isEditable: "<",
    updateTranscripts: "&",

    // variables to track
    transcriptModel: "=",
    transcriptUploadInProgress: "=",
    currentTranscriptUploaded: "=",
    uploadTranscript: "&",
  };
}
