import { last } from "lodash";
import { GlStaff, Patient } from "models/user.model";
import * as moment from "moment";
import { Letter } from "../../../../../models/letter.model";
import {
  GnetOculoLetterResponse,
  PlinyBrowserExtensionService,
} from "../../../../core/services/PlinyBrowserExtensionService/pliny-browser-extension.service";
import { LetterService } from "../../../services/letter.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import "./patient-letters.scss";

export class PatientLettersController
  implements angular.IController, angular.IOnChanges
{
  letters: Letter[];
  letter: Letter;
  collapsed: boolean;
  debug = false;
  editCurrentLetter: (args?: { letter: Letter }) => void;
  showPrint = false;
  dates: string[];
  canEditLetter: boolean;
  selectedDate: string;
  lettersGroupedByDate: Map<string, Letter[]>;
  lettersForSelectedDate: Letter[];
  activeTabIndex = 0;

  // enable extension export?
  extensionExportEnabled: boolean = false;

  // details
  patient: Patient;
  user: GlStaff;

  constructor(
    public PatientRecordService: PatientRecordService,
    private LetterService: LetterService,
    private $timeout: angular.ITimeoutService,
    private PlinyBrowserExtensionService: PlinyBrowserExtensionService,
    private toastr: angular.toastr.IToastrService,
    private $q: angular.IQService
  ) {
    "ngInject";
  }

  $onInit() {
    // only execute if we enable export
    if (this.extensionExportEnabled) {
      this.PlinyBrowserExtensionService.addCallbackToGlobalInterface(
        "getSelectedGnetLetterData",
        this.parseLetterDataForOculo.bind(this)
      );
    }
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.letters && this.letters) {
      this.sortLettersByDate(this.letters);
      this.letter = this.lettersGroupedByDate[0];
    }
  }

  $onDestroy(): void {
    // always deregister callback on exit,
    // this also handles if it doesnt exist
    this.PlinyBrowserExtensionService.removeCallbackFromGlobalInterface(
      "getSelectedGnetLetterData"
    );
  }

  // also add a modal for selecting either a specific letter or
  // CC addresee
  // lettersForSelectedDate and activeTabIndex
  // then we fetch form there
  parseLetterDataForOculo(): angular.IPromise<GnetOculoLetterResponse> {
    // 1. add the template
    // fetch selected letter
    this.toastr.info("Preparing letter to export to Oculo...");
    const selectedLetter: Letter =
      this.lettersForSelectedDate?.[this.activeTabIndex];

    // 2a. fetch the parsed hidden div data
    // if no letter, ignore
    if (!selectedLetter) {
      this.toastr.error("No letter selected to export to Oculo");
      // nothing to export
      return Promise.resolve(undefined);
    }

    // 2b. if theres is data
    // add a delay to allow the template to be rendered
    // fetch the template from the hidden div
    const headerData = document.getElementById("print-header-wrap");
    const footerData = document.getElementById("print-footer-wrap");
    const pageData = document.getElementById("gnet-pliny-hidden-letter");

    // no template fetched? ignore
    // header and footer data could be empty if default
    if (!pageData) {
      this.toastr.error(
        "Error rendering letter to export to Oculo, please try again"
      );
      return Promise.resolve(undefined);
    }

    // generate
    return this.LetterService.generateLetterBase64FromElementReference({
      headerData: headerData?.innerHTML,
      footerData: footerData?.innerHTML,
      pageData: pageData?.innerHTML,
      background: true,
    })
      .then((letterBase64: string) => {
        // return specific payload
        this.toastr.success(
          "Successfully generated letter, now exporting to Oculo..."
        );
        return {
          patientName: this.patient.name,
          letterBase64,
        };
      })
      .catch((err) => {
        console.error("error exporting letter to Oculo", err);
        this.toastr.error(
          "Error rendering letter to export to Oculo, please try again"
        );
        return Promise.resolve(undefined);
      });
  }
  setLetter(selectedLetter: Letter) {
    this.letter = selectedLetter;

    this.editCurrentLetter({ letter: this.letter });
  }

  changeDate(date: string) {
    this.selectedDate = date;
    this.updateLetters(date);
    const indexOfSelectedLetter = this.lettersForSelectedDate?.findIndex(
      (letter) => {
        return letter.id === this.letter?.id;
      }
    );
    // This is a nasty hack to automatically select the letter tab when changing tabs
    this.$timeout().then(() => {
      if (indexOfSelectedLetter === -1) {
        this.activeTabIndex = 0;
      } else {
        this.activeTabIndex = indexOfSelectedLetter;
      }
    });
  }

  onGeneratePdf($letter: Letter, key?: string) {
    this.LetterService.openPdfLetterWindow(
      $letter.patient_id,
      [$letter.id],
      true,
      key
    );
  }

  onPrint($letter: Letter, key?: string) {
    this.LetterService.openPrintLetterWindow(
      $letter.patient_id,
      [$letter.id],
      true,
      key
    );
  }

  sortLettersByDate(letters: Letter[]) {
    this.lettersGroupedByDate = letters.reduce((map, l) => {
      const date = moment(l.created_at).format("L");
      const thisDaysLetters = map.get(date) || [];
      map.set(date, [...thisDaysLetters, l]);
      return map;
    }, new Map<string, Letter[]>());
    this.dates = Array.from(this.lettersGroupedByDate.keys());
    this.selectedDate = last(this.dates);
    this.changeDate(this.selectedDate);
  }

  updateLetters(dateKey: string) {
    this.lettersForSelectedDate = this.lettersGroupedByDate.get(dateKey);
  }

  dateKeyToDate(dateKey: string) {
    return moment(dateKey, "L").toDate();
  }

  getLetterReferrer(letter: Letter) {
    return this.LetterService.getLetterReferrer(letter);
  }
}

export class PatientLetters implements angular.IComponentOptions {
  static selector = "patientLetters";
  static template = require("./patient-letters.html");
  static controller = PatientLettersController;
  static bindings = {
    letters: "<",
    editCurrentLetter: "&?",
    canEditLetter: "<",
    patient: "<",
    user: "<",
    extensionExportEnabled: "<?",
  };
}
