import { copy, isUndefined } from "angular";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import {
  IGlSignature,
  SignatureService,
} from "app/core/services/signature-service/signature.service";
import { get, isEmpty, isNaN, isNull } from "lodash";
import { Contact } from "models/contact.model";
import { IGlInjectionRecord } from "models/injection";
import { CAN_CREATE_LETTERS } from "../../../../../config/environment";
import { LetterType } from "../../../../../models/letter-types.model";
import { GlLetterType, Letter } from "../../../../../models/letter.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { Patient, User } from "../../../../../models/user.model";
import {
  GnetOculoLetterResponse,
  PlinyBrowserExtensionService,
} from "../../../services/PlinyBrowserExtensionService/pliny-browser-extension.service";
import { GlModelService } from "../../../services/gl-model.service";
import { LetterService } from "../../../services/letter.service";
import {
  GL_LETTER_EVENT_UPDATE,
  GlLetterController,
} from "../gl-letter/gl-letter";
import "./segment-letters.scss";
import angular = require("angular");

export class SegmentLettersController
  implements
    angular.IController,
    angular.IOnChanges,
    angular.IOnInit,
    angular.IOnDestroy
{
  // @input()
  canCreateLetter = true;
  currentReferrer: any;
  user: User;
  // eslint-disable-next-line
  record: PatientRecord | any;
  records: PatientRecord[];
  recordsToDisplay: PatientRecord[] = [];
  patient: Patient;
  history: PatientRecordData;
  letters: Letter[];
  mode: "edit" | "display" = "edit";
  managingOptom: PatientClinic;
  consolidatedInjections: IGlInjectionRecord[];
  contacts: Contact[];
  dirtyForm = {
    optometrist: false,
    gp: false,
    managing_optom: false,
    referrer: false,
  };
  activeTabId: any;
  selectedTemplate: LetterType;
  numberOfLetters: number;
  letterManagingOptom: Letter;
  letterOptom: Letter;
  letterGp: Letter;
  letterReferrer: Letter;
  activeTabset = 0;
  letterWithoutRecord: boolean = false;
  letterTemplates: LetterType[] = [
    {
      id: 1,
      title: "Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/initial.html"),
    },
    {
      id: 2,
      title: "Review",
      key: "1",
      raw_template: require("../gl-letter-templates/review.html"),
    },
    {
      id: 9,
      title: "Post Referral Review",
      key: "1",
      raw_template: require("../gl-letter-templates/post-referral-review.html"),
    },
    {
      id: 15,
      title: "Glaucoma Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/glaucoma-initial.html"),
    },
    {
      id: 16,
      title: "Glaucoma Review",
      key: "1",
      raw_template: require("../gl-letter-templates/glaucoma-review.html"),
    },
    {
      id: 5,
      title: "Cataract Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/cataract-initial.html"),
    },
    {
      id: 7,
      title: "Cataract Discharge",
      key: "1",
      raw_template: require("../gl-letter-templates/cataract-discharge.html"),
    },
    {
      id: 12,
      title: "Capsulotomy",
      key: "1",
      raw_template: require("../gl-letter-templates/capsulotomy.html"),
    },
    {
      id: 6,
      title: "Retina Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/retina-initial.html"),
    },
    {
      id: 8,
      title: "Retina Review",
      key: "1",
      raw_template: require("../gl-letter-templates/retina-review.html"),
    },
    {
      id: 11,
      title: "Plastics Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/plastics-initial.html"),
    },
    {
      id: 10,
      title: "Plastics Discharge",
      key: "1",
      raw_template: require("../gl-letter-templates/plastics-discharge.html"),
    },
    {
      id: 3,
      title: "Post Procedure",
      key: "1",
      raw_template: require("../gl-letter-templates/post-procedure.html"),
    },
    {
      id: 4,
      title: "Post Operation",
      key: "1",
      raw_template: require("../gl-letter-templates/post-op.html"),
    },
    {
      id: 14,
      title: "Dry Eye",
      key: "1",
      raw_template: require("../gl-letter-templates/dry-eye.html"),
    },
    {
      id: 13,
      title: "Doctor Referral",
      key: "1",
      raw_template: require("../gl-letter-templates/doctor-referral.html"),
    },
  ];
  _masterLetter: string;
  masterLetter: string;
  gpLetter: string;
  lettersForm?: angular.IFormController;
  signature: IGlSignature;
  componentLetters: any;
  letterHeading: string;
  currentRecord: PatientRecord;
  selectedRecord: PatientRecord;
  dateFilter = this.$filter("date");
  letterControllers: GlLetterController[] = [];

  // can export via extension?
  extensionExportEnabled: boolean = false;

  constructor(
    private $filter: angular.IFilterService,
    private $q: angular.IQService,
    private $scope: angular.IScope,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private PatientRecordService: PatientRecordService,
    private SignatureService: SignatureService,
    private PlinyBrowserExtensionService: PlinyBrowserExtensionService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onInit() {
    // if any of them match
    if (this.extensionExportEnabled) {
      // add the function
      this.PlinyBrowserExtensionService.addCallbackToGlobalInterface(
        "getSelectedGnetLetterData",
        this.parseLetterDataForOculo.bind(this)
      );
    }
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.letters && this.letters) {
      let newLetter: Letter;
      this.componentLetters = [...this.letters];
      if (this.componentLetters.length === 0) {
        this.componentLetters.push(newLetter);
      }

      // work out which letter is which;
      this.letters.forEach((letter) => {
        if (letter.type === "managing_optom") {
          this.letterManagingOptom = letter;
          this.letterHeading = "(Managing Optometrist)";
        } else if (letter.type === "optometrist") {
          this.letterOptom = letter;
          this.letterHeading = "(Optometrist)";
        } else if (letter.type === "gp") {
          this.letterGp = letter;
          this.letterHeading = "(GP)";
        } else if (letter.type === "referrer") {
          this.letterReferrer = letter;
          this.letterHeading = "(Referrer)";
        } else {
          this.letterGp = letter;
          this.letterHeading = "";
        }
      });

      if (changes.user && this.canCreateLetters()) {
        this.SignatureService.fetchAll().then(
          ([signature] = []) => (this.signature = signature)
        );
      }
    }

    if (changes.currentReferrer) {
      this.currentReferrer = this.currentReferrer || "optometrist";
    }

    this.recordsToDisplay =
      this.PatientRecordService.getPatientRecordsForDisplay(this.records);
  }

  $onDestroy(): void {
    // always deregister callback on exit,
    // this also handles if it doesnt exist
    this.PlinyBrowserExtensionService.removeCallbackFromGlobalInterface(
      "getSelectedGnetLetterData"
    );
  }

  changeActive() {
    this.currentRecord =
      this.currentRecord === this.selectedRecord
        ? null
        : (this.currentRecord = this.selectedRecord);
  }

  createLetterWithoutRecord() {
    this.record = {};
  }

  formatRecordForSelect(record: PatientRecord) {
    const dateOfRecord = this.dateFilter(
      record.appointment_date || record.data_signed_at || record.updated_at
    );
    const createdBy = get(
      record,
      "data_signed_by.name",
      get(record, "data_updated_by.name")
    );
    const recordType = this.getRecordType(record);
    return [recordType, dateOfRecord, createdBy]
      .filter(Boolean)
      .join(" / ")
      .toString();
  }

  isValueEmpty(value: any) {
    return (
      isUndefined(value) || isNull(value) || isNaN(value) || isEmpty(value)
    );
  }

  isLetterWithoutRecord() {
    this.currentRecord = null;
    this.record = null;
    this.letterWithoutRecord = !this.letterWithoutRecord;
  }

  canCreateLetters() {
    return CAN_CREATE_LETTERS.includes(this.user.type.name);
  }

  isEditMode() {
    return this.mode === "edit";
  }

  updateLetters() {
    // which is the active tab
    if (this.currentReferrer === "gp") {
      this.masterLetter = copy(
        this.gpLetter || this.letterGp.compiled_template
      );
    }
    this.$scope.$broadcast(GL_LETTER_EVENT_UPDATE);
  }

  updateDataFields() {
    this.$scope.$broadcast(GL_LETTER_EVENT_UPDATE);
  }

  letterDidChange(
    letter: string,
    notSaved?: boolean,
    letterKey?: GlLetterType
  ) {
    if (!letter) {
      return;
    }
    switch (letterKey) {
      case "gp": {
        this.gpLetter = letter;
        break;
      }
    }
    this._masterLetter = letter;
    this.dirtyForm[letterKey] = notSaved;
  }

  addToLetters() {
    let x: Letter;
    this.componentLetters.push(x);
    // new tab not active in view have to wait for tab to come into view to set to active
    this.$timeout(250).then(
      () => (this.activeTabset = this.componentLetters.length - 1)
    );
  }

  removeFromLetters(item: any) {
    const index = this.componentLetters.indexOf(item);
    this.componentLetters.splice(index, 1);
  }

  showLetterTab(
    type: "optometrist" | "managing_optom" | "gp" | "referrerDetails"
  ) {
    const provider = this.GlModelService.getFromRecordOrHistory(
      `providers.${type}`,
      this.record.data,
      this.history
    );
    const letter =
      type === "managing_optom"
        ? this.letterManagingOptom
        : type === "gp"
        ? this.letterGp
        : type === "optometrist"
        ? this.letterOptom
        : this.letterReferrer;

    return (this.canCreateLetters() && !isEmpty(provider)) || letter;
  }
  canSync() {
    let canSync = false;
    switch (this.currentReferrer) {
      case "managing_optom": {
        canSync = !!this.letterManagingOptom;
        break;
      }
      case "gp": {
        canSync = !!this.letterGp;
        break;
      }
      case "optometrist": {
        canSync = !!this.letterOptom;
        break;
      }
      case "referrerDetails": {
        canSync = !!this.letterReferrer;
        break;
      }
    }
    if (this.letters && this.letters.length < 2) {
      canSync = false;
    }
    if (this.selectedTemplate) {
      canSync = true;
    }
    return canSync;
  }

  getReferrer() {
    return this.GlModelService.getFromRecordOrHistory(
      `providers.referrer`,
      this.record.data,
      this.history
    );
  }

  saveAll() {
    this.letters.forEach((l) => {
      return this.LetterService.update(l).then(() => {
        this.setLettersFormPristine();
      });
    });
    // return this.$q
    //   .all(this.letterControllers.map((c) => c.save()))
    //   .then(() => );
  }

  printAll(key?: string) {
    this.LetterService.openPrintLetterWindow(
      this.patient.id,
      this.letters.map((letter) => letter.id),
      true,
      key
    );
  }

  pdfAll(key?: string) {
    this.LetterService.openPdfLetterWindow(
      this.patient.id,
      this.letters.map((letter) => letter.id),
      true,
      key
    );
  }

  setLettersFormPristine() {
    if (this.lettersForm) {
      this.lettersForm.$setPristine();
    }
  }

  hasSavedLetters() {
    return this.letters && this.letters.length > 0;
  }

  getReferrerTitle($tabProvider: string) {
    const referrer = this.GlModelService.getFromRecordOrHistory(
      "providers.referrer",
      this.record.data,
      this.history
    );
    let tabTitle = "";
    switch ($tabProvider) {
      case "managing_optom":
        tabTitle = "Managing Optom";
        break;
      case "optometrist":
        tabTitle = "Optometrist";
        break;
      case "gp":
        tabTitle = "GP";
        break;
      case "referrer":
        tabTitle = "Referrer";
        break;
      default:
    }
    if (referrer === $tabProvider) {
      tabTitle += " (Referrer)";
    }

    if (this.dirtyForm[$tabProvider]) {
      tabTitle += " *";
    }
    return tabTitle;
  }

  // also add a modal for selecting either a specific letter or
  // CC addresee
  // lettersForSelectedDate and activeTabIndex
  // then we fetch form there
  parseLetterDataForOculo(): angular.IPromise<GnetOculoLetterResponse> {
    // 1. add the template
    // fetch selected letter
    this.toastr.info("Preparing letter to export to Oculo...");
    const selectedLetter: Letter = this.componentLetters?.[this.activeTabset];

    // 2a. fetch the parsed hidden div data
    // if no letter, ignore
    if (!selectedLetter) {
      this.toastr.error("No letter selected to export to Oculo");
      // nothing to export
      return Promise.resolve(undefined);
    }

    // 2b. if theres is data
    // add a delay to allow the template to be rendered
    // fetch the template from the hidden div
    const headerData = document.getElementById("print-header-wrap");
    const footerData = document.getElementById("print-footer-wrap");
    const pageData = document.getElementById("gnet-pliny-hidden-letter");

    // no template fetched? ignore
    // header and footer data could be empty if default
    if (!pageData) {
      this.toastr.error(
        "Error rendering letter to export to Oculo, please try again"
      );
      return Promise.resolve(undefined);
    }

    // generate
    return this.LetterService.generateLetterBase64FromElementReference({
      headerData: headerData?.innerHTML,
      footerData: footerData?.innerHTML,
      pageData: pageData?.innerHTML,
      background: true,
    })
      .then((letterBase64: string) => {
        // return specific payload
        this.toastr.success(
          "Successfully generated letter, now exporting to Oculo..."
        );
        return {
          patientName: this.patient.name,
          letterBase64,
        };
      })
      .catch((err) => {
        console.error("error exporting letter to Oculo", err);
        this.toastr.error(
          "Error rendering letter to export to Oculo, please try again"
        );
        return Promise.resolve(undefined);
      });
  }

  getLetterReferrer(letter: Letter) {
    return this.LetterService.getLetterReferrer(letter);
  }

  private getRecordType(record: PatientRecord) {
    if (record.type === "patient_record") {
      if (record.virtual_review) {
        return "Optom Record";
      } else {
        return "Ophthal Record";
      }
    } else if (record.type === "history") {
      return "History Record";
    } else if (record.type === "tech_record") {
      return "Tech Record";
    }
  }
}

export class SegmentLettersComponent implements angular.IComponentOptions {
  static selector = "segmentLetters";
  static template = require("./segment-letters.html");
  static controller = SegmentLettersController;
  static bindings = {
    canCreateLetter: "<?",
    consolidatedInjections: "<",
    currentReferrer: "<",
    drops: "<",
    drugs: "<",
    externalProcedures: "<",
    history: "<",
    inHouseProcedures: "<",
    letters: "<",
    managingOptom: "<",
    mode: "<?",
    contacts: "<?",
    patient: "<",
    record: "<",
    records: "<",
    user: "<",
    extensionExportEnabled: "<?",
  };
}
