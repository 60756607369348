import { GlModelDefaultMode } from "app/core/directive/gl-model";
import { isEmpty, isFunction, isNil, isUndefined, set } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { IGlSide, IGlSideBilateral } from "models/gl-side.model";
import { Appendix, IGlOption } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

class BilateralSelectController
  extends GlFormController
  implements angular.IController, angular.IOnChanges, angular.IOnInit
{
  copy: boolean;
  defaultLeft: IGlOption;
  defaultRight: IGlOption;
  enableLeft: boolean;
  enableRight: boolean;
  field: any;
  key: string;
  leftIcon: string;
  optionKey: string;
  options: IGlOption[];
  customOptions: IGlOption[];
  useCustomOptions: boolean = false;
  other = true;
  otherKey: string;
  path: string;
  ngRequired = true;
  rightIcon: string;
  title: string;
  mode: IGlFormMode;
  // gl-model default mode
  defaultMode?: GlModelDefaultMode;

  // @Output
  glChange: (arg: {
    path: string;
    key: string;
    side: string;
    oldValue: any;
  }) => void;

  // on change can be anything
  onChange: (arg: { option: IGlOption; side: IGlSide }) => void;

  // autofill
  undoAutofillDiagnosis: (arg: { key: string; side: string }) => void;
  confirmAutofillDiagnosis: (arg: {
    diagnosis: IGlOption;
    key: string;
    side: string;
  }) => void;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  $onInit(): void {
    if (this.optionKey) {
      this.options = this.appendix.get(this.optionKey);
    } else {
      this.options = this.appendix.get(this.key);
    }
  }

  $onChanges(): void {
    if (isUndefined(this.other)) {
      this.other = true;
    }

    if (isEmpty(this.otherKey)) {
      this.otherKey = this.defaultOtherKey();
    }
  }

  defaultOtherKey(): string {
    return `${this.key}_other`;
  }

  getPath(): string {
    if (this.path) {
      return `${this.path}.${this.key}`;
    }
    return this.key;
  }

  getOtherPath(): string {
    if (this.path) {
      return `${this.path}.${this.otherKey}`;
    }
    return this.otherKey;
  }

  // handle on change if needed
  handleOnChange(option: IGlOption, side: IGlSideBilateral) {
    if (isFunction(this.onChange)) {
      this.onChange({ option, side });
    }
  }

  // custom options will only be used if not empty and toggled
  getOptions() {
    return this.useCustomOptions &&
      !isNil(this.customOptions) &&
      !isEmpty(this.customOptions)
      ? this.customOptions
      : this.options;
  }

  // if custom options in use then use custom options
  // else follow gl-model
  getDefaultOptionForSide(side: IGlSideBilateral) {
    // if use custom options enabled, always use default left
    // otherwise do left or right as usual
    return side === "left"
      ? this?.defaultLeft ?? null
      : this?.defaultRight ?? null;
  }

  shouldShowDescription(val: IGlOption) {
    if (val) {
      const keyOpt = this.options.find((opt) => opt.key === val.key);
      const shouldShowOther = (keyOpt && keyOpt.showOther) || false;
      return (shouldShowOther || val.key === "other") && this.other === true
        ? true
        : false;
    } else {
      return false;
    }
  }

  // for display mode
  shouldDisplayOption(option: IGlOption) {
    // as long as it has a name and key isnt part of the hidden ones
    return this.appendix.shouldDisplayOption(option);
  }

  // resets option to just no option selected (i.e. undefined)
  clearSelections() {
    set(this.field, `${this.key}`, {
      left: undefined,
      right: undefined,
    });
    set(this.field, `${this.otherKey}`, {
      left: undefined,
      right: undefined,
    });
  }
}

export class BilateralSelect implements angular.IComponentOptions {
  static selector = "bilateralSelect";
  static template = require("./bilateral-select.html");
  static controller = BilateralSelectController;
  static bindings = {
    copy: "<",
    defaultLeft: "<?",
    defaultRight: "<?",
    /**
     * This sets the default-mode for gl-model directive. For description of
     * modes, see gl-model.directive.
     */
    defaultMode: "@?",
    enableLeft: "<",
    enableRight: "<",
    field: "<",
    isEditable: "<?",
    key: "@",
    leftIcon: "@",
    mode: "@?",
    ngRequired: "<?",
    optionKey: "@",
    other: "<",
    otherKey: "@",
    glChange: "&?",
    /**
     * if this key is not at the root level of the field object, then use the
     * path input to set a relative path. ie:
     * {management:{diagnosis_progression}} has a key 'diagnosis_progression'
     * and a path 'management'
     **/
    path: "@?",
    rightIcon: "@",
    title: "@",

    // on change optional
    onChange: "&?",
    // autofill stuff
    undoAutofillDiagnosis: "&",
    confirmAutofillDiagnosis: "&",

    // toggle state between custom options or regular
    useCustomOptions: "<?",
    customOptions: "<?",
  };
}
