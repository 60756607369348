import { DropzoneOptions } from "dropzone";
import { isFunction } from "lodash";
import * as pace from "pace-progress";
import { AuthService } from "./core/services/auth.service";
import angular = require("angular");

declare global {
  interface Window {
    // this is for global functions that are exposed
    // usually mapped as an object with callable functions
    api?: Record<string, (...args: any) => any>;
  }
}

declare module "angular" {
  /* eslint-disable */
  ///////////////////////////////////////////////////////////////////////////////
  // ngSanitize module (angular-sanitize.js)
  ///////////////////////////////////////////////////////////////////////////////
  namespace sanitize {
    ///////////////////////////////////////////////////////////////////////////
    // SanitizeProvider
    // see https://docs.angularjs.org/api/ngSanitize/provider/$sanitizeProvider
    ///////////////////////////////////////////////////////////////////////////
    export interface ISanitizerProvider {
      enableSvg(): boolean;
      enableSvg(flag: boolean): ISanitizerProvider;
      addValidAttrs(attrs: string[]): ISanitizerProvider;
      addValidElements(elements: string[]): ISanitizerProvider;
      addValidElements(elements: {
        htmlElements: string[];
        htmlVoidElements?: string[];
        svgElements?: string[];
      }): ISanitizerProvider;
    }
  }
  /* eslint-enable*/
}

/**
 * As angular-jwt allows skipAuthorization to be used in a http method
 * the code below extends the type to include this option
 */
declare module "angular" {
  export interface IRequestShortcutConfig {
    skipAuthorization?: boolean;
  }
}

export const configuration = (
  laddaProvider: any,
  $sceDelegateProvider: angular.ISCEDelegateProvider,
  $httpProvider: angular.IHttpProvider,
  jwtOptionsProvider: any,
  API_URL: string,
  dropzoneOpsProvider: any,
  toastrConfig: angular.toastr.IToastConfig
) => {
  "ngInject";

  /**
   * Configure ladda
   */
  laddaProvider.setOption({
    /* optional */
    style: "expand-right",
    spinnerSize: 25,
    spinnerColor: "#676A6C",
  });

  /**
   * Whitelist urls to allow images to be loaded from
   */
  $sceDelegateProvider.resourceUrlWhitelist([
    // Allow same origin resource loads.
    "self",
    // Allow loading from our assets domain.  Notice the difference between * and **.
    "https://s3-ap-southeast-2.amazonaws.com/images-glauconet/**",
    "https://images-glauconet.s3.ap-southeast-2.amazonaws.com/**",
    "http://glauconet.test/**",
    "http://localhost:3000/**",
    // for testing with http-server
    // "http://<ip_address>/3000/**",
  ]);

  /**
   * Configure angularJwt provider
   */
  const apiDomain = API_URL.replace(/^http(s?):\/\//, "")
    .replace(/(\/?)$/, "")
    // eslint-disable-next-line no-useless-escape
    .replace(/(\:\d+)/, "");

  /**
   * Include the Gnet Pliny Gateways so that the jwt interceptor can add the
   * Authorization header automatically when connecting to these urls.  In the
   * future, it may be necessary to make this more dynamic, by fetching the
   * associated clinic pliny gateway from the active clinic.  But for the time
   * being, only these 2 gateways are in use.
   */
  const gnetPlinyGateways = ["edge.mces.pliny.com.au", "edge.ses.pliny.com.au"];
  jwtOptionsProvider.config({
    authPrefix: 'Bearer ',
    tokenGetter: (AuthService: AuthService) => {
      "ngInject";
      return AuthService.getAccessToken();
    },
    whiteListedDomains: [apiDomain, ...gnetPlinyGateways],
    loginPath: "login",
    unauthenticatedRedirector: (
      $state: angular.ui.IStateService,
      $window: angular.IWindowService,
      toastr: angular.toastr.IToastrService,
      AuthService: AuthService,
    ) => {
      "ngInject";
      // in the event that AuthService hasn't been instantiated
      // or we are already on the login page 
      // dont do anything
      const currentUrl: string = $window.location.href;
      if (!isFunction(AuthService?.openReloginModal) || currentUrl?.includes('/login')) {
        return;
      }

      // otherwise open and initiate relogin
      const modal = AuthService.openReloginModal();

      // if modal doesnt exist its already open
      // ignore
      if (!modal) {
        return;
      }

      // open modal if existing
      modal
        .result
        ?.then(({ success, redirectUrl }) => {
          // if successful relogin, reload and close modal
          if (success) {
            // fetch user and continue
            toastr.success('Successfully relogged in!');
            AuthService.getUser();

            // determine if we need to redirect based on the current location
            // if url isnt the same - redirect
            if ($window.location.href !== redirectUrl) {
              $state.go(redirectUrl);
            }
          } else {
            // otherwise it will be an auto go and relogin
            toastr.error('Session expired, redirecting to login page...');
            $state.go("login");
          }
        })
        .catch(() => {
          // surpress error for closing modal
        });
    },
  });
  $httpProvider.interceptors.push("jwtInterceptor");

  /**
   * Configure Dropzone
   */
  const dzOpts: DropzoneOptions = {
    maxFilesize: 10,
    paramName: "file", // The name that will be used to transfer the file
    maxFiles: 1,
    addRemoveLinks: true,
    acceptedFiles: "application/pdf,image/*",
  };
  dropzoneOpsProvider.setOptions(dzOpts);

  /**
   * Configure Toastr Options
   */
  angular.extend(toastrConfig, {
    timeOut: 3000,
    positionClass: "toast-top-left"
  });
};

export const CONSTANTS = [
  {
    name: "API_URL",
    value: isDevEnvironment()
      ? "http://localhost:3000/" // regular dev
      : // ?  "http://<ip_address>:3000/" // if testing with http-server
      "https://api.glauconet.net/",
  },
  {
    name: "CLIENT_ID",
    value: isDevEnvironment() ? "4" : "4",
  },
  {
    name: "CLIENT_SECRET",
    value: isDevEnvironment()
      ? "b8RundAbFSg1WbvGpGUkcZbhDbOQhais19HG73X7"
      : "b8RundAbFSg1WbvGpGUkcZbhDbOQhais19HG73X7",
  },
  {
    name: "DASHBOARD_REFRESH_INTERVAL",
    value: 120 * 1000, // 120 seconds
  },
  {
    name: "AUTO_SAVE_INTERVAL",
    value: isDevEnvironment() ? 0 : 30 * 1000, // 30 seconds
  },
  {
    name: "WHITELISTED_OPTOMS",
    value: isDevEnvironment() ? [4, 5] : [4, 5], // array of user_ids
  },
];

export const GOOGLE_API_KEY = "AIzaSyBie_FMoV_bYbssTmVo8j7d6b44Ll3OGwM";

export const checkAuthOnLoad = (
  authManager: angular.jwt.IAuthManagerServiceProvider
) => {
  "ngInject";
  authManager.checkAuthOnRefresh();
  authManager.redirectWhenUnauthenticated();
};

/**
 * This starts pace.js progress monitoring
 */
export const startPace = () => {
  pace.start();
};

export function isDevEnvironment() {
  return window.location.hostname.search("glauconet.net") === -1;
}
