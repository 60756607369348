import { PatientRecord, PatientRecordData } from "models/patient-record.model";
import { Appendix, IGlOption } from "../../../services/appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import "./va.scss";
import angular = require("angular");

class VaController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  /** Inputs */
  enableLeft = true;
  enableRight = true;
  record: PatientRecordData;
  recordHistory: PatientRecord[];
  newRecord: boolean;
  /** End Inputs */

  showSubjectiveVa = false;
  showVaAided = false;
  showVaUnaided = false;
  showPinHole = false;
  vaNumeratorOptions = this.appendix.get("visualAccuityNumerator");
  vaDenominatorOptions = this.appendix.get("visualAccuityDenominator", true);
  phNumeratorOptions = this.appendix.get("pinholeNumerator");
  phDenominatorOptions = this.appendix.get("pinholeDenominator", true);
  modeAlreadyUpdated = false;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  $onChanges(onChangesObj: angular.IOnChangesObject): void {
    if (
      (onChangesObj.record ||
        onChangesObj.recordHistory ||
        onChangesObj.mode) &&
      this.record &&
      this.mode === "edit"
    ) {
      if (this.modeAlreadyUpdated) {
        //only update tab selections once
        return;
      }
      if (onChangesObj.mode && this.mode === "edit") {
        this.modeAlreadyUpdated = true;
      }
      const currentRecordHasAnyVa =
        this.hasVaAided(this.record) ||
        this.hasVaUnAided(this.record) ||
        this.hasVaSubjective(this.record) ||
        this.hasPinHole(this.record);

      /**
       * First check if this record has any va data (subjective, aided, unaided,
       * pinhole). If the current record does, then set the tab states based on
       * the current record.
       */
      if (currentRecordHasAnyVa) {
        this.showVaAided = this.hasVaAided(this.record);
        this.showVaUnaided = this.hasVaUnAided(this.record);
        this.showSubjectiveVa = this.hasVaSubjective(this.record);
        this.showPinHole = this.hasPinHole(this.record);
      } else {
        /** if the current record does NOT have any VA. Then find the most
         * recent record from the record history that does. Then set the tab
         * states based on the fields entered in that record. */
        // from the record history, find the most recent record that has any va
        const mostRecentVaRecord = this.recordHistory
          .map((r) => r.data)
          .reverse()
          .find((r) => {
            return (
              this.hasVaAided(r) ||
              this.hasVaUnAided(r) ||
              this.hasVaSubjective(r) ||
              this.hasPinHole(r)
            );
          });
        this.showVaAided = this.hasVaAided(mostRecentVaRecord);
        this.showVaUnaided = this.hasVaUnAided(mostRecentVaRecord);
        this.showSubjectiveVa = this.hasVaSubjective(mostRecentVaRecord);
        this.showPinHole = this.hasPinHole(mostRecentVaRecord);
      }
      if (
        !this.showVaAided &&
        !this.showVaUnaided &&
        !this.showSubjectiveVa &&
        !this.showPinHole
      ) {
        // if this record has no VA at all, then default the Aided VA as default
        // for legacy records
        this.showVaUnaided = true;
      }
    }
  }

  hasVaAided(recordData: PatientRecordData) {
    return !!(
      recordData?.aided_va_numerator?.left ||
      recordData?.aided_va_numerator?.right ||
      recordData?.aided_va_denominator?.left ||
      recordData?.aided_va_denominator?.right
    );
  }

  hasVaUnAided(recordData: PatientRecordData) {
    return !!(
      recordData?.va_numerator?.left ||
      recordData?.va_numerator?.right ||
      recordData?.va_denominator?.left ||
      recordData?.va_denominator?.right
    );
  }

  hasVaSubjective(recordData: PatientRecordData) {
    return !!(
      recordData?.subjective_va_numerator?.left ||
      recordData?.subjective_va_numerator?.right ||
      recordData?.subjective_va_denominator?.left ||
      recordData?.subjective_va_denominator?.right
    );
  }

  hasPinHole(recordData: PatientRecordData) {
    return !!(
      recordData?.ph_numerator?.left ||
      recordData?.ph_numerator?.right ||
      recordData?.ph_denominator?.left ||
      recordData?.ph_denominator?.right
    );
  }

  // hide pinhole denominator if numerator is a
  //speicifc key
  shouldHideDenominator(option: IGlOption) {
    return ["not-examined", "no-improvement"].includes(option?.key);
  }

  shouldDisplayOption(option: IGlOption) {
    return this.appendix.shouldDisplayOption(option);
  }
}

export class VaComponent implements angular.IComponentOptions {
  static selector = "va";
  static template = require("./va.html");
  static controller = VaController;
  static bindings = {
    enableLeft: "<?",
    enableRight: "<?",
    newRecord: "<?",
    recordHistory: "<?",
    record: "<",
    ...GlFormControllerBindings,
  };
}
