import { isDefined, isUndefined } from "angular";
import { GlModelDefaultMode } from "app/core/directive/gl-model";
import { clamp, set } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import { Appendix } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

export interface IStats {
  min: number;
  max: number;
  decimals: number;
  step: number;
}

class BilateralNumberController
  extends GlFormController
  implements angular.IController, angular.IOnInit
{
  copy: boolean;
  defaultLeft: number;
  defaultRight: number;
  enableLeft: boolean;
  enableRight: boolean;
  field: any;
  key: string;
  leftIcon: string;
  ngRequired = true;
  path: string;
  rightIcon: string;
  stats: IStats;
  title: string;
  defaultMode?: GlModelDefaultMode;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  $onInit() {
    this.stats = this.appendix.getOptions(this.key);
  }

  validation(side: IGlSide, event: any) {
    const val = parseFloat(event.target.value);
    this._validate(val, side);
  }

  getPlaceholder() {
    return isUndefined(this.stats)
      ? this.title
      : `${this.stats.min.toFixed(
          this.stats.decimals
        )} - ${this.stats.max.toFixed(this.stats.decimals)}`;
  }

  getGlModelPath(side: IGlSide) {
    if (this.path) {
      return this.path + "." + side;
    }
  }

  /**
   * @ignore
   * IGNORE UNTIL WE REINTRODUCE BILATERAL NUMBER LENS CLEAR
   */
  resetValues() {
    set(this.field, this.key, {
      left: 0,
      right: 0,
    });
  }

  private _validate(val: number, side: IGlSide) {
    if (isDefined(this.stats)) {
      if (isUndefined(this.field[this.key])) {
        this.field[this.key] = {};
      }
      this.field[this.key][side] = clamp(val, this.stats.min, this.stats.max);
    }
  }
}

export class BilateralNumber implements angular.IComponentOptions {
  static selector = "bilateralNumber";
  static template = require("./bilateral-number.html");
  static controller = BilateralNumberController;
  static bindings = {
    copy: "<",
    defaultLeft: "<?",
    defaultRight: "<?",
    enableLeft: "<",
    enableRight: "<",
    field: "<",
    isEditable: "<?",
    key: "@",
    leftIcon: "@",
    mode: "@?",
    ngRequired: "<?",
    path: "@?",
    rightIcon: "@",
    title: "@",
    defaultMode: "@?",
  };
}
