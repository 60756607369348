import { copy } from "angular";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { GL_VIEWS } from "app/pages/main.record/components/view-manager/view-manager.component";
import {
  cloneDeep,
  defaultsDeep,
  first,
  isArray,
  isEqual,
  isNil,
  isObject,
  set,
  some,
  sortBy,
} from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlBilateral,
  GlDiagnosis,
  GlDiagnosisOption,
  PatientRecordData,
} from "models/patient-record.model";
import { Patient, User } from "models/user.model";
import { PATIENT_RECORD_EVENT_SIGN } from "../../../../../app/pages/main.record/record";
import { DiagnosisService } from "../../../services/diagnosis.service";
import { GlModelService } from "../../../services/gl-model.service";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

import { AutofillHelperService } from "app/core/services/autofill-helper/autofill-helper.service";
import {
  AutofillService,
  IAutofillIconParams,
} from "app/core/services/autofill/autofill.service";
import { ChangesService } from "app/core/services/changes/changes.service";
import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlFormMode } from "models/gl-form-mode";
import { DIAGNOSIS_ARRAY_KEY } from "../../../../../lib/key-appendix";
import "./patient-diagnosis.scss";

export class DiagnosisController
  extends GlFormController
  implements angular.IController, angular.IOnChanges {
  // @Input()
  enableLeft = true;
  enableRight = true;
  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  selectedCondition: GL_VIEWS;
  diagnosisForDisplay: GlBilateral<GlDiagnosis[]>;
  user: User;
  patient: Patient;

  mode: IGlFormMode;

  patientDiagnosisForm: angular.IFormController;

  // to keep track of autofill state
  diagnosisAutofillInit: boolean = false;

  diagnosisMessages = this.ToastrAppendix.getDiagnosisMessages();

  // diagnosis autofill icon from source
  autofillIconSourceParams: IAutofillIconParams[];
  autofillIconTargetParams: IAutofillIconParams[];
  // diagnosis autofill icon from target

  constructor(
    private $scope: angular.IScope,
    private $stateParams: angular.ui.IStateParamsService,
    private ToastrAppendix: ToastrAppendix,
    private DiagnosisService: DiagnosisService,
    private GlModelService: GlModelService,
    private toastr: angular.toastr.IToastrService,
    private AutofillService: AutofillService,
    private ChangesService: ChangesService,
    private AutofillHelperService: AutofillHelperService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // on main record sign, it saves all form data incl of this
    // so have to set to pristine
    this.$scope.$on(PATIENT_RECORD_EVENT_SIGN, () => {
      if (this?.patientDiagnosisForm?.$dirty) {
        this.patientDiagnosisForm.$setPristine();
      }
    });

    // TEST FOR DIAGNOSIS KEYS
    this.autofillIconSourceParams =
      this.AutofillService.getAutofillIconParamsForSourceKey(
        DIAGNOSIS_ARRAY_KEY
      );
    this.autofillIconTargetParams =
      this.AutofillService.getAutofillIconParamsForTargetKey(
        DIAGNOSIS_ARRAY_KEY
      );
  }

  $onChanges() {
    if (this.record && this.isEditable && this.isEditMode()) {
      // If we are in edit mode, migrate any legacy diagnosis fields to the
      // diagnosis_array and delete the old key
      const diagnosis = this.record?.management?.diagnosis;
      if (diagnosis) {
        this.record.management.diagnosis_array = {
          ...(this.enableLeft && { left: [diagnosis.left] }),
          ...(this.enableRight && { right: [diagnosis.right] }),
        };
        delete this.record.management.diagnosis;
      }
      const baseDiagnosis: GlBilateral<GlDiagnosis[]> = {
        ...(this.enableLeft && { left: [] }),
        ...(this.enableRight && { right: [] }),
      };

      const defaultsRaw = {
        management: {
          diagnosis_array:
            this.GlModelService.get("management.diagnosis_array") ||
            baseDiagnosis,
        },
      };

      const isTechnician = this.user.type.name === "technician";

      const defaults = {
        management: {
          diagnosis_array: {
            right: !isTechnician
              ? this.getDiagnosisDefaults(
                defaultsRaw.management.diagnosis_array.right,
                this.record.management?.diagnosis_array?.right
              )
              : undefined,
            left: !isTechnician
              ? this.getDiagnosisDefaults(
                defaultsRaw.management.diagnosis_array.left,
                this.record.management?.diagnosis_array?.left
              )
              : undefined,
          },
        },
      };

      defaultsDeep(this.record, defaults);

      const defaultDiagnosis = this.DiagnosisService.getDefaultDiagnosis(
        !isTechnician ? this.selectedCondition : undefined
      );
      if (
        !this.record.management.diagnosis_array.left ||
        (this.record.management.diagnosis_array.left?.length === 0 &&
          this.enableLeft)
      ) {
        this.record.management.diagnosis_array.left = [copy(defaultDiagnosis)];
      }

      // right side declared or is empty and enabled
      if (
        !this.record.management.diagnosis_array.right ||
        (this.record.management.diagnosis_array.right?.length === 0 &&
          this.enableRight)
      ) {
        this.record.management.diagnosis_array.right = [copy(defaultDiagnosis)];
      }

      // left side declared or is empty and enabled
      if (this.enableLeft === false || this.enableRight === false) {
        this.record.management.diagnosis_array = {
          ...(this.enableLeft && {
            left: this.record.management.diagnosis_array.left,
          }),
          ...(this.enableRight && {
            right: this.record.management.diagnosis_array.right,
          }),
        };
      }
    }
  }

  // DIAGNOSIS RELATED
  getDiagnosisDefaults(currentDefaults: any, currentRecord: any) {
    // this function goes through each of the default values and filters out any that
    // already have that value in current record.
    // Otherwise if a field has 1 level but the default
    // (from previous record) has more than 1 level,
    // the other levels are displayed
    // -> ie current record = healthy but previous record retina, t
    // he no dr field gets shown.
    if (!currentRecord || !currentDefaults || currentDefaults.length === 0) {
      return currentDefaults;
    }
    const diagnosis_array = [];
    for (let i = 0; i < currentDefaults.length; i++) {
      if (!currentRecord[i]) {
        diagnosis_array.push(currentDefaults[i]);
      } else {
        diagnosis_array.push(currentRecord[i]);
      }
    }
    return diagnosis_array;
  }

  getDiagnosisForDisplay() {
    const { diagnosis, diagnosis_array } = this.record?.management || {};
    if (diagnosis_array) {
      return diagnosis_array;
    } else if (!diagnosis_array && diagnosis) {
      return {
        left: [diagnosis.left],
        right: [diagnosis.right],
      };
    }
  }

  getBiggestDiagnosisArray() {
    const diagnosis = this.getDiagnosisForDisplay();
    const left = diagnosis?.left || [];
    const right = diagnosis?.right || [];
    return left.length > right.length ? left : right;
  }

  getDiagnosis(diagnosis: GlDiagnosis) {
    return this.DiagnosisService.getDiagnosis(diagnosis);
  }

  showOtherTextField(diagnosis: GlDiagnosis) {
    return this.DiagnosisService.showOtherTextField(diagnosis);
  }

  // DIAGNOSIS ON CHANGES
  level1OnChange(
    side: IGlSideBilateral,
    diagnosis: GlDiagnosis,
    oldDiagnosisArray: GlDiagnosis[],
    // eslint-disable-next-line
    index: number
  ) {
    // if its an object (legacy data or gl-model related)
    // convert it into an array
    if (!isArray(oldDiagnosisArray) && isObject(oldDiagnosisArray)) {
      oldDiagnosisArray = [oldDiagnosisArray];
    }

    // if no diagnosis changed, ignore
    if (!diagnosis) {
      return;
    }

    // if level 1 changes. then set the child options to defaults
    const { level1 } = diagnosis;
    // get a clone
    const diagnosisArrayClone: GlBilateral<GlDiagnosis[]> = cloneDeep(
      this.record.management.diagnosis_array
    );

    // if the diagnosis is found
    if (level1) {
      // EDGE CASE: if level 1 doenst allow for additional options
      // replace all with just healthy
      if (!this.isDiagnosisMultiSelect(level1)) {
        // if exists replace

        const diagnosisArray: GlDiagnosis[] = diagnosisArrayClone[side];

        // replace with previous one for sorting
        // undo autofill for all current diagnoses
        this.removeAutofillDiagnosesOnSide(diagnosisArray, side);

        // remove all other diagnoses
        delete diagnosis.level2;
        delete diagnosis.level3;
        delete diagnosis.level4;

        // set as the only option
        this.record.management.diagnosis_array[side] = [cloneDeep(diagnosis)];
      } else {
        // set level 2 to the default value
        diagnosis.level2 = first(
          this.DiagnosisService.LEVEL2_OPTIONS[level1.key]
        );
      }
    } else {
      delete diagnosis.level2;
    }

    // set the child values
    this.level2OnChange(side, diagnosis);
  }

  level2OnChange(side: IGlSideBilateral, diagnosis: GlDiagnosis) {
    // if level 1 changes. then set the child options to defautls
    const { level2 } = diagnosis;

    if (level2) {
      // set level 2 to the default value
      diagnosis.level3 = first(
        this.DiagnosisService.LEVEL3_OPTIONS[level2.key]
      );
    } else {
      delete diagnosis.level3;
    }

    if (!this.showOtherTextField(diagnosis)) {
      delete diagnosis.level2_other;
    }

    // set the child values
    this.level3OnChange(side, diagnosis);
  }

  level3OnChange(
    // eslint-disable-next-line
    side: IGlSideBilateral,
    diagnosis: GlDiagnosis
  ) {
    // if level 1 changes. then set the child options to defautls
    const { level3 } = diagnosis;

    if (level3) {
      // set level 2 to the default value
      diagnosis.level4 = first(
        this.DiagnosisService.LEVEL4_OPTIONS[level3.key]
      );
    } else {
      delete diagnosis.level4;
    }
  }

  // OPTIONS RELATED
  getLevel1Options() {
    return this.DiagnosisService.LEVEL1_OPTIONS;
  }

  getLevel2Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level1) {
      return this.DiagnosisService.LEVEL2_OPTIONS[diagnosis.level1.key];
    }
  }

  getLevel3Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level2) {
      return this.DiagnosisService.LEVEL3_OPTIONS[diagnosis.level2.key];
    }
  }

  getLevel4Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level3) {
      return this.DiagnosisService.LEVEL4_OPTIONS[diagnosis.level3.key];
    }
  }

  // GENERAL DIAGNOSIS CRUD
  addDiagnosis(diagnosisArray: GlDiagnosis[]) {
    diagnosisArray.push({ level1: undefined });
    // set form dirty mock
    this.patientDiagnosisForm.$setDirty();
  }

  deleteDiagnosis(side: IGlSideBilateral, index: number) {
    const diagnosisArray: GlDiagnosis[] =
      this.record.management.diagnosis_array[side];
    // if empty remove other side
    this.undoAutofillByIndex(diagnosisArray[index], side, index);
    // splice
    diagnosisArray.splice(index, 1);

    // set form dirty mock
    this.patientDiagnosisForm.$setDirty();
  }

  // split into two parts, one is diagnosis autofill
  // the other is a procedure autofill
  copyDiagnosis(index: number, toSide: IGlSideBilateral) {
    const fromSide = toSide === "right" ? "left" : "right";

    // old value to store
    const oldValue = this.record?.management?.diagnosis_array?.[toSide]?.[index];
    // value we want to copy over
    const newValue = cloneDeep(
      this.record?.management?.diagnosis_array?.[fromSide]?.[index]
    );
    newValue.id = this.generateDiagnosisId();

    // baseline error
    if (isNil(newValue)) {
      return this.toastr.error(
        this.diagnosisMessages.error.copy.diagnosis.doesnt_exist
      );
    }

    // CASE: check if other side has a non-multi-select field
    else if (this.sideHasNonMultiSelectDiagnosis(toSide)) {
      // then block and error out
      switch (index) {
        // by defautl should be an identical clone
        case 0:
          set(this.record, `management.diagnosis_array.${toSide}`, [
            cloneDeep(newValue),
          ]);
          break;
        default:
          return this.toastr.error(
            this.diagnosisMessages.error.copy.multi_select
              .cannot_copy_to_non_multiselect
          );
      }
    } else if (this.sideHasNonMultiSelectDiagnosis(fromSide)) {
      // CASE: are we copying a non-multi-select field over
      // handle and remove autofill
      this.removeAutofillDiagnosesOnSide(
        this.record.management.diagnosis_array[toSide],
        toSide
      );

      // then assign
      set(this.record, `management.diagnosis_array.${toSide}`, [
        cloneDeep(newValue),
      ]);
    }

    // DEFAULT: just go about as usual and hceck for erros
    set(
      this.record,
      `management.diagnosis_array.${toSide}[${index}]`,
      cloneDeep(newValue)
    );

    // create a change object
    const changeObj: IGlChangesRecordData = {
      currentValue: newValue,
      previousValue: oldValue,
      side: toSide,
      sourceKey: DIAGNOSIS_ARRAY_KEY,
      sourcePath:
        this.AutofillHelperService.getAutofillPath(DIAGNOSIS_ARRAY_KEY),
      recordData: this.record,
      type: "copy",
      id: newValue.id,
      index,
      originKey: DIAGNOSIS_ARRAY_KEY,
      originPath: this.AutofillService.getAutofillPath(DIAGNOSIS_ARRAY_KEY),
      extraData: {
        recordId: this.recordId,
        patientId: this.patient?.id,
      },
    };

    this.ChangesService.publish(changeObj);
  }

  // OBS <-> DIAGNOSIS
  // remove autofill for one diagnosis
  undoAutofillByIndex(
    diagnosis: GlDiagnosis,
    side: IGlSideBilateral,
    index: number
  ) {
    // WE NEED TO FIRE TWO: ONE FOR TARGET AND ONE FOR SOURCE
    const targetObj: IGlChangesRecordDataOrigin = {
      currentValue: diagnosis,
      sourceKey: DIAGNOSIS_ARRAY_KEY,
      sourcePath: this.AutofillService.getAutofillPath(DIAGNOSIS_ARRAY_KEY),
      side,
      index,
      type: "autofill_undo",
      origin: "both",
      id: diagnosis?.id,
      recordData: this.record,
      originKey: DIAGNOSIS_ARRAY_KEY,
      originPath: this.AutofillService.getAutofillPath(DIAGNOSIS_ARRAY_KEY),
      extraData: {
        recordId: this.recordId,
        patientId: this.patient.id,
      },
    };

    // then send over
    this.ChangesService.publish(targetObj);
  }

  // same as above but for ALL diagnoses on one side
  removeAutofillDiagnosesOnSide(
    diagnoses: GlDiagnosis[],
    side: IGlSideBilateral
  ) {
    // sort diagnoses by index
    const sorted: GlDiagnosis[] = sortBy(diagnoses, (d) =>
      this.record?.management?.diagnosis_array?.[side]?.findIndex((_d) =>
        isEqual(d, _d)
      )
    );

    for (const diagnosis of sorted?.reverse() ?? []) {
      const index: number = this.record?.management?.diagnosis_array?.[
        side
      ]?.findIndex((_d) => isEqual(diagnosis, _d));
      if (index !== -1) {
        this.undoAutofillByIndex(diagnosis, side, index);
      }
    }
  }

  // diagnosis side has a restricted diagnosis?
  // e.g. Healthy is one as you cant be diagnoses with
  // anything else after healthy
  sideHasNonMultiSelectDiagnosis(side: IGlSideBilateral) {
    for (const diagnosis of this.record.management.diagnosis_array[side] ??
      []) {
      if (isNil(diagnosis)) {
        continue;
      }

      const { level1, level2 } = diagnosis;

      if (some([level1, level2], (o) => !this.isDiagnosisMultiSelect(o))) {
        return true;
      }
    }

    return false;
  }

  // general helper, multi select === allows for extra diagnoses
  // anythign that isnt healthy basically
  isDiagnosisMultiSelect(diagnosis: GlDiagnosisOption) {
    // DEBUG: for bypassing multiple diagnosis selection
    // return true;
    return this.DiagnosisService.checkIfDiagnosisAllowsMultiSelect(diagnosis);
  }

  // OPTIONS
  generateDiagnosisId(diagnosis?: GlDiagnosis) {
    return diagnosis?.id ?? this.AutofillHelperService.generateAutofillId();
  }

  getDiagnosisId(diagnosis: GlDiagnosis, altId: string) {
    return diagnosis?.id ?? altId;
  }

  setId(diagnosis: GlDiagnosis, id: string) {
    if (isNil(diagnosis) || !isNil(diagnosis?.id)) {
      return;
    }
    // instantiated id always takes precedence
    diagnosis.id = id;
  }
}

export class PatientDiagnosisComponent implements angular.IComponentOptions {
  static selector = "patientDiagnosis";
  static template = require("./patient-diagnosis.html");
  static controller = DiagnosisController;

  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    record: "<",
    selectedCondition: "<?",
    user: "<",
    patient: "<",
    ...GlFormControllerBindings,
  };
}
