import * as moment from "moment";
import { IGlSide } from "../../../models/gl-side.model";
import {
  IPatientDocumentDicomData,
  PatientDocument,
  ReferralDetails,
  ReviewDetails,
} from "../../../models/patient-document.model";
import { GlDropzoneFileWrapper } from "../components/data/gl-dropzone/gl-dropzone";
import { API_PATH, API_PATH_v2, API_PATH_v3 } from "./api-paths";

export interface IResourcePatientDocument
  extends PatientDocument,
  angular.resource.IResource<PatientDocument> { }

// storage key stuff
export const STORAGE_KEY_LAST_CHECK_TIME = "gl:lastCheckTime";
export const STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL = "gl:documentUploadUpdateInterval";

export const UPDATE_INTERVAL_MANUAL = "Manual";
export const UPDATE_INTERVAL_2_MINS = "2 Mins";
export const UPDATE_INTERVAL_5_MINS = "5 Mins";
export const UPDATE_INTERVAL_10_MINS = "10 Mins";
export const UPDATE_INTERVAL_30_MINS = "30 Mins";

export class PatientDocumentService {
  static injectionName = "PatientDocumentService";
  path = `${this.API_URL}${API_PATH}/document/:id`;
  patientId: number;
  documents: PatientDocument[];
  apiV2Base = `${this.API_URL}${API_PATH_v2}`;
  apiV3Base = `${this.API_URL}${API_PATH_v3}`;
  public ngResource = this.$resource<IResourcePatientDocument>(
    this.path,
    { id: "@id" },
    {
      get: { cache: true, method: "GET" },
    }
  );
  private cache = this.$cacheFactory.get("$http");

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService,
    private $http: angular.IHttpService,
    private $q: angular.IQService
  ) {
    "ngInject";
  }

  get(id: number) {
    return this.$http
      .get<PatientDocument>(`${this.API_URL}${API_PATH}/document/${id}`)
      .then((response) => response.data);
  }

  getAllForPatient(patientId: number) {
    return this.$http
      .get<PatientDocument[]>(
        `${this.apiV2Base}/patient/${patientId}/documents`
      )
      .then((response) => response.data);
  }

  delete(document: PatientDocument) {
    return this.$http
      .delete(`${this.API_URL}${API_PATH_v2}/document/${document.id}`)
      .then((response) => response.data);
  }

  updateReferralTag(document: PatientDocument, tag: string) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        {
          tags: tag,
        }
      )
      .then((response) => response.data);
  }

  updateDocumentReviewDetails(
    document: PatientDocument,
    reviewDetails: ReviewDetails
  ) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        {
          review_details: reviewDetails,
          review_status: reviewDetails.status,
        }
      )
      .then((response) => response.data);
  }

  updateDocumentReferralDetails(
    document: PatientDocument,
    details: ReferralDetails
  ) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        {
          referral_details: details,
        }
      )
      .then((response) => response.data);
  }

  updateDocument(document: PatientDocument) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        document.data
      )
      .then((response) => response.data);
  }

  updateDocumentDate(document: PatientDocument, date: string) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        {
          updated_created_date: date,
        }
      )
      .then((response) => response.data);
  }

  updateReferralDetails(
    document: PatientDocument,
    referral_details: ReferralDetails
  ) {
    return this.$http
      .put<PatientDocument>(
        `${this.API_URL}${API_PATH_v2}/document/${document.id}`,
        {
          referral_details,
        }
      )
      .then((response) => response.data);
  }

  getDocumentReferralsUrl(documents: PatientDocument[]) {
    return documents?.findIndex(
      (pd) =>
        pd.data?.tags?.includes("referral") &&
        !pd.data?.tags?.includes("referral-old")
    );
  }

  create(
    patientId: number,
    recordId: number | null,
    file: Blob,
    name: string,
    data?: {
      eye?: IGlSide;
      field: string;
      dicom_data?: IPatientDocumentDicomData;
      tags?: string;
      mime?: string;
      extra_details?: {
        title: string;
        more_information?: string;
      };

      // consent form
      consent_record_id?: number;
    },
    onProgress?: (progress: ProgressEvent) => void
  ) {
    const fd = new FormData();
    fd.append("file", file, name);
    if (recordId) {
      fd.append("patient_record_id", recordId.toString());
    }
    if (data?.field) {
      fd.append("field", data.field);
    }
    if (data?.eye) {
      fd.append("eye", data.eye);
    }

    if (data?.dicom_data) {
      fd.append("dicom_data", JSON.stringify(data.dicom_data));
    }

    if (data?.tags) {
      fd.append("tags", data.tags);
    }

    if (data?.mime) {
      fd.append("mime", data.mime);
    }

    if (data?.extra_details) {
      fd.append("extra_details", JSON.stringify(data.extra_details));
    }

    return this.$http({
      method: "POST",
      url: `${this.API_URL}${API_PATH_v2}/patient/${patientId}/document`,
      data: fd,
      headers: { "Content-Type": undefined },
      uploadEventHandlers: {
        progress: onProgress,
      },
    }).then((response) => response.data);
  }

  // uses the wrapper to create for a ptient
  uploadDocumentsForPatient(
    documents: GlDropzoneFileWrapper[],
    patientId: number,
    recordId?: number
  ) {
    // // we have to break it up into three different arrays to send over
    const formData: FormData = new FormData();
    for (const document of documents) {
      // separate it out into file, data and dicom data
      const file: File = document.file;
      const data = document?.data ?? null;
      const dicomData = document?.dicom_data ?? null;

      /*
        multiple appends under the same key means it will
        become an array 
        https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
      */
      formData.append("file_array[]", file);
      formData.append("data_array[]", JSON.stringify(data));
      formData.append("dicom_data_array[]", JSON.stringify(dicomData));
    }

    if (recordId) {
      formData.append("patient_record_id", recordId.toString());
    }

    return this.$http
      .post(`${this.apiV3Base}/patient/${patientId}/document`, formData, {
        headers: { "Content-Type": undefined },
      })
      .then((res: any) => {
        return res.data;
      });
  }

  refreshUrl(document: PatientDocument) {
    return this.$q.resolve().then(() => {
      let url;
      let AmzRequestDate;
      let AmzExpiryDuration;
      try {
        url = new URL(document.url);
        AmzRequestDate = url.searchParams.get("X-Amz-Date");
        AmzExpiryDuration = url.searchParams.get("X-Amz-Expires");
      } finally {
        //
      }

      if (AmzRequestDate && AmzExpiryDuration) {
        // this is an s3 url
        const expiry = moment(AmzRequestDate).add(AmzExpiryDuration, "seconds");
        if (this.getTimeWithRefreshThreshold().isBefore(expiry)) {
          // this is a valid url
          return document;
        } else {
          return this.get(document.id);
        }
      } else {
        // this isn't an s3 url so it is always valid
        return document;
      }
    });
  }

  /**
   * Add 3 minutes to the current time. Use this to determine if the URL needs
   * to be updated. This minimizes problems with expired signed urls and clock
   * drift
   */
  private getTimeWithRefreshThreshold() {
    return moment().add(3, "minutes");
  }
}
