import { isArray } from "angular";
import { GlModelDefaultMode } from "app/core/directive/gl-model";
import { Appendix, IGlOption } from "app/core/services/appendix";
import { AuthService } from "app/core/services/auth.service";
import { AutofillHelperService } from "app/core/services/autofill-helper/autofill-helper.service";
import {
  AutofillService,
  IAutofillIconParams,
} from "app/core/services/autofill/autofill.service";
import { ChangesService } from "app/core/services/changes/changes.service";
import { LoggingService } from "app/core/services/logging/logging.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import {
  cloneDeep,
  defaultsDeep,
  get,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isUndefined,
  set,
  sortBy,
} from "lodash";
import {
  IGlChangeType,
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlSideBilateral } from "models/gl-side.model";
import { GlBilateral, PatientRecordData } from "models/patient-record.model";
import { PATIENT_RECORD_EVENT_SIGN } from "../../../../../app/pages/main.record/record";
import { GlFormController } from "../../gl-form-controller";

export class BilateralSelectMultipleController
  extends GlFormController
  implements angular.IComponentController, angular.IOnInit
{
  field: PatientRecordData;
  enableRight = false;
  enableLeft = false;
  generalDefaultOption: IGlOption;
  defaultLeft: IGlOption;
  defaultRight: IGlOption;
  // gl-model default mode
  defaultMode?: GlModelDefaultMode;

  copy: boolean;
  optionKey: string;
  options: IGlOption[];
  customOptions: IGlOption[];
  useCustomOptions: boolean = false;

  other: boolean = true;
  otherKey: string;
  key: string;
  path: string;
  legacyKey: string;
  title: string;

  longestArrayNumber: number;

  diagnosisMessages = this.ToastrAppendix.getDiagnosisMessages();
  observationMessages = this.ToastrAppendix.getObservationsMessages();

  // record id
  recordId: number = this.$stateParams.recordId;
  patientId: number = this.$stateParams.patientId;

  // form
  bilateralSelectMultipleForm: angular.IFormController;

  // autofill icon refs
  autofillIconSourceParams: IAutofillIconParams[];
  autofillIconTargetParams: IAutofillIconParams[];

  onChange: (arg: {
    option: IGlOption;
    side: IGlSideBilateral;
    index?: number;
  }) => void;

  constructor(
    private $scope: angular.IScope,
    private PatientRecordService: PatientRecordService,
    private AutofillService: AutofillService,
    private AutofillHelperService: AutofillHelperService,
    private ToastrAppendix: ToastrAppendix,
    private appendix: Appendix,
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    private LoggingService: LoggingService,
    private ChangesService: ChangesService,
    private $stateParams: angular.ui.IStateParamsService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // setup listener
    this.$scope.$on(PATIENT_RECORD_EVENT_SIGN, () => {
      if (this?.bilateralSelectMultipleForm?.$dirty) {
        this.bilateralSelectMultipleForm.$setPristine();
      }
    });

    // initialise options
    if (this.optionKey) {
      this.options = this.appendix.get(this.optionKey);
    } else {
      this.options = this.appendix.get(this.key);
    }

    // for autofill icon
    this.autofillIconSourceParams =
      this.AutofillService.getAutofillIconParamsForSourceKey(this.key);
  }

  // eslint-disable-next-line
  $onChanges(): void {
    // directive will trigger first before this
    if (this.isEditMode() && this.field) {
      // else defaults for the bilateral field as it
      // needs something to render into
      // gl-model will clear up the null case
      const defaults = {};
      defaults[this.key] = {};

      // use default if specified otherwise null
      if (this.enableLeft) {
        defaults[this.key].left = [this.getDefaultOptionForSide("left")];
      }
      if (this.enableRight) {
        defaults[this.key].right = [this.getDefaultOptionForSide("right")];
      }

      defaultsDeep(this.field, defaults);

      // edge case if set by GL MODEL
      // GL-MODEL sets values as an object
      // this converts it into an array
      // potential new one
      if (
        !isArray(this.field[this.key]?.right) ||
        isUndefined(this.field?.[this.key]?.right)
      ) {
        const rightOption = !isNil(this.field?.[this.key]?.right)
          ? this.field?.[this.key]?.right
          : this.getDefaultOptionForSide("right");

        if (rightOption && isNil(rightOption?.id)) {
          rightOption.id = this.generateOptionId();
        }

        set(this.field, `${this.key}.right`, [rightOption]);
      }
      if (
        !isArray(this.field[this.key]?.left) ||
        isUndefined(this.field?.[this.key]?.left)
      ) {
        const leftOption = !isNil(this.field?.[this.key]?.left)
          ? this.field?.[this.key]?.left
          : this.getDefaultOptionForSide("left");

        if (leftOption && isNil(leftOption?.id)) {
          leftOption.id = this.generateOptionId();
        }

        set(this.field, `${this.key}.left`, [leftOption]);
      }

      // old implementation
      // if (
      //   !isArray(this.field[this.key]?.left) &&
      //   isObject(this.field[this.key]?.left)
      // ) {
      //   this.field[this.key].left = [this.field[this.key].left];
      // }
      // if (
      //   !isArray(this.field[this.key]?.right) &&
      //   isObject(this.field[this.key]?.right)
      // ) {
      //   this.field[this.key].right = [this.field[this.key].right];
      // }
    }
  }

  // EXPERIMENTAL
  // for autofill before confirming
  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // array balance
  getBiggestObservationArray() {
    const observations: GlBilateral<IGlOption[]> = this?.field?.[this.key];
    const left = observations?.left ?? [];
    const right = observations?.right ?? [];
    return left.length > right.length ? left : right;
  }

  getPath(side: IGlSideBilateral): string {
    if (this.path) {
      return `${this.path}.${this.key}.${side}`;
    }
    return `${this.key}.${side}`;
  }

  getPathAtIndex(side: IGlSideBilateral, index: number): string {
    return `${this.getPath(side)}.${index}`;
  }

  getOtherPath(): string {
    if (this.path) {
      return `${this.path}.${this.otherKey}`;
    }
    return this.otherKey;
  }

  // custom options will only be used if not empty and toggled
  getOptions() {
    return this.useCustomOptions &&
      !isNil(this.customOptions) &&
      !isEmpty(this.customOptions)
      ? this.customOptions
      : this.options;
  }

  // if custom options in use then use custom options
  // else follow gl-model
  getDefaultOptionForSide(side: IGlSideBilateral) {
    // //  do left or right as usual and return null otherwise
    // let defaultOption = this.appendix.getDefaultKey(this.key);
    // defaultOption = isObject(defaultOption) ? defaultOption : undefined;

    return side === "left"
      ? this?.defaultLeft ?? undefined
      : this?.defaultRight ?? undefined;
  }

  // description?
  shouldShowDescription(val: IGlOption) {
    if (val) {
      const keyOpt = this.options.find((opt) => opt.key === val.key);
      const shouldShowOther = (keyOpt && keyOpt.showOther) || false;
      return (shouldShowOther || val.key === "other") && this.other === true;
    } else {
      return false;
    }
  }

  // manual trigger
  // this is the final set of actions before sending off to autofill
  handleAutofill(
    side: IGlSideBilateral,
    index: number,
    oldObservations: GlBilateral<[IGlOption]>
  ) {
    // setup your values
    const currentValue: IGlOption = this.field?.[this.key]?.[side]?.[index];
    const previousValue: IGlOption = oldObservations?.[side]?.[index];

    // pass down to autofill
    this.AutofillService.handleAutofill({
      sourceKey: this.key,
      currentValue,
      previousValue,
      side,
      recordData: this.field,
      id: currentValue?.id,
      type: "autofill",
      originKey: this.key,
      originPath: this.AutofillService.getAutofillPath(this.key),
    });
  }

  // for logging things
  handleChangeLogging(
    side: IGlSideBilateral,
    currentValue: IGlOption | IGlOption[], // can be option or an array
    oldObservations: GlBilateral<IGlOption[]>,
    changeType: IGlChangeType
  ) {
    this.LoggingService.pushToRecordChangesLog({
      sourcePath: `${
        this.AutofillService.getAutofillPath(this.key) ?? `${this.key}`
      }.${side}`,
      sourceKey: this.key,
      previousValue: oldObservations?.[side],
      currentValue,
      type: changeType,

      originKey: this.key,
      originPath: this.AutofillService.getAutofillPath(this.key),
    });
  }

  // AUTOFILL RELATED
  // primary change function that will call the relevant
  // change cycle

  selectDidChange(side: IGlSideBilateral, index: number, optionId?: any) {
    const option: IGlOption = this.field[this.key]?.[side]?.[index];
    // if no id set id
    if (!isNil(option) && isNil(option?.id)) {
      // use exisitng one and if not existing, re-generate
      option.id = optionId;
    }

    // check if we need to revert anything
    this.validateObservations(side);
    // filter out empty observations
    this._observationsDidChange(side);

    // then pass to the callback
    this.handleOnChanges(option, side, index);
  }

  // validate observations based on certain scenarios
  validateObservations(side: IGlSideBilateral) {
    // CASE 1: first value is non multi select
    if (this._sideHasNonMultipleSelectValue(side)) {
      const sideObservations: IGlOption[] =
        get(this.field, `${this.key}.${side}`) ?? [];

      // if more than one, undo autofill for remainder
      if (sideObservations.length > 1) {
        // get everything except first option and reverse
        this._undoObservations(sideObservations.slice(1), side);
      }

      // set to just index 0
      set(this.field, `${this.key}.${side}`, [
        cloneDeep(sideObservations?.[0]),
      ]);
    }
  }

  validateOptionIds(side: IGlSideBilateral) {
    const options: IGlOption[] = get(this.field, `${this.key}.${side}`) ?? [];
    for (const option of options) {
      if (!isNil(option) && !option?.id) {
        option.id = this.generateOptionId();
      }
    }
  }

  handleOnCopy(side: IGlSideBilateral, index: number) {
    const toSide: IGlSideBilateral = side === "right" ? "left" : "right";

    // check if other side's first value has a non-duplicate field
    if (this._sideHasNonMultipleSelectValue(toSide) && index > 0) {
      return this.toastr.error(
        this.observationMessages.error.selection.multi_select_not_allowed[
          toSide
        ]
      );
    }

    // handle for specific index
    this.selectDidChange(side, index);
  }

  // copy from one side to other since we doing individual
  copyObservationRowSide(index: number, toSide: IGlSideBilateral) {
    const fromSide: IGlSideBilateral = toSide === "right" ? "left" : "right";
    const fromValue: IGlOption = this.field?.[this.key][fromSide][index];
    const previousObservations: GlBilateral<IGlOption[]> = cloneDeep(
      this.field[this.key]
    );

    // create new value
    const toValue: IGlOption = cloneDeep(fromValue);
    // set id only if it is not null
    if (!isNil(toValue)) {
      toValue.id = this.generateOptionId();
    }

    // * HANDLE EDGE CASES
    // check if other side's first value has a non-duplicate field
    if (this._sideHasNonMultipleSelectValue(toSide)) {
      switch (index) {
        // for index 0 copying is freely allowed so continue
        case 0:
          break;
        default:
          // otherwise prevent issues
          return this.toastr.error(
            this.observationMessages.error.selection.multi_select_not_allowed[
              toSide
            ]
          );
      }
      // if we are copying a non-multiple-field value over, we need to set
      // the other side to be the exact same
    } else if (this._sideHasNonMultipleSelectValue(fromSide)) {
      // remove all autofills on given side
      this._undoObservations(previousObservations[toSide], toSide);

      // set value
      set(this.field, `${this.key}.${toSide}`, [toValue]);

      // change logging
      this.handleChangeLogging(toSide, [toValue], previousObservations, "copy");
      return;
    }

    // value
    set(this.field, `${this.key}.${toSide}.${index}`, toValue);

    // feed to changes service
    const changeObj: IGlChangesRecordData = {
      currentValue: toValue,
      previousValue: get(
        previousObservations,
        `${this.key}.${toSide}.${index}`
      ),
      side: toSide,
      sourceKey: this.key,
      sourcePath: this.AutofillService.getAutofillPath(this.key),
      recordData: this.field,
      type: "copy",
      id: toValue?.id,
      index,

      originKey: this.key,
      originPath: this.AutofillService.getAutofillPath(this.key),
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };
    this.ChangesService.publish(changeObj);

    // set dirty as a value has been changed;
    this.bilateralSelectMultipleForm.$setDirty();
  }

  // ROW HANDLING
  addObservation(
    observationArray: IGlOption[],
    side: IGlSideBilateral,
    index: number
  ) {
    // override by making any non 0 index values just not examined
    const newIndex = index + 1;
    const defaultOption =
      newIndex === 0
        ? (this.appendix.getDefaultKey(this.key) as IGlOption)
        : // : { name: "Not Examined", key: "not-examined" };
          (side === "left" ? this.defaultLeft : this.defaultRight) ?? null;

    // option adding
    observationArray.push(cloneDeep(defaultOption));

    // mock dirty
    this.bilateralSelectMultipleForm.$setDirty();
  }

  // delete observation
  deleteObservation(side: IGlSideBilateral, index: number) {
    // undo observation
    const observation: IGlOption = cloneDeep(
      get(this.field?.[this.key][side], index)
    );

    this._undoObservationByIndex(observation, side, index);

    // splice
    this.field[this.key][side].splice(index, 1);

    // mock dirty
    this.bilateralSelectMultipleForm.$setDirty();

    // double check if both sides are empty in case
    if (
      isUndefined(this.field?.[this.key]?.left?.[index]) &&
      isUndefined(this.field?.[this.key]?.right?.[index])
    ) {
      const otherSide: IGlSideBilateral = side === "left" ? "right" : "left";
      this.field[this.key][otherSide].splice(index, 1);
    }
  }

  // is option a multi-select value?
  isOptionMultiSelect(option: IGlOption) {
    return this.appendix.isOptionMultiSelect(this.key, option?.key);
  }

  // for display mode, should it be displayed?
  shouldDisplayOption(option: IGlOption) {
    // as long as it has a name and key isnt part of the hidden ones
    return this.appendix.shouldDisplayOption(option);
  }

  // CLEAR BY ROW INDEX
  /**
    @ignore
    // ONLY USE IF WE INTRODUCE CLEAR FOR BILATERAL SELECT AGAIN
  */
  clearSelections(field: GlBilateral<IGlOption[]>, index: number) {
    if (index >= 0) {
      // otherwise for other ones should always be a remove
      // if it exists
      if (field?.right?.[index]) {
        this.deleteObservation("right", index);
      }
      if (field?.left?.[index]) {
        this.deleteObservation("left", index);
      }

      // edge case if deleting last row remaining
      if (isEmpty(field?.right?.[index]) && index === 0) {
        set(field, `${this.key}.right.${index}`, [undefined]);
      }
      if (isEmpty(field?.left?.[index]) && index === 0) {
        set(field, `${this.key}.left.${index}`, [undefined]);
      }
    }
  }

  // OPTIONS
  generateOptionId(option?: IGlOption) {
    return option?.id ?? this.AutofillHelperService.generateAutofillId();
  }

  getOptionId(option: IGlOption, altId: string) {
    return option?.id ?? altId;
  }

  setId(option: IGlOption, id: string) {
    if (isNil(option) || !isNil(option?.id)) {
      return;
    }
    // instantiated id always takes precedence
    option.id = id;
  }

  // handle on changes callback if supplied
  handleOnChanges(option: IGlOption, side: IGlSideBilateral, index?: number) {
    if (isFunction(this.onChange)) {
      this.onChange({ option, side, index });
    }
  }

  // this assumes theyre in order
  private _undoObservations(observations: IGlOption[], side: IGlSideBilateral) {
    // take observations and sort them by relative index, ignore the ones
    // that arent there
    const sorted: IGlOption[] = sortBy(observations, (o) =>
      this.field?.[side]?.findIndex((_o) => isEqual(o, _o))
    );

    for (const obseravation of sorted?.reverse() ?? []) {
      const index: number = this.field?.[side]?.findIndex((o) =>
        isEqual(obseravation, o)
      );

      if (index !== -1) {
        this._undoObservationByIndex(obseravation, side, index);
      }
    }
  }

  // undo observation by index
  private _undoObservationByIndex(
    observation: IGlOption,
    side: IGlSideBilateral,
    index: number
  ) {
    const changeObj: IGlChangesRecordDataOrigin = {
      currentValue: observation,
      side,
      sourceKey: this.key,
      sourcePath: this.AutofillService.getAutofillPath(this.key),
      recordData: this.field,
      type: "autofill_undo",
      origin: "both",
      index,
      id: observation?.id,

      originKey: this.key,
      originPath: this.AutofillService.getAutofillPath(this.key),
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };

    this.ChangesService.publish(changeObj);
  }

  // cleanup any empty observations
  private _observationsDidChange(side: IGlSideBilateral) {
    // left side
    if (!isEmpty(this.field[this.key].left) && side === "left") {
      // gl-model has a tendency to set to an object so this sets this back to
      // nominal
      if (!isArray(this.field[this.key].left)) {
        this.field[this.key].left = [];
      }

      // console.log("left", this.key, this.field[this.key].left);
      // only if not empty or undefined  or first index
      this.field[this.key].left = this.field[this.key].left?.filter(
        // this case allows no option selected
        (o, i) => !isUndefined(o) || i === 0
        // (o, i) => (!isUndefined(o) && !isEmpty(o)) || i === 0
        // (o) => !isNil(o)
      );
      // console.log("left", this.key, this.field[this.key].left);
    }

    // right side
    if (!isEmpty(this.field[this.key].right) && side === "right") {
      // gl-model has a tendency to set to an object so this sets this back to
      // nominal
      if (!isArray(this.field[this.key].right)) {
        this.field[this.key].right = [];
      }
      // console.log("right", this.key, this.field[this.key].left);

      // only if not empty or undefined  or first index
      this.field[this.key].right = this.field[this.key].right?.filter(
        (o, i) => !isUndefined(o) || i === 0
        // (o, i) => (!isUndefined(o) && !isEmpty(o)) || i === 0
        // (o) => !isNil(o)
      );
      // console.log("right", this.key, this.field[this.key].left);
    }
  }

  // helper to identify if observation side has a non-multi-select value
  // where its limited to only one row
  private _sideHasNonMultipleSelectValue(sideToCheck: IGlSideBilateral) {
    // its mostly always the first value
    const sideValue: IGlOption = get(
      this.field,
      `${this.key}.${sideToCheck}.0`
    );
    // we return the inverse as the function checks if its multi-select
    return !this.isOptionMultiSelect(sideValue);
  }
}

export class BilateralSelectMultiple implements angular.IComponentOptions {
  static selector = "bilateralSelectMultiple";
  static template = require("./bilateral-select-multiple.html");
  static controller = BilateralSelectMultipleController;
  static bindings = {
    key: "@",
    title: "@",
    path: "@",
    mode: "@",

    other: "<?",
    field: "<",
    enableRight: "<",
    enableLeft: "<",

    // toggle state between custom options or regular
    useCustomOptions: "<?",
    customOptions: "<?",

    copy: "<?",
    defaultMode: "@?",
    generalDefaultOption: "<?",
    defaultLeft: "<?",
    defaultRight: "<?",

    otherKey: "@?",
    legacyKey: "@?",
    optionKey: "@",

    isEditable: "<?",
    glRequired: "<?",
    glChange: "&",

    // optional on change function
    onChange: "&?",
  };
}
