import { IComponentController, IComponentOptions, isFunction } from "angular";
import { Appendix } from "app/core/services/appendix";
import { DrugsDbService } from "app/core/services/drugs-db/drugs-db.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { filter, isNil, map, split } from "lodash";
import { PatientProcedureDrug } from "models/patient-procedure";
import { GlPrescriptionDrugData } from "models/prescription.model";
import {
  DrugDbRecordV2ExtensionDecorator,
  UserFavouriteDrugGroup,
} from "models/user-favourite-drugs";
import "./drug-search.scss";

export class DrugSearchController implements IComponentController {
  favouriteDrugs: PatientProcedureDrug[];
  favouriteDrugGroups: UserFavouriteDrugGroup[];

  onSelectDrug: (arg: { drugData: GlPrescriptionDrugData }) => void;

  drugSearchOptions = {
    debounce: {
      default: 200,
      blur: 100,
    },
  };

  constructor(
    public appendix: Appendix,
    private DrugsDbService: DrugsDbService,
    private PrescriptionsService: PrescriptionsService
  ) {
    "ngInject";
  }

  /** bootstrap code specifies typeahead-on-select to have
   *  these attributes and they are defined as type any in
   *  the official code, the only thing we know is
   *  $model will be a DB record
   **/
  selectDrug(
    $item: any,
    $model: GlPrescriptionDrugData,
    // eslint-disable-next-line
    $label: any,
    // eslint-disable-next-line
    $event: any
  ) {
    if (isFunction(this.onSelectDrug)) {
      this.onSelectDrug({ drugData: $model });
    }
  }

  // prescriptions
  searchDrugs(query: string) {
    if (query) {
      return this.DrugsDbService.searchDrugsDb(query)
        .then((response: DrugDbRecordV2ExtensionDecorator[]) => {
          // given results from response and favourites are guaranteed to be small
          // it wont hurt perforamnce to map <20-30 drugs into a readable format for easy use
          const favouritesFiltered: GlPrescriptionDrugData[] =
            this.searchFavouritesByQuery(query);
          const responseConverted: GlPrescriptionDrugData[] = map(
            response,
            (d) => {
              return this.PrescriptionsService.convertPbsApiModelToGlDrug(d);
            }
          );

          // map queries into response for typeahead
          return filter(
            map(favouritesFiltered.concat(responseConverted), (r) => {
              return {
                ...r,
                queries: split(query, " "), // for typeahead
              };
            }),
            (d) => !isNil(d?.brand_name)
          );
        })
        .catch(() => {
          return [];
        });
    }
  }

  // query favourite based on sql like matching
  // we cant do this on the backedn as the data is ecoded
  searchFavouritesByQuery(q: string): GlPrescriptionDrugData[] {
    if (!q) {
      return [];
    }

    const queryRegex: RegExp = new RegExp(q, "gi");
    const filtered: PatientProcedureDrug[] = this?.favouriteDrugs.filter(
      (d) => {
        const mpptTest: boolean = queryRegex.test(d.data.mp_pt);
        const brandNameTest: boolean = queryRegex.test(d.data.brand_name);
        const tpuuOrMppPtTest: boolean = queryRegex.test(d.data.tpuu_or_mpp_pt);

        return mpptTest || brandNameTest || tpuuOrMppPtTest;
      }
    );

    // return filtered
    return map(filtered, (d) => d?.data);
  }
}

export class DrugSearchComponent implements IComponentOptions {
  static selector = "drugSearch";
  static template = require("./drug-search.html");
  static controller = DrugSearchController;
  static bindings = {
    onSelectDrug: "&",
    drugSearchOptions: "<?",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
  };
}
