// singleton
// handles conenction with the browser extension and exposes

import { IPromise } from "angular";
import { set } from "lodash";
import { Letter } from "models/letter.model";
import { Patient } from "models/user.model";

// response
export interface GnetOculoLetterResponse {
  patientName: string,
  letterBase64: string | Blob;
}

// takes patient details and a selected Letter, returns name and letter as base64 string
export type GnetOculoLetterCallback = (
  { patient, selectedLetter, }: { patient: Patient, selectedLetter: Letter; }
) =>
  // returns promise of patientName and letter data as a base64 string
  IPromise<GnetOculoLetterResponse>;

// types of callbacks we support
export type PlinyBrowserExtensionCallback =
  // add more if needed
  GnetOculoLetterCallback;


// method name type, we also use it as the name of the method to 
// call for consistency 
export type GnetPlinyExtensionMethodName =
  "getSelectedGnetLetterData";

// specific name
export const GNET_OCULO_LETTER_API_METHOD_NAME: GnetPlinyExtensionMethodName = "getSelectedGnetLetterData";


// a global singleton
// handles connection with the browser extension and exposes
// global interfaces with extendability in mind
export class PlinyBrowserExtensionService {
  static injectionName = "PlinyBrowserExtensionService";

  // this will be called like this.$window.${apiPrefix}.${methodName}
  API_PROPERTY_KEY: string = 'api';

  // map for callbacks that can be registered in the future
  private callbackMap: Map<string, (...arg: any) => any> =
    new Map<string, (...arg: any) => any>();

  constructor(
    private $window: angular.IWindowService,
  ) {
    "ngInject";

    // declare the main interface
    set(this.$window, this.API_PROPERTY_KEY, {});
  }

  // on exit should always clear
  clearCallbacks() {
    this.callbackMap = new Map<string, (...arg: any) => any>();
  }

  // this can be used to update the reference to the global function 
  addCallbackToGlobalInterface(
    type: GnetPlinyExtensionMethodName,
    // determine the type of callback functions
    callback: PlinyBrowserExtensionCallback
  ) {
    switch (type) {
      case "getSelectedGnetLetterData":
        this.handleAddGnetOculoLetterInterface(callback);
        break;
      default:
        break;
    }
  }

  removeCallbackFromGlobalInterface(type: GnetPlinyExtensionMethodName) {
    switch (type) {
      case GNET_OCULO_LETTER_API_METHOD_NAME:
        this.removeGnetOculoLetterInterface();
        break;
      default:
        null;
    }
  }

  // set callback function (generic)
  setCallbackFunction(method: GnetPlinyExtensionMethodName, callback: PlinyBrowserExtensionCallback) {
    this.callbackMap.set(
      method,
      callback
    );
  }

  removeCallbackFunction(method: GnetPlinyExtensionMethodName) {
    this.callbackMap.delete(method);
  }

  // more specific ones
  private handleAddGnetOculoLetterInterface(callback: GnetOculoLetterCallback) {
    this.callbackMap.set(
      GNET_OCULO_LETTER_API_METHOD_NAME,
      callback
    );

    // wrap it in a function
    // so that if the callback doesnt exist, nothing will be returned
    const wrappedFunction = (params: { patient: Patient, selectedLetter: Letter; }) => {
      if (this.callbackMap.has(GNET_OCULO_LETTER_API_METHOD_NAME)) {
        return this.callbackMap.get(GNET_OCULO_LETTER_API_METHOD_NAME)(params);
      }
    };

    // set callback function
    set(
      this.$window,
      `${this.API_PROPERTY_KEY}.${GNET_OCULO_LETTER_API_METHOD_NAME}`,
      wrappedFunction.bind(this)
    );
  }

  private removeGnetOculoLetterInterface() {
    this.callbackMap.delete(GNET_OCULO_LETTER_API_METHOD_NAME);
    delete window?.api?.[GNET_OCULO_LETTER_API_METHOD_NAME];
  }

}