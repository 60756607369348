// import { Letter } from "models/letter.model";
import { GlProvider } from "models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { LetterService } from "../../../../core/services/letter.service";
import "./letter-container.scss";

class LetterContainerController implements angular.IController {
  // @Inputs()
  allAddressees: any;
  letterTemplate: string;
  originalReferrer: any;
  showPdfTemplate: boolean;
  toAddressee: any;
  isLast: boolean;
  cc: GlProvider;
  user: User;
  date: Date; // we leave it as it is if no date
  usePdfStyle: boolean = false; // use optional default pdf style

  constructor(private LetterService: LetterService) {
    "ngInject";
  }

  getAddressee(addressee: GlProvider) {
    if (this.allAddressees) {
      if (this.isAddresseesProvider()) {
        return this.LetterService.getProviderNameToDisplay(addressee);
      } else {
        return this.LetterService.contactAddresseeNameFormat(addressee);
      }
    }
  }

  isAddresseesProvider() {
    if (this.allAddressees) {
      return (
        "gp" in this.allAddressees ||
        "optometrist" in this.allAddressees ||
        "referrerDetails" in this.allAddressees
      );
    } else {
      return false;
    }
  }

  printCCList() {
    const ccList = [];
    this.allAddressees?.forEach((addressee) => {
      if (
        addressee.data.id !== this.toAddressee.data.id &&
        addressee.deleted !== true
      ) {
        ccList.push(
          this.LetterService.contactAddresseeNameFormat(addressee.data)
        );
      }
    });
    return ccList.join(", ");
  }

  getOriginalReferrer() {
    const original = this.allAddressees?.filter((a) => {
      return a.referrer === true;
    });
    return original[0];
  }

  isAddresseeNotReferrer(referrer: any, cc: any) {
    if (this.allAddressees) {
      return (
        this.getAddressee(this.allAddressees[referrer]) !==
        this.getAddressee(this.allAddressees[cc])
      );
    }
  }
}

export class LetterContainerComponent implements angular.IComponentOptions {
  static selector = "glLetterContainer";
  static controller = LetterContainerController;
  static template = require("./letter-container.component.html");
  static bindings = {
    allAddressees: "<",
    cc: "<",
    isLast: "<",
    letterTemplate: "<",
    showPdfTemplate: "<",
    toAddressee: "<",
    user: "<",
    date: "<?",
    usePdfStyle: "<?",
  };
}
