/**
 * Import the Component styles
 */

import { element } from "angular";
import { Contact } from "models/contact.model";
import { GlPatientProviders } from "models/patient-record.model";
import { Letter } from "../../../models/letter.model";
import { User } from "../../../models/user.model";
import { LetterService } from "../../core/services/letter.service";
import { PatientService } from "../../core/services/patient.service";
import { PdfPrintService } from "../../core/services/pdf-print.service";
import "./print-letter.scss";
import moment = require("moment");

class PrintLetterPageController
  implements angular.IController, angular.IOnInit
{
  patientId = this.$stateParams.patientId as number;
  patient: User;
  letterIds = this.$stateParams.letterId as string;
  shouldPrintOnLoad = (this.$state.params.print as boolean) || false;
  shouldPdfOnLoad = (this.$state.params.pdf as boolean) || false;
  shouldEmailLoad = (this.$state.params.email as boolean) || false;
  // default is emtpy string to allow printing of the letter
  // by generating [""] after splitting by comma delimiter
  addresseesPrint: string = this.$state.params.addressees || "";
  keys: string[] = this.addresseesPrint?.split(",");
  user: User;
  providers: GlPatientProviders;
  letters: Letter[];
  pdfGenerationInProgress = false;
  printInProgress = false;
  showPdfTemplate = false;
  showEmailContainer = false;
  emailFrom: string;
  emailTo: string;
  subject: string;
  pdfAsB64: string;
  emailTemplates = [
    {
      id: 1,
      title: "MCES",
      clinic_id: 50,
      raw_template: require("./components/email-templates/mces.html"),
    },
    {
      id: 2,
      title: "Sunbury",
      clinic_id: 2,
      raw_template: require("./components/email-templates/sunbury.html"),
    },
  ];

  constructor(
    public $state: angular.ui.IStateService,
    private $stateParams: angular.ui.IStateParamsService,
    private LetterService: LetterService,
    private $window: angular.IWindowService,
    private $timeout: angular.ITimeoutService,
    private PdfPrintService: PdfPrintService,
    private PatientService: PatientService,
    private $q: angular.IQService
  ) {
    "ngInject";
  }

  $onInit() {
    const getPatientPromise = this.PatientService.get(
      this.patientId
    ).$promise.then((patient) => (this.patient = patient));
    const getLettersPromise = this.$q
      .all(
        this.letterIds
          .split("-")
          .map((id) => this.LetterService.get(this.patientId, +id))
      )
      .then((letters) => {
        this.letters = letters;
      });
    this.$q
      .all([getPatientPromise, getLettersPromise])
      .then(() => {
        return this.$timeout();
      })
      .then(() => {
        if (this.shouldPrintOnLoad) {
          this.printWithTemplate();
        } else if (this.shouldPdfOnLoad) {
          this.pdf();
        } else if (this.shouldEmailLoad) {
          this.showEmailContainer = true;
          this.handleGeneratePdfBlob();
        }
      });
  }

  print() {
    this.$window.print();
  }

  printWithTemplate() {
    this.printInProgress = true;
    this.showEmailContainer = false;
    this.pdfGenerationInProgress = true;
    this.showPdfTemplate = true;
    this.$timeout(500)
      .then(() => {
        element(document.querySelector(".print-footer .footer-left")).append(
          ""
        );
        return this.PdfPrintService.print({
          headerData: element(
            document.querySelector("#print-header-wrap")
          ).html(),
          footerData: element(
            document.querySelector("#print-footer-wrap")
          ).html(),
          pageData: element(document.querySelector("html")).html(),
          background: true,
        });
      })
      .then(() => {
        this.$window.print();
      })
      .catch(() => {
        // console.log(error);
      })
      .finally(() => {
        this.pdfGenerationInProgress = false;
        this.showPdfTemplate = false;
        this.printInProgress = false;
      });
  }

  getLetterCC(letter: Letter, key: string) {
    return this.LetterService.getLetterCC(letter, key);
  }

  pdf() {
    this.showEmailContainer = false;
    this.pdfGenerationInProgress = true;
    this.showPdfTemplate = true;
    this.$timeout(500)
      .then(() => {
        element(document.querySelector(".print-footer .footer-left")).append(
          ""
        );
        return this.PdfPrintService.print({
          headerData: element(
            document.querySelector("#print-header-wrap")
          ).html(),
          footerData: element(
            document.querySelector("#print-footer-wrap")
          ).html(),
          pageData: element(document.querySelector("html")).html(),
          background: true,
        });
      })
      .then((response) => {
        return (this.$window.location.href = URL.createObjectURL(response));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.pdfGenerationInProgress = false;
        this.showPdfTemplate = false;
      });
  }

  handleGeneratePdfBlob() {
    this.showEmailContainer = true;
    this.getReferrer();
    this.pdfGenerationInProgress = true;
    this.showPdfTemplate = true;

    this.$timeout(500)
      .then(() => {
        element(document.querySelector(".print-footer .footer-left")).append(
          ""
        );
        return this.PdfPrintService.print({
          headerData: element(
            document.querySelector("#print-header-wrap")
          ).html(),
          footerData: element(
            document.querySelector("#print-footer-wrap")
          ).html(),
          pageData: element(document.querySelector("html")).html(),
          background: true,
        });
      })
      .then((response) => {
        this.blobTob64(response).then((result: string) => {
          this.pdfAsB64 = result;
        });
      })
      .finally(() => {
        this.pdfGenerationInProgress = false;
        this.showPdfTemplate = false;
      });
  }

  generatePdfBlob() {
    this.$timeout(500)
      .then(() => {
        element(document.querySelector(".print-footer .footer-left")).append(
          ""
        );
        return this.PdfPrintService.print({
          headerData: element(
            document.querySelector("#print-header-wrap")
          ).html(),
          footerData: element(
            document.querySelector("#print-footer-wrap")
          ).html(),
          pageData: element(document.querySelector("html")).html(),
          background: true,
        });
      })
      .then((response) => {
        this.blobTob64(response).then((result: string) => {
          this.pdfAsB64 = result;
        });
      })
      .finally(() => {
        this.pdfGenerationInProgress = false;
        this.showPdfTemplate = false;
      });
  }

  blobTob64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  getReferrer() {
    if (this.letters) {
      const index = this.letters[0]?.addressees.findIndex(
        (la) => la.referrer === true
      );
      this.emailTo = this.letters[0]?.addressees?.[index]?.data?.email;
      this.subject = `Patient: ${this.patient?.name}`;
      const addressee = this.formatContactForAddressee(
        this.letters[0]?.addressees[index]?.data
      );
      return addressee?.substring(0, addressee.indexOf("("));
    }
  }

  formatContactForAddressee(contact: Contact) {
    return this.LetterService.contactAddresseeNameFormat(contact);
  }

  getAddressee(letter: Letter, index: number) {
    const keysToPrint = this.getAddresseeKeysForLetter(letter);
    if (keysToPrint[index]) {
      return this.LetterService.getProviderNameToDisplay(
        letter?.addressees[keysToPrint[index]]
      );
    }
  }

  getAddresseeKeysForLetter(letter: Letter) {
    if (!this.keys && letter?.addressees) {
      return Object.keys(letter?.addressees).filter((k) => k !== "referrer");
    } else {
      return this.keys;
    }
  }

  private getFileName() {
    // const letterDate = parseServerDate(this.letters.updated_at);
    return `${this.patient.data.last_name}_${
      this.patient.data.first_name
    }_letter_${moment().format("YYYYMMDD")}.pdf`;
  }
}

export class PrintLetterPage implements angular.IComponentOptions {
  static selector = "glPrintLetter";
  static template = require("./print-letter.html");
  static controller = PrintLetterPageController;
  static bindings = {
    user: "<",
  };
}
