import { Contact } from "../../../models/contact.model";
import { API_PATH_v2 } from "./api-paths";
import { IGlApiResponse } from "../../../models/gl-api-response.model";

export interface IContactResourceClass
  extends angular.resource.IResourceClass<IContactResource> {
  getAllContacts: () => IContactResource[];
}

export interface IContactResource
  extends Contact,
  angular.resource.IResource<Contact> { }

export class ContactService {
  static injectionName = "ContactService";
  v2BasePath = `${this.API_URL}${API_PATH_v2}`;
  contactPath = `${this.v2BasePath}/contacts`;
  getAllContactsPath = `${this.v2BasePath}/contacts_basic`;

  private cache = this.$cacheFactory.get("$http");

  constructor(
    private $cacheFactory: angular.ICacheFactoryService,
    private $http: angular.IHttpService,
    private API_URL: string
  ) {
    "ngInject";
  }

  getContactDetails(contactId: number) {
    return this.$http
      .get<Contact>(`${this.v2BasePath}/contacts/${contactId}`)
      .then((response) => response.data);
  }

  getAll({ searchString }: { searchString?: string; }) {
    const params: any = {};
    if (searchString) {
      params.q = searchString;
    }
    const url = `${this.v2BasePath}/contacts`;
    return this.$http
      .get<IGlApiResponse<Contact[]>>(url, { params })
      .then((response) => response.data.data);
  }

  createContact(contact: Contact) {
    const url = `${this.v2BasePath}/contacts`;
    return this.$http
      .post<Contact>(url, contact)
      .then((response) => response.data);
  }

  getAllPatientContacts(patientId: number) {
    const url = `${this.API_URL}${API_PATH_v2}/patients/${patientId}/contacts`;
    return this.$http
      .get<IGlApiResponse<Contact[]>>(url)
      .then((response) => response.data.data);
  }

  deleteContact(contact: Contact) {
    const url = `${this.v2BasePath}/contacts/${contact.id}`;
    return this.$http.delete<Contact>(url).then((response) => response.data);
  }

  clearCache() {
    this.cache.remove(this.contactPath);
    this.cache.remove(this.getAllContactsPath);
  }
}
