import * as cleanDeep from "clean-deep";
import { get, isNil, omit } from "lodash";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../models/patient-record.model";
import { PatientRecordService } from "./patient-record/patient-record.service";

export class GlModelService {
  static injectionName = "GlModelService";
  static $inject = [PatientRecordService.injectionName];

  consolidatedRecordHistory: PatientRecordData = {};
  records: PatientRecord[] = [];

  glModelDefaultsIsEnabled = true;

  constructor(private PatientRecordService: PatientRecordService) {
    //
  }

  disableGlModelDefaults() {
    this.glModelDefaultsIsEnabled = false;
  }

  enableGlModelDefaults() {
    this.glModelDefaultsIsEnabled = true;
  }

  setRecordHistory(records: PatientRecord[]) {
    this.records = records;
    // first sort all the records by id so the newest is last
    this.consolidatedRecordHistory =
      this.getConsolidatedRecordFromHistory(records);
  }

  /**
   * This function walks through all the records in the history and finds the
   * most recent record that has the path set. If it doesn't find anything it
   * returns undefined. It relies on the records array being sorted from oldest
   * record to newest
   */
  getRecordConsolidatedValue(path: string) {
    let consolidatedValue = undefined;
    for (const record of this.records) {
      const val = get(record, `data.${path}`);
      let valFromVirtualReview = undefined;
      // When walking the record history, we need to take into account a record
      // that may have a virtual review. If there is a virtual review, then try
      // to get the path from the virtualReview.data.  If this value exists,
      // return this otherwise return the value from the original record
      if (record.virtual_review?.data) {
        valFromVirtualReview = get(record.virtual_review.data, path);
      }
      // we check for falsy rather than 0
      if (!isNil(val) || !isNil(valFromVirtualReview)) {
        consolidatedValue = valFromVirtualReview ?? val;
      }
    }
    return consolidatedValue;
  }

  getConsolidatedRecordFromHistory(records: PatientRecord[]) {
    // first sort all the records by id so the newest is last
    const consolidatedHistory = [...records]
      .sort((r1, r2) => r1.id - r2.id)
      .reduce<PatientRecordData>((ret, record) => {
        let mergedRecordData = ret;
        if (record.type !== "tech_record" && record.type !== "history") {
          mergedRecordData = Object.assign(
            mergedRecordData,
            cleanDeep(record.data)
          );
          if (record.virtual_review?.data) {
            this.mergeManagementData(
              mergedRecordData,
              record.virtual_review.data
            );
            const virtualReviewData = omit(
              record.virtual_review.data,
              "management"
            );
            mergedRecordData = Object.assign(
              mergedRecordData,
              cleanDeep(virtualReviewData)
            );
          }
        } else if (record.type === "history") {
          this.mergeManagementData(mergedRecordData, record.data);
          const historyData = omit(record.data, "management");
          mergedRecordData = Object.assign(
            mergedRecordData,
            cleanDeep(historyData)
          );
        } else {
          // for tech_records, they only include a partial management section, so
          // when working out the consolidate record, just skip this entire
          // section
          const techRecordExcludingManagement = omit(
            cleanDeep(record.data),
            "management"
          );
          mergedRecordData = Object.assign(
            mergedRecordData,
            techRecordExcludingManagement
          );
        }

        return mergedRecordData;
      }, {});

    // calculate what the maximum IOP for this record history is and set it in
    // the consolidated record for reference.
    consolidatedHistory.max_iop = {
      left: this.PatientRecordService.getMaxIopForSide("left", records),
      right: this.PatientRecordService.getMaxIopForSide("right", records),
    };
    return consolidatedHistory;
  }

  clearRecordHistory() {
    this.consolidatedRecordHistory = {};
  }

  mergeManagementData(
    targetRecord: PatientRecordData,
    sourceRecord: PatientRecordData
  ) {
    const { management } = sourceRecord ?? {};
    if (management) {
      const mergedManagement = { ...targetRecord.management, ...management };
      targetRecord.management = mergedManagement;
    }
    return targetRecord;
  }

  get(path: string) {
    return get(this.consolidatedRecordHistory, path);
  }

  getFromRecordOrHistory(
    path: string,
    record: PatientRecordData,
    history: PatientRecordData
  ) {
    const valueFromHistory = get(history, path);
    return get(record, path, valueFromHistory);
  }
}
