import { GlModelDefaultMode } from "../../../../../app/core/directive/gl-model";
import { GlFormController } from "../../gl-form-controller";

/**
 * For text input only, this is different to textarea which is meant for paragraphs
 */
class BilateralTextInputController
  extends GlFormController
  implements angular.IController
{
  ngRequired = true;
  defaultMode: GlModelDefaultMode = GlModelDefaultMode.default;

  constructor() {
    "ngInject";
    super();
  }
}

export class BilateralTextInput implements angular.IComponentOptions {
  static selector = "bilateralTextInput";
  static template = require("./bilateral-text-input.html");
  static controller = BilateralTextInputController;
  static bindings = {
    field: "<",
    key: "@",
    title: "@",
    enableLeft: "<",
    enableRight: "<",
    copy: "<",
    leftIcon: "@",
    rightIcon: "@",
    mode: "@?",
    ngRequired: "<?",
    isEditable: "<?",
    defaultMode: "@?",
  };
}
