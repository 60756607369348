/* eslint-disable @typescript-eslint/no-unused-vars */
import { IOnChangesObject, isFunction } from "angular";
import { PatientProcedureFormService } from "app/core/services/PatientProcedureFormService/patient-procedure-form.service";
import { ConsentFormHelperService } from "app/core/services/consent-form-helper/consent-form-helper.service";
import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { diff } from "json-diff";
import { cloneDeep, defaultsDeep, forEach, isArray, isEmpty, isEqual, isNil, isObject, pick, set } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { IGlSide } from "models/gl-side.model";
import { PatientProcedureExternal, PatientProcedureExternalSelectionWrapper, PatientProcedureSelectable } from "models/patient-procedure";
import {
  GlCataractForm,
  GlPatientRecordPractitioner,
  PatientCataractConsentRecord,
  PatientConsentFormRecord,
  PatientRecord,
  PatientRecordData,
} from "models/patient-record.model";
import {
  GlStaff,
  IGlUserCataractFormSettings,
  Patient
} from "models/user.model";
import { GlModelDefaultMode } from "../../../../../app/core/directive/gl-model";
import {
  Appendix,
  CATARACT_FORM_KEYS,
  IGlOption,
} from "../../../../../app/core/services/appendix";
import {
  // PATIENT_RECORD_EVENT_SAVE,
  PATIENT_RECORD_SET_PRISTINE,
} from "../../../../../app/pages/main.record/record";
import { GlFormController } from "../../gl-form-controller";

import "./cataracts-section.scss";

export class CataractsSectionController
  extends GlFormController
  implements angular.IController, angular.IOnInit, angular.IOnChanges {
  user: GlStaff;
  patient: Patient;
  mode: IGlFormMode;
  // form
  cataractConsentModel: angular.IFormController;
  record: PatientRecord;

  // the current consent form
  latestCataractConsentRecord: PatientCataractConsentRecord;

  // the full list (always updated)
  cataractConsentForms: PatientCataractConsentRecord[];

  // procedure the consent form is based on
  linkedConsentRecordProcedure: PatientProcedureExternal;
  // external procedures
  externalProcedures: PatientProcedureExternal[];
  // filtered by cataract
  cataractExternalProcedures: PatientProcedureExternalSelectionWrapper[];
  defaultOption: IGlOption = null;
  userCataractConsentFormSettings: IGlUserCataractFormSettings;

  // init
  debug: boolean = this.$stateParams.debug;
  isEditable: boolean;
  init: boolean = false;
  // L/R enable?
  enableLeft: boolean = false;
  enableRight: boolean = false;
  // toggle between user and all
  // can also be associated with state from a parent
  useUserOptions: boolean = false;
  generateCataractConsentInProgress: boolean = false;
  deleteCataractConsentInProgress: boolean = false;
  formIdToBeDeleted: number;

  // progress state (use for both signing/unsigning)
  userSignInProgress: boolean = false;
  patientSignInProgress: boolean = false;

  // re-open
  reopenAndEditInProgress: boolean = false;
  // very specific error handling flags
  errorConsentFormAlreadySigned: boolean = false;

  // is the current record changes the same as the latest consent form?
  consentFormDataMatches: boolean = false;

  // toastr message appendix, itll be under [category].consent_form
  // e.g. error.consent_form
  consentFormMessages = this.ToastrAppendix.getRecordsMessages();

  // function to pass that handles the parent tab collapse behaviour
  toggleTab: (arg: { state: boolean; }) => void;


  constructor(
    public appendix: Appendix,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private PatientProcedureFormService: PatientProcedureFormService,
    private ConsentFormHelperService: ConsentFormHelperService,
    private ToastrAppendix: ToastrAppendix,
    private toastr: angular.toastr.IToastrService,
    private $scope: angular.IScope,
    private $stateParams: angular.ui.IStateParamsService,
    private $q: angular.IQService,
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    this._updateUserCataractConsentFormSettings();
  }

  $onChanges(changes: IOnChangesObject): void {
    // if there are any changes to mode or the user, update
    if (changes?.user && this.user) {
      this._updateUserCataractConsentFormSettings();
    }

    // fetches a filter of existing cataract procedures
    if (changes?.externalProcedures && this.externalProcedures) {
      // try and get the previously selected procedure
      const prevSelectedId: number = this.getCurrentlySelectedProcedure()?.id;

      // refresh
      this.cataractExternalProcedures = this.externalProcedures.filter((p) =>
        p.type === "external" && p.data.nameAppendix.name === 'Cataract'
      );

      // try and toggle again, if not just choose the first one in the list 
      const indexPrevSelected: number = this.cataractExternalProcedures.findIndex(
        (p) => p.id === prevSelectedId
      );

      // we either use the index found, or as a fallback
      // the first instance
      this.onSelectProcedure(this.cataractExternalProcedures[
        indexPrevSelected !== -1
          ? indexPrevSelected
          : 0
      ]);

    }

    // if changed to edit mode set defaults
    if (changes?.mode) {
      // this fixes a state bug where the 
      // consent form isnt being updated
      this.updateLinkedProcedureForConsentForm();

      // in edit mode, programatically focuses the select elements
      // to trigger autofill
      if (this.isEditMode()) {
        this._focusBilateralSelectRows();
      }
    }

    // if there are cataract consent forms, change it to just return the first one
    if (changes?.record && this.record?.consent_forms?.length) {
      // get the most recent one as it returns with recent on top
      this.updateConsentRecordReferenceByParent(this.record);
    }

    // run an update 
    if (
      (changes?.externalProcedures || changes?.record) &&
      this.externalProcedures &&
      this.record
    ) {
      if (!this.init) {
        this.initWithLinkedProcedure();
        this.init = true;
      }
      this.updateLinkedProcedureForConsentForm();
    }

  }

  // get mode
  getMode(): IGlFormMode {
    // previous behaviour had this set to display mode if signed
    // now the consent section acts as a form template so
    // just add any other behaviour here if you need to

    // otherwise follow inherited or default to edit
    return this.mode;
  }

  // CONSENT FORM DISPLAY RELATED
  // should show row?
  shouldDisplayField(fieldKey: string) {
    // if we are not using user options then always display
    if (!this.useUserOptions) {
      return true;
    }

    // otherwise by default always check
    return this?.userCataractConsentFormSettings?.fields_to_display?.includes(
      fieldKey
    );
  }

  // side here is depenednet on whether it should be dispalyed also
  // and with respect to the currently selected procedure 
  shouldEnableSide(fieldKey: string, side: IGlSide) {
    // also check the supposed linked consent procedure
    // fallback is both
    const selectedProcedureSide: IGlSide =
      this.getCurrentlySelectedProcedure()?.data.eye ?? "both";

    // then check
    if (side === "left") {
      return this.enableLeft && this.shouldDisplayField(fieldKey) &&
        ['left', 'both'].includes(selectedProcedureSide);
    } else if (side === "right") {
      return this.enableRight && this.shouldDisplayField(fieldKey) &&
        ['right', 'both'].includes(selectedProcedureSide);
    } else {
      // both
      return (
        (this.enableLeft || this.enableRight) &&
        this.shouldDisplayField(fieldKey)
      );
    }
  }

  // default mode is based on the user's settings
  // and not card display
  getDefaultModeForField(fieldKey: string) {
    return this.userCataractConsentFormSettings?.fields_to_display?.includes(
      fieldKey
    )
      ? GlModelDefaultMode.default
      : GlModelDefaultMode.none;
  }

  // USER DEFAULT OPTIONS RELATED
  // on toggle, handles setting defaults on empty fields
  // if defaults are defined
  handleOnToggleUserOptions() {
    // if we toggle to use options
    this.useUserOptions = !this.useUserOptions;
  }

  getUserDefaultOption(fieldKey: string) {
    // if we are using user default options
    if (
      this?.userCataractConsentFormSettings?.field_options &&
      this.useUserOptions
    ) {
      const defaultOption: IGlOption =
        this?.userCataractConsentFormSettings?.field_options?.[fieldKey]?.find(
          (o) => o.default
        );
      return defaultOption ?? null;
    } else {
      // otherwise default will be null
      return null;
    }
  }

  // CONSENT FORM RELATED
  hasLinkedCataractConsentForm() {
    return !isNil(this.latestCataractConsentRecord);
  }

  // SIGNING STUFF
  // is it signed?
  userSignedCataractConsentForm(form: PatientCataractConsentRecord) {
    return this.ConsentFormHelperService.userSignedConsentForm(form);
  }

  // is it singed by patient?
  patientSignedCataractConsentForm(form: PatientCataractConsentRecord) {
    return this.ConsentFormHelperService.patientSignedConsentForm(form);
  }

  // SINGATURE CHECKS
  bothSignaturesPresent(form: PatientCataractConsentRecord) {
    return (
      this.userSignedCataractConsentForm(form) &&
      this.patientSignedCataractConsentForm(form)
    );
  }

  eitherSignaturePresent(form: PatientCataractConsentRecord) {
    return (
      this.userSignedCataractConsentForm(form) ||
      this.patientSignedCataractConsentForm(form)
    );
  }

  // current record signed
  recordIsSigned() {
    return this?.record?.data_status === "SIGNED";
  }


  // create new consent form
  createCataractConsentForm({
    saveParent = true,
    linkedProcedureId
  }: {
    // do we save parent record as well?
    saveParent?: boolean,
    linkedProcedureId?: number;
  }) {
    // if there is no linked procedure, use either the currently selected one or 
    // the one established to be the saved one
    // find selected procedure either by what is selected
    // or using the currently selected one
    const linkedProcedure: PatientProcedureExternal =
      this.cataractExternalProcedures.find((p) => p.selected);
    if (!linkedProcedureId) {
      linkedProcedureId = linkedProcedure?.id;
    }

    if (isNil(linkedProcedureId)) {
      return this.toastr.error('Cataract procedure must be selected for consent form to save.');
    }

    // then we can continue
    this.generateCataractConsentInProgress = true;

    // we should always use the most senior practitioner for the consent form
    // when creating
    const consentFormPractitioner: GlPatientRecordPractitioner =
      this.PatientRecordService.getMostSeniorPractitioner(
        this.record?.data?.practitioner,
        this.user
      );

    // construct data to form a new consent record
    const consentFormRecordData: PatientRecordData = {
      management: cloneDeep(
        this.record.data.management
      ),
      // the full procedure
      linked_procedure: linkedProcedure,
      practitioner: consentFormPractitioner,
    };

    // create a new consent form with the data provided
    return this.PatientRecordService.createLinkedRecordWithData({
      recordData: consentFormRecordData,
      linked_record_type: 'consent_form_cataract',
      patient_id: this.patient.id,
      parent_record_id: this.record.id,
    })
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        // consent form successfully updated
        // toggle any error flags for the consent form to false
        this.errorConsentFormAlreadySigned = false;

        // set latest consent form
        this.latestCataractConsentRecord = cataractConsentForm;
        this.isDifferentToLatestConsentForm();

        // update linked procedure
        this.updateLinkedProcedureForConsentForm();
        this.toastr.success("Successfully saved cataract consent form!");

        // set pristine only if existing
        if (this?.cataractConsentModel) {
          this.cataractConsentModel.$setPristine();
        }
      })
      .then(() => {
        // perform the same on the original record as well
        if (saveParent) {
          return this.PatientRecordService.updateAndSetPractitioner(
            this.record,
            this.user
          )
            .then((parentRecord: PatientRecord) => {
              this.toastr.success("Successfully saved record!");

              // update consent forms 
              this.record.consent_forms = this.cataractConsentForms = parentRecord.consent_forms;

              // set record to prsitine cuz theres no changes to the form
              this.$scope.$emit(PATIENT_RECORD_SET_PRISTINE, this.record.id);
            })
            .catch((err) => {
              console.error(err);
              this.toastr.error("Error saving record. Please try again.");
            });
        }
      })
      .catch((err) => {
        console.error(err);
        // if its a consent form error, show the error
        this._handleConsentFormSaveErrors(err);
      })
      .finally(() => {
        this.generateCataractConsentInProgress = false;
      });
  }

  // delete
  deleteCataractConsentForm(form: PatientCataractConsentRecord) {
    // must not be signed at all
    if (!form) {
      return this.toastr.error(this.consentFormMessages.error.consent_form.delete.no_form);
    }

    // must ensure no signatures exist before we can delete
    if (this.eitherSignaturePresent(form)) {
      return this.toastr.error(this.consentFormMessages.error.consent_form.delete.signed);
    }

    // else continue
    const confirm: boolean = window.confirm(
      "Are you sure you want to delete this consent form? You will have to create a new consent form if so."
    );

    const formId: number = form.id;
    // on confirm
    if (confirm) {
      this.deleteCataractConsentInProgress = true;
      this.formIdToBeDeleted = formId;

      return this.$q
        .resolve()
        .then(() => {
          // check if patient signed first then undo
          if (this.patientSignedCataractConsentForm(form)) {
            return this.ConsentFormHelperService.reOpenConsentFormPatient(form);
          }
          return form;
        })
        .then(() => {
          // then check if surgeon signed and undo
          if (this.userSignedCataractConsentForm(form)) {
            return this.ConsentFormHelperService.reOpenConsentFormUser(form);
          }
          return form;
        })
        .then((consentRecord: PatientCataractConsentRecord) => {
          // then delete consent form
          return this.PatientRecordService.delete(consentRecord);
        })
        .then(() => {
          // if successfully deleted, we need to fetch a new list of consent forms
          return this.ConsentFormHelperService.fetchConsentFormsForRecord(this.patient.id, this.record.id);
        })
        .then((consentForms: PatientConsentFormRecord[]) => {
          // then update it
          this.updateConsentRecordReferenceByConsentForms(consentForms);
        })
        .then(() => {
          this.toastr.success(
            "Successfully deleted the consent record"
          );
        })
        .catch((error) => {
          console.error("Unable to delete record", error);
          return this.toastr.error(
            "Unable to delete the conent form, please try again."
          );
        })
        .finally(() => {
          this.deleteCataractConsentInProgress = false;
          this.formIdToBeDeleted = undefined;
        });
    }
  }

  // n changes for post op section
  // depending on what EXISTING (not temporary post op procedures exist)
  // autofill
  handleAutofillWhenWithPostOp(option: IGlOption) {
    // 1. get created procedure that matches it
    // latest one will show
    const cataractProcedureInRecord: PatientProcedureExternal =
      this.getExistingCataractProcedureForRecord();

    // based on an existing cataract proceedure done in this record
    // go based on eye order or eye
    if (!isNil(cataractProcedureInRecord)) {
      let optionToChoose: IGlOption;
      // both eye check order
      switch (cataractProcedureInRecord.data.eye) {
        case "both":
          // depends on left right order
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op[
            cataractProcedureInRecord.data.order === "left_right"
              ? "left"
              : "right"
            ];
          break;
        case "left":
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op?.left;
          break;
        case "right":
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op?.right;
          break;
        default:
          optionToChoose = option;
          break;
      }

      // 2. then update based on that cardinality
      this.PatientProcedureFormService.handleAutofillWhenWithPostOp(
        this.record.data,
        optionToChoose ?? option
      );
    }
  }

  // returns currently selected procedure
  getCurrentlySelectedProcedure() {
    return this.cataractExternalProcedures?.find((p) => p?.selected);
  }

  // for returning one that matches in the current record
  getExistingCataractProcedureForRecord() {
    return this.PatientProcedureHelperService.getExternalProcedureForRecordByName(
      this.record.id,
      "Cataract",
      this.cataractExternalProcedures
    );
  }

  // do any existing cataract procedure exist (based on what is filtered)
  recordHasExistingCataractProcedures() {
    return this.cataractExternalProcedures?.length > 0;
  }

  // fetches linked procedure for a cataract record
  updateLinkedProcedureForConsentForm() {
    // try and fetch an instance
    // either from the linked consent record or a local reference
    const linkedProcedureId: number =
      this.latestCataractConsentRecord?.data?.linked_procedure?.id ?? this.latestCataractConsentRecord?.data?.linked_procedure_id;

    // all external procedures
    const allExternalProcedures: PatientProcedureExternal[] = this.PatientProcedureService.getExternalProcedures();
    // if no id (i.e. legacy)
    if (isNil(linkedProcedureId)) {
      // fetch based on older logic
      // legacy consent forms worked on the principle of 
      // using the same procedure created within the same record
      this.linkedConsentRecordProcedure = this.getExistingCataractProcedureForRecord();
    } else {
      // else use id 
      // we can afford to look through all external procedures as we have an aboslute reference
      // this also bypasses the problem of searching for an older procedure from 
      // a different record
      this.linkedConsentRecordProcedure =
        allExternalProcedures.find((p) => p.id === linkedProcedureId);
    }
  }

  // toggle state
  toggleExternalTabState(state: boolean = false) {
    if (isFunction(this.toggleTab)) {
      this.toggleTab({ state });
    }
  }

  // create workflow
  handleGenerateCataractConsentForm() {
    // if there is only one cataract procedure
    // use it
    if (this.cataractExternalProcedures.length === 1) {
      this.createCataractConsentForm({
        saveParent: true,
        linkedProcedureId: this.cataractExternalProcedures[0].id
      });
    } else {
      // find selected procedure either by what is selected
      // or using the currently selected one
      const selectedProcedure: PatientProcedureExternal =
        this.cataractExternalProcedures.find((p) => p.selected);

      // cant save without one
      if (!selectedProcedure) {
        return this.toastr.error('Consent record must be linked to a procedure to save.');
      }

      // continue otherwise
      this.createCataractConsentForm({
        saveParent: true,
        linkedProcedureId: selectedProcedure.id
      });
    }
  }

  // if theres a linked procedure associated with the consent record
  // have an exisitng linked procedure selected on init
  initWithLinkedProcedure() {
    const linkedProcedureId: number =
      this.latestCataractConsentRecord?.data?.linked_procedure?.id ??
      this.latestCataractConsentRecord?.data?.linked_procedure_id;

    // find it with reference and select it
    // it will either be an existing linked one or 
    // as a fallback, a cataract procedure linked to the record
    const foundProcedure: PatientProcedureSelectable =
      !isNil(linkedProcedureId)
        ? (this.cataractExternalProcedures ?? []).find((p) => p?.id === linkedProcedureId)
        : this.getExistingCataractProcedureForRecord();

    // if found, select 
    if (foundProcedure) {
      this.onSelectProcedure(foundProcedure);
    }
  }

  // for selecting a linked procedure if applicable
  onSelectProcedure(procedure: PatientProcedureExternalSelectionWrapper) {
    // simple toggle
    forEach(this.cataractExternalProcedures, (p) => {
      if (p.id !== procedure?.id) {
        p.selected = false;
      } else {
        p.selected = true;
      }
    });

    // then ensure that we enact a difference change
    // a new procedure means a new consent form
    this.isDifferentToLatestConsentForm();
  }

  // update current consent record procedure reference
  updateConsentRecordReferenceByParent(parentRecord: PatientRecord) {
    if (parentRecord?.consent_forms?.length) {
      this.updateConsentRecordReferenceByConsentForms(parentRecord?.consent_forms ?? []);
    }
  }

  updateConsentRecordReferenceByConsentForms(consentForms: PatientConsentFormRecord[]) {
    // update parent
    this.record.consent_forms = consentForms;

    // update full reference
    this.cataractConsentForms = (consentForms)
      .filter((cf) => this.isCataractConsentForm(cf));

    // update main reference as the first one
    // if there arent any, it will just be undefined as intended
    // meaning there isnt a form
    this.latestCataractConsentRecord = this.cataractConsentForms?.[0];

    // update
    this.isDifferentToLatestConsentForm();
  }


  // function that just checks if relative to the current consent form, if the 
  // model has had any changes
  // compares record vs latest_consent_form
  isDifferentToLatestConsentForm() {
    // nothing to compare with? ignore
    if (!this.latestCataractConsentRecord) {
      return this.consentFormDataMatches = false;
    }

    // otherwise ignore if display mode
    if (this.isDisplayMode()) {
      return this.consentFormDataMatches = true;
    }

    const cataractConsentFormFields: (keyof GlCataractForm)[] =
      this.ConsentFormHelperService.getCataractConsentFormFields();


    // otherwise check against all fields that arent empty
    const currentlySelectedExternalProcedure: PatientProcedureExternal =
      this.cataractExternalProcedures.find((p) => p.selected);

    // MAIN PARENT RECORD
    const parentRecordConsentFormFields = this._filterObj(
      pick(
        cloneDeep(this.record?.data?.management),
        ...cataractConsentFormFields
      )
    );
    // add procedure
    parentRecordConsentFormFields.linked_procedure_id = currentlySelectedExternalProcedure?.id;

    // ACTUAL CONSENT FORM
    const latestConsentFormSimplified = this._filterObj(
      pick(
        cloneDeep(this.latestCataractConsentRecord?.data?.management),
        // fields
        ...cataractConsentFormFields,
      )
    );
    latestConsentFormSimplified.linked_procedure_id =
      this.latestCataractConsentRecord?.data?.linked_procedure?.id ??
      this.latestCataractConsentRecord?.data?.linked_procedure_id;

    // if its the same and a consent form exsits, just allow the user to
    // view the consent form
    // else if different, force them to re-generate a new one with a warning
    this.consentFormDataMatches = isEqual(
      parentRecordConsentFormFields,
      latestConsentFormSimplified
    );


    // debugging
    if (this.debug) {
      const differences = diff(
        parentRecordConsentFormFields,
        latestConsentFormSimplified
      );
      console.log(
        {
          differences,
          parentRecordConsentFormFields,
          latestConsentFormSimplified
        },
        this.consentFormDataMatches
      );
    }
  }

  // reset all changes if it gets too complicated
  resetConsentFormChanges() {
    const cataractConsentFormFields: (keyof GlCataractForm)[] =
      this.ConsentFormHelperService.getCataractConsentFormFields();

    // STEP 1: update correct data
    // fetch latest consent form data 
    const latestConsentFormSimplified = this._filterObj(
      pick(
        this.latestCataractConsentRecord?.data?.management,
        // fields
        ...cataractConsentFormFields,
      )
    );

    // check which keys have been removed
    const removedKeys = cataractConsentFormFields.filter((k) =>
      isNil(this.latestCataractConsentRecord?.data?.management?.[k])
    );

    // find out which fields do not exist
    // reset information
    this.record.data.management = {
      ...this.record.data.management,
      ...latestConsentFormSimplified
    };

    // STEP 2: remove keys from latest consent form record that didnt exist
    if (removedKeys.length) {
      removedKeys.forEach((k) => {
        if (this.record?.data?.management?.[k]) {
          delete this.record?.data?.management?.[k];
        }
      });
    }
    // set procedure
    const replacementProcedure: PatientProcedureExternal =
      this.cataractExternalProcedures.find((p) => p.id ===
        // either use the id from the full procedure or just the id if legacy
        (this.latestCataractConsentRecord?.data?.linked_procedure?.id ??
          this.latestCataractConsentRecord?.data?.linked_procedure_id)
      );

    // replace, if it doesnt exist will just deselect everything
    this.onSelectProcedure(replacementProcedure);

    // changes
    this.isDifferentToLatestConsentForm();
  }

  // basic filter function
  isCataractConsentForm(consentForm: PatientConsentFormRecord) {
    return consentForm?.type === 'consent_form_cataract';
  }

  // get consent form signed state in text
  consentFormStateToText(consentForm: PatientConsentFormRecord) {
    const patientSigned: boolean = this.ConsentFormHelperService.patientSignedConsentForm(
      consentForm
    );
    const userSigned: boolean = this.ConsentFormHelperService.userSignedConsentForm(
      consentForm
    );

    // no signature
    if (!patientSigned && !userSigned) {
      return 'Not Signed';
    } else if (patientSigned && userSigned) {
      return 'Completed';
    } else if (patientSigned) {
      return 'Patient Signed Only';
    } else if (userSigned) {
      return 'Surgeon Signed Only';
    } else {
      return '';
    }
  }

  // get basic summary of consent form procedure
  getConsentFormProcedureSummary(consentForm: PatientConsentFormRecord) {
    let externalProcedure: PatientProcedureExternal =
      consentForm?.data?.linked_procedure as PatientProcedureExternal;

    // else try to find by id 
    const legacyFoundProcedure: PatientProcedureExternal =
      this.externalProcedures?.find((p) => p.id === consentForm?.data?.linked_procedure_id);

    // if the new linked procedure field isnt used and we 
    // found a legacy procedure, use it
    if (isNil(externalProcedure) && legacyFoundProcedure) {
      externalProcedure = legacyFoundProcedure;
    }

    // edge case if procedure isnt found or procedure isnt external
    if (isNil(externalProcedure) || externalProcedure?.type !== 'external') {
      return "Cataract";
    }

    return this.PatientProcedureHelperService.getExternalProcedureSummary(
      externalProcedure
    );
  }

  // must be in edit mode and no signatures present
  canDeleteConsentForm(form: PatientCataractConsentRecord) {
    return this.isEditMode() && !this.eitherSignaturePresent(form);
  }

  // do some extra filtering
  private _filterObj(obj: any) {
    const keys: string[] = Object.keys(obj);
    for (const key of keys) {
      const field = obj[key];
      if (
        // if its empty in ANY sense like this
        // 1. is array and empty
        (isArray(field) && field.every((k) => isNil(k))) ||
        // 2. if its an object and there arent any keys
        (isObject(field) && !isArray(field) && !Object.keys(field)?.length) ||
        // or if its empty or nil
        isNil(field) ||
        isEmpty(field)
      ) {
        // delete
        delete obj[key];
        // otherwise if its a bilateral field
      } else if (!isNil(field?.left) || !isNil(field?.right)) {
        // check L/R recursively
        const _checkedObj = this._filterObj(field);

        // check again
        if (isEmpty(_checkedObj) || isNil(_checkedObj)) {
          delete obj[key];
        }
      }
    }

    // return in the end
    return obj;
  }

  // set focus to trigger gl model
  private _focusBilateralSelectRows() {
    const selectElements = document.querySelectorAll(
      "bilateral-select,gl-select"
    );
    // reverse to avoid it scrolling down
    for (const element of Array.from(selectElements ?? [])) {
      // then do for the select elements
      const selectElements = element.getElementsByTagName("select");
      for (const select of Array.from(selectElements ?? [])) {
        const _s = select as HTMLElement;
        _s.focus({
          preventScroll: true,
        });
      }
    }
  }

  private _updateUserCataractConsentFormSettings() {
    // assign defaults
    const _cataractConsentFormDefaults = {
      fields_to_display: [],
      field_options: {},
      use_by_default: false,
    };
    this.userCataractConsentFormSettings =
      this.user?.data?.cataract_form_settings ?? _cataractConsentFormDefaults;
    defaultsDeep(
      this.userCataractConsentFormSettings,
      _cataractConsentFormDefaults
    );

    // user options
    this._handleUserDefaultOptions();
    // fields to display
    this._handleUserFieldsToDisplay();
    // check options
    this.useUserOptions =
      this?.user?.data?.cataract_form_settings?.use_by_default ?? false;

    // then focus
    this._focusBilateralSelectRows();
  }

  private _handleUserDefaultOptions() {
    // then defaults
    if (
      !isNil(this?.user?.data?.cataract_form_settings?.field_options) &&
      !isEmpty(this?.user?.data?.cataract_form_settings?.field_options)
    ) {
      // assign directly after filtering through existing options only
      set(
        this.userCataractConsentFormSettings,
        "field_options",
        this.appendix.filterCataractOptionsByExisting(
          this?.user?.data?.cataract_form_settings?.field_options
        )
      );
    } else {
      set(this.userCataractConsentFormSettings, "field_options", {});
    }
  }

  private _handleUserFieldsToDisplay() {
    // also for fields to display
    if (
      !isNil(this?.user?.data?.cataract_form_settings?.fields_to_display) &&
      !isEmpty(this?.user?.data?.cataract_form_settings?.fields_to_display)
    ) {
      set(
        this.userCataractConsentFormSettings,
        "fields_to_display",
        this.user?.data?.cataract_form_settings?.fields_to_display
      );
    } else {
      // set for all by default
      set(
        this.userCataractConsentFormSettings,
        "fields_to_display",
        CATARACT_FORM_KEYS.map((k) => k.key)
      );
    }

    // after setting field
    // do a double check and remove data for any
    // disabled field to prevent unwanted carrying over
    // e.g. when defaults are used
    // but only triggers on edit mode
    if (this.isEditMode()) {
      for (const formKey of CATARACT_FORM_KEYS) {
        // if the key exists in current data set it all to null
        if (
          !isNil(this?.record.data?.management?.[formKey.key]) &&
          !this?.userCataractConsentFormSettings?.fields_to_display?.includes(
            formKey?.key
          )
        ) {
          // if either side exsits remove
          delete this?.record.data?.management[formKey.key];
        }
      }
    }
  }

  private _handleConsentFormSaveErrors(error: any) {
    // if its saving related
    const err_message: string = error?.data;
    const status: number = error?.status;

    // check for error types

    // CASE ALREADY SIGNED
    // we check for a very specific error code
    if (status === 422 && err_message.includes('ECFSV_SGN_1')) {
      // 1. set a flag to show a specific error prompt
      this.errorConsentFormAlreadySigned = true;
      // error
      return this.toastr.error(
        this.consentFormMessages.error.consent_form.save.signed
      );
    }

    // else its something generic
    return this.toastr.error("Error saving cataract consent form");
  }
}

export class CataractsSectionComponent implements angular.IComponentOptions {
  static selector = "cataractsSection";
  static template = require("./cataracts-section.html");
  static controller = CataractsSectionController;
  static bindings = {
    user: "<",
    patient: "<",
    record: "<",
    mode: "@",
    enableLeft: "<",
    enableRight: "<",
    isEditable: "<",
    useUserOptions: "=?",

    // toggle external tab state
    toggleTab: "&?",
    externalProcedures: "<",
  };
}
