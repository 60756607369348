import { isFunction, isNil, set } from "lodash";
import { PatientRecordData } from "models/patient-record.model";
import {
  AudioTranscript,
  IAudioTranscriptTemplate,
} from "models/record-transcript.model";
import { Patient } from "models/user.model";
import { API_PATH_v3 } from "../api-paths";

import moment = require("moment");

export class MediaRecordingService {
  static injectionName: string = "MediaRecordingService";

  public audioStream: MediaStream | undefined;
  private mediaRecorder: MediaRecorder;

  // permission modal
  private recordingInProgress: boolean = false;
  private permissionsModalActive: boolean = false;

  apiV3Base = `${this.API_URL}${API_PATH_v3}`;

  constructor(
    private API_URL: string,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $http: angular.IHttpService,
    private toastr: angular.toastr.IToastrService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  // some browsers dont support recording, check in case
  supportsRecording() {
    return !isNil(navigator?.mediaDevices?.getUserMedia);
  }

  /**
   * we could use navigator.persmissions.query
   *
   * but this isn't supported of firefox as of 11/2024
   * https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
   *
   * for firefox we have to detect and use
   * an alternative method
   *
   * so this sorts it out as a universal method
   */
  recordingPermissionGranted() {
    // this is another cheat way to get permissions by
    // just creating a media stream
    // the act of creating will be a way to check if permissions are allowed
    return this.getMediaDevices()
      ?.getUserMedia({
        audio: true,
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(
          "Recording permission denied or not supported, please check your browser's settings"
        );
        return false;
      });
  }

  // just a helper
  getMediaDevices() {
    return navigator.mediaDevices;
  }

  // audio only
  getMediaRecordingPromise(): Promise<MediaStream> {
    // can record?
    if (!this.supportsRecording()) {
      return;
    }

    // else return
    return this.getMediaDevices()?.getUserMedia({
      audio: true,
    });
  }

  // recording?
  isRecording() {
    return this.recordingInProgress;
  }

  // can toggle with a specific state
  // or ignore and flip
  toggleRecordingInProgress(state?: boolean) {
    this.recordingInProgress = state ?? !this.recordingInProgress;
  }

  // can record? its like a guard
  canRecord() {
    if (!this.supportsRecording()) {
      this.toastr.error("Browser does not support recording.");
      return false;
    }

    if (this.recordingInProgress) {
      this.toastr.error(
        "Active recording is in progress, please stop the current recording and try again"
      );
      return false;
    }

    return true;
  }

  // MEDIA RECORDER HANDLERS
  getMediaRecorder() {
    return this.mediaRecorder;
  }

  resetMediaRecorder() {
    // stop whatever you're doing
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }

    // get rid of it
    this.mediaRecorder = undefined;
  }

  async startMediaRecorder(params?: {
    startCallback: (...args: any[]) => void;
    stopCallback: (audioBlob: Blob, ...args: any[]) => void;
  }) {
    // otherwise start recording or try to
    try {
      // check if permission can be granted
      const permissionGranted: boolean =
        await this.recordingPermissionGranted();

      // if persmission not granted, dont bother and open modal
      if (!permissionGranted) {
        return this.openPermissionsModal()
          .result.then(() => {
            //
          })
          .catch(() => {
            //
          });
      }

      // otherwise we try to see if the url allows recording to continue
      // we dont want the recording to start when we are not on the record page
      if (!this.urlAllowsRecording()) {
        return this.toastr.success(
          "Recording permissions granted, recording will automatically start next time."
        );
      }

      // if there isnt an exisitng instance already,
      // create one
      this.initialise(params).then(() => {
        if (this.mediaRecorder) {
          this.mediaRecorder.start();
          this.toggleRecordingInProgress(true);
        }
      });
    } catch (err) {
      // on ANY given error - ignore and just terminate
      this.toggleRecordingInProgress(false);
      this.resetMediaRecorder();
    }
  }

  stopMediaRecorder() {
    this.mediaRecorder?.stop();
    this.audioStream = undefined;
  }

  // BASIC FUNCTIONS
  initialise({
    startCallback,
    stopCallback,
  }: {
    startCallback: (...args: any[]) => void;
    stopCallback: (audioBlob: Blob, ...args: any[]) => void;
  }) {
    // cant record? not my problem
    if (!this.canRecord()) {
      return;
    }

    // bind functions
    const onStart = this._onRecordingInit.bind(this);
    const onError = this._onRecordingError.bind(this);

    // initialise
    return this.getMediaRecordingPromise()
      .then((stream: MediaStream) => {
        // pass callback functions
        onStart({ stream, startCallback, stopCallback });
      }, onError)
      .catch((err) => {
        console.error(err);
        this.toastr.error(err);
        this.toggleRecordingInProgress(false);
      });
  }

  // PERMISSIONS MODAL
  isPermissionsModalActive() {
    return this.permissionsModalActive;
  }

  togglePermissionsModal(state?: boolean) {
    return (this.permissionsModalActive =
      state ?? !this.permissionsModalActive);
  }

  // setters
  setStartingTimestamp(transcript: Partial<IAudioTranscriptTemplate>) {
    set(transcript, "time_started", moment().toISOString(true));
  }

  // this is usually called as a callback
  // a helper function that can be attached to an instance
  finaliseRecording(transcript: Partial<IAudioTranscriptTemplate>) {
    // set time ended
    set(transcript, "time_ended", moment().toISOString(true));
    // set path based on audio blob
    const audioUrl: string = URL.createObjectURL(transcript?.recording);
    set(transcript, "path", audioUrl);

    return transcript;
  }

  // activate the modal
  openPermissionsModal() {
    if (this.permissionsModalActive) {
      return;
    }

    // active
    this.permissionsModalActive = true;

    // declare modal
    const modal: angular.ui.bootstrap.IModalInstanceService =
      this.$uibModal.open({
        component: "mediaPermissionsModal",
        keyboard: false,
        backdrop: "static",
      });

    // open modal
    modal.result.finally(() => {
      this.permissionsModalActive = false;
    });

    return modal;
  }

  // API RELATED
  getRecordings({
    patientId,
    recordId,
  }: {
    patientId: number;
    recordId: number;
  }) {
    const url = `${this.apiV3Base}/patient/${patientId}/record/${recordId}/transcript`;
    return this.$http
      .get<AudioTranscript>(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  // upload
  uploadLatestRecording({
    transcript,
    patient,
    recordId,
    recordDataDiff,
  }: {
    transcript: IAudioTranscriptTemplate;
    patient: Patient;
    recordId: number;
    recordDataDiff: PatientRecordData;
  }) {
    // if no audio recording exsits, ignore
    // if details are missing, return
    if (
      [transcript?.recording, patient, recordId, recordDataDiff].some((o) =>
        isNil(o)
      )
    ) {
      return;
    }

    const { recording, time_started, time_ended } = transcript;

    // else create metadata
    const formData: FormData = new FormData();
    formData.append("audio_file", recording);

    // ids
    formData.append("patient_id", String(patient.id));
    formData.append("record_id", String(recordId));

    // timestamps
    formData.append("time_started", time_started);
    formData.append("time_ended", time_ended);

    // stringify data diff
    formData.append("record_data_diff", JSON.stringify(recordDataDiff));

    // post to endpoint
    const url = `${this.apiV3Base}/transcript`;
    return this.$http
      .post<any>(url, formData, {
        headers: { "Content-Type": undefined },
      })
      .then((res: any) => {
        return res.data;
      });
  }

  // upload
  updateLatestRecording({
    transcriptId,
    transcript,
    patient,
    recordId,
    recordDataDiff,
  }: {
    // transcript id of existing object
    transcriptId: number;
    // new audio file
    transcript: IAudioTranscriptTemplate;
    patient: Patient;
    recordId: number;
    recordDataDiff: PatientRecordData;
  }) {
    // if no audio recording exsits, ignore
    // if details are missing, return
    if (
      [transcript?.recording, patient, recordId, recordDataDiff].some((o) =>
        isNil(o)
      )
    ) {
      return;
    }

    const { recording, time_started, time_ended } = transcript;

    // else create metadata
    const formData: FormData = new FormData();
    formData.append("audio_file", recording);

    // timestamps
    formData.append("time_started", time_started);
    formData.append("time_ended", time_ended);

    // stringify data diff
    formData.append("record_data_diff", JSON.stringify(recordDataDiff));

    // post to endpoint
    const url = `${this.apiV3Base}/transcript/${transcriptId}`;
    return this.$http
      .put<any>(url, formData, {
        headers: { "Content-Type": undefined },
      })
      .then((res: any) => {
        return res.data;
      })
      .catch((err) => console.error(err));
  }

  // test uploading to see if it works
  testUpload(params: {
    patient: Patient;
    recordId: number;
    recordDataDiff: PatientRecordData;
  }) {
    const audioFile = new Blob([""], { type: "audio/webm" });
    const testTime = moment();

    // setup mock
    const mockTransript: IAudioTranscriptTemplate = {
      recording: audioFile,
      time_started: testTime.subtract(10, "minutes").toISOString(true),
      time_ended: testTime.toISOString(true),
    };

    this.uploadLatestRecording({
      transcript: mockTransript,
      ...params,
    });
  }

  // see if current URL allows for recordings to start,
  // this avoids issues with recordings
  // initialising by just allowing permissions
  // currenty its set allow for patient record pages only
  urlAllowsRecording() {
    return /#\/patient\/\d+\/record\/\d+/.test(this.$window.location.hash);
  }

  /* HANDELRS */
  // this is more of a start
  private _onRecordingInit({
    stream,
    startCallback,
    stopCallback,
  }: {
    stream: MediaStream;
    startCallback: (...args: any[]) => void;
    stopCallback: (audioBlob: Blob, ...args: any[]) => void;
  }) {
    // initialise
    this.mediaRecorder = new MediaRecorder(stream, {
      mimeType: "audio/webm", // smaller size and quality
    });

    // data source
    let chunks = [];
    // else carry over
    const mediaRecorder = this.mediaRecorder;

    // 1. setup event listeners
    // add event listeners for when we have chunsk
    mediaRecorder.ondataavailable = (e: any) => {
      this._onDataAvailable(e, chunks);
    };

    mediaRecorder.onstart = () => {
      // this should start a timer to track progress
      this.toggleRecordingInProgress(true);
      this.toastr.success("Recording has started.");

      // callback
      if (isFunction(startCallback)) {
        startCallback();
      }

      // capture context
      this.audioStream = stream;
    };

    // stop workflow (e.g. what happens after)
    mediaRecorder.onstop = (e) => {
      const audioBlob: Blob = this._onRecordingStop(e, chunks);
      chunks = [];
      // a function that is passed down
      // usually to call something to set
      // the final recording somewhere else
      if (isFunction(stopCallback)) {
        stopCallback(audioBlob);
      }

      // remove context
      this.audioStream = undefined;
    };
  }

  private _onRecordingError(e: any) {
    console.error(e);
  }

  // on data available
  // this is called once the data is finalised
  private _onDataAvailable(e: any, chunks: any[]) {
    if (e?.data?.size > 0) {
      chunks.push(e?.data);
    }
  }

  private _onRecordingStop(_e: any, chunks: any[]) {
    // e.g. send to s3
    // save as blob...
    this.toastr.success("Recording ended.");
    this.toggleRecordingInProgress(false);

    // convert to blob
    const audioBlob = new Blob(chunks, { type: this.mediaRecorder.mimeType });

    // audio recording url for reviewing
    // const audioUrl = URL.createObjectURL(audioBlob);

    // return the audio blob after
    return audioBlob;
  }
}
