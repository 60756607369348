import { IOnChangesObject } from "angular";
import { AlertHelperService } from "app/core/services/alert-helper.service";
import { GlAlertLevel } from "models/alert.model";
import { PatientRecord } from "models/patient-record.model";
import { Patient } from "models/user.model";

class DashboardPatientAlertIconController
  implements angular.IComponentController, angular.IOnChanges
{
  levels: GlAlertLevel[] = [];
  patient: Patient;
  record: PatientRecord;

  formattedAllergies: string[];

  constructor(private AlertHelperService: AlertHelperService) {
    "ngInject";
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes?.record) {
      this.formatAllergiesIntoArray();
    }
  }

  shouldShowAlertIcon(): boolean {
    // has alerts of specified level type
    // or rejected consent questions
    // or allergies
    return this.AlertHelperService.shouldShowAlertsByAlertLevel(
      this.patient,
      this.levels ?? [],
      this.record?.data?.allergies_list
    );
  }

  getAllergiesList() {
    return this?.record?.data?.allergies_list;
  }

  formatAllergiesIntoArray() {
    const allergiesList: string = this.getAllergiesList();
    // split
    this.formattedAllergies = allergiesList?.split(/\r?\n/g) ?? [];
  }
}

export class DashboardPatientAlertIcon {
  static selector = "dashboardPatientAlertIcon";
  static template = require("./dashboard-patient-alert-icon.html");
  static controller = DashboardPatientAlertIconController;
  static bindings = {
    levels: "<?",
    patient: "<",
    record: "<",
  };
}
