import { extend } from "angular";
import { chunk, join, map, partition } from "lodash";
import { IGlApiResponse } from "models/gl-api-response.model";
import { PatientProcedure } from "models/patient-procedure";
import {
  GlPrescription,
  GlPrescriptionDrugData,
} from "models/prescription.model";
import { DrugDbRecordV2ExtensionDecorator } from "models/user-favourite-drugs";
import { GlStaff, GlUserTypeString, Patient, User } from "models/user.model";
import { AddressHelperService } from "../AddressHelperService/address.helper.service";
import { API_PATH_v2 } from "../api-paths";
import { Appendix } from "../appendix";
import { PdfPrintService } from "../pdf-print.service";

export interface IGlPrintPrescriptionArgs {
  drugs: GlPrescriptionDrugData[];
  patient: Patient;
  prescriber: GlStaff;
  prescription: GlPrescription;
}

export interface IPrescriptionsRequest {
  record_id?: number;
  patient_id: number;
  treatment_ids?: number[] | string[];
  data: GlPrescriptionDrugData[];
  authority_number?: string;
}

export class PrescriptionsService {
  static injectionName = "PrescriptionsService";
  PRESCRIPTIONS_CHUNK_SIZE: number =
    this.appendix.getDrugsPerPrescriptionChunk();
  apiV2Base = `${this.API_URL}${API_PATH_v2}`;

  private prescriptionTemplate = require("./new-prescription-template.html");
  private prescriptions: GlPrescription[];

  constructor(
    private API_URL: string,
    private $http: angular.IHttpService,
    private $compile: angular.ICompileService,
    private $q: angular.IQService,
    private $rootScope: angular.IRootScopeService,
    private $timeout: angular.ITimeoutService,
    private appendix: Appendix,
    private PdfPrintService: PdfPrintService,
    private AddressHelperService: AddressHelperService
  ) {
    "ngInject";
  }

  reset() {
    this.prescriptions = [];
  }

  getPrescriptions() {
    return this.prescriptions;
  }

  // optoms and ophthals can prescribe only
  canPrescribe(prescriber: User) {
    const prescriberTypes: GlUserTypeString[] = [
      "optometrist",
      "ophthalmologist",
    ];
    return prescriberTypes.includes(prescriber?.type.name);
  }

  // PRESCRIPTIONS API
  fetchPrescriptions(patient_id: number) {
    return this.$http
      .get<IGlApiResponse<GlPrescription[]>>(
        `${this.apiV2Base}/prescriptions?patient_id=${patient_id}`
      )
      .then((response) => {
        this.prescriptions = response.data.data;
        return response.data.data;
      });
  }

  createPrescription(params: IPrescriptionsRequest) {
    return this.$http
      .post<GlPrescription>(`${this.apiV2Base}/prescriptions`, {
        ...params,
        data: this._trimPrescriptionRequestDataTextFields(params.data),
      })
      .then((response) => {
        return response.data;
      });
  }

  updatePrescription(prescription_id: number, params: IPrescriptionsRequest) {
    return this.$http
      .put<GlPrescription>(
        `${this.apiV2Base}/prescriptions/${prescription_id}`,
        {
          ...params,
          data: this._trimPrescriptionRequestDataTextFields(params.data),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  deletePrescription(prescription_id: number) {
    return this.$http
      .delete(`${this.apiV2Base}/prescriptions/${prescription_id}`)
      .then((response) => {
        return response;
      });
  }

  // format
  formatPrescriptionNumber(prescriptionNumber: number, digits: number = 8) {
    return prescriptionNumber.toString().padStart(digits, "0");
  }

  formatAuthorityPrescriptionNumber(prescriptionNumber: number) {
    const checksum: number = String(prescriptionNumber)
      .split("")
      .reduce((sum, curr) => Number(curr) + sum, 0);
    return this.formatPrescriptionNumber(
      Number(`${prescriptionNumber}${checksum % 9}`)
    );
  }

  // filters
  filterAuthorityDrugs(drugs: PatientProcedure[]) {
    return drugs.filter(
      (drug: PatientProcedure) =>
        drug.data.authority_script && !drug.data?.private
    );
  }

  // chunks by authority then if authority, individual else regular is up to max of 5
  chunkProcedureDrugs(
    drugs: PatientProcedure[],
    chunkSize: number = this.PRESCRIPTIONS_CHUNK_SIZE
  ) {
    const partitioned: PatientProcedure[][] = partition(
      drugs,
      function (drug: PatientProcedure) {
        return drug.data.authority_script && !drug.data.private;
      }
    );
    return [...chunk(partitioned[0], 1), ...chunk(partitioned[1], chunkSize)];
  }

  chunkPrescriptionDrugs(
    drugs: GlPrescriptionDrugData[],
    chunkSize: number = this.PRESCRIPTIONS_CHUNK_SIZE
  ) {
    const partitioned: GlPrescriptionDrugData[][] = partition(
      drugs,
      function (drug: GlPrescriptionDrugData) {
        return drug.authority_script && !drug?.private;
      }
    );
    return [...chunk(partitioned[0], 1), ...chunk(partitioned[1], chunkSize)];
  }

  filterPrescriptionsByRecordId(
    recordId: number,
    prescriptions: GlPrescription[]
  ) {
    if (!prescriptions) {
      return [];
    }
    if (!recordId) {
      return prescriptions;
    }
    return (
      prescriptions.filter((p: GlPrescription) => p.record_id === recordId) ??
      []
    );
  }

  // PDF print arguments
  preview(args: IGlPrintPrescriptionArgs) {
    return this.compilePrescriptionHtml(args);
  }

  pdf({ drugs, patient, prescriber, prescription }: IGlPrintPrescriptionArgs) {
    return this.compilePrescriptionHtml({
      drugs,
      patient,
      prescriber,
      prescription,
    }).then((html) => {
      return this.PdfPrintService.print({
        headerData: "",
        footerData: "",
        background: true,
        pageData: html,
        margin: {
          left: "0mm",
          right: "0mm",
          top: "0mm",
          bottom: "0mm",
        },
      });
    });
  }

  // convert pbs api to current model
  convertPbsApiModelToGlDrug(
    drug: DrugDbRecordV2ExtensionDecorator
  ): GlPrescriptionDrugData {
    return {
      id: drug.li_item_id, // v2
      brand_name: drug.brand_name,
      item_code: drug.pbs_code,
      program_code: drug.program_code,
      rstr_flag: drug?.benefit_type_code,
      mq: drug?.maximum_quantity_units,
      repeats: drug?.number_of_repeats,
      pack_size: drug?.pack_size ? String(drug?.pack_size) : null,
      tpuu_or_mpp_pt: drug?.schedule_form,
      mp_pt: drug?.drug_name,
      dose: 1,

      // ignore li_form for now as schedule_form is more descriptive
      li_form: drug?.li_form,
      li_drug_name: drug?.li_drug_name,

      authority_script: this.isAuthorityDrug(drug?.benefit_type_code),
      treatment_of_code: drug?.treatment_of_code
        ? String(drug?.treatment_of_code)
        : null,
      restriction_flag: this.isAuthorityDrug(drug?.benefit_type_code)
        ? "A"
        : null,
      // if restriction text exists use that, else take prescribing texts
      // then join them
      restriction_text: drug?.restriction_text
        ? drug.restriction_text
        : drug?.prescribing_texts?.length
          ? join(
            map(drug?.prescribing_texts, (t) => t.prescribing_txt),
            ". "
          )
          : null,

      drug_type: drug?.drug_type ?? null,
      version: drug?.version, // v2 to associate

      // also consider favourites
      favourite: drug?.favourite,
      favourite_groups: drug?.favourite_groups,
    };
  }

  isAuthorityDrug(restrictionFlag: string) {
    return ["R", "A", "S"].includes(restrictionFlag);
  }

  private _convertEyeSideHelper(side: string) {
    switch (side) {
      case "right":
        return "Right Eye";
      case "left":
        return "Left Eye";
      case "both":
        return "Both Eyes";
      default:
        return side;
    }
  }

  private _trimPrescriptionRequestDataTextFields(
    data: IPrescriptionsRequest["data"]
  ) {
    return data.map((d: GlPrescriptionDrugData) => {
      return {
        ...d,
        restriction_text: d?.restriction_text?.trim() ?? null,
        instructions: d?.instructions?.trim() ?? null,
        tpuu_or_mpp_pt: d?.tpuu_or_mpp_pt?.trim() ?? null,
      };
    });
  }

  private compilePrescriptionHtml({
    drugs,
    patient,
    prescriber,
    prescription,
  }: IGlPrintPrescriptionArgs) {
    return this.$q.resolve().then(() => {
      const scope = this.$rootScope.$new(true);
      const clinicDetails = prescriber.auxiliary_clinics.find(
        (clinic) => prescriber.clinic_id === clinic.id
      );
      const prescriber_number =
        clinicDetails?.pivot?.data?.provider_number || "";
      const address = clinicDetails?.pivot?.data?.prescription_address || "";

      // INCLUDE EDGE CASE WHERE ADDRESS MIGHT NOT SHOW
      if (patient.data?.address?.address_components) {
        const { line_1, postal_code, suburb, state } =
          this.AddressHelperService.getAddressFromGoogleAddress(
            patient.data.address
          );
        patient.data.address_line1 = line_1;
        patient.data.postcode = postal_code;
        patient.data.suburb = suburb;
        patient.data.state = state;
      }

      extend(scope, {
        drugs,
        patient,
        prescriber,
        prescription,

        date: new Date(),
        prescriber_number,
        address: address.replace(/\n/, "<br/>"),
        getDropDescription: this.appendix.getTreatmentPrescriptionText,
        formatAuthorityPrescriptionNumber:
          this.formatAuthorityPrescriptionNumber,
        formatPrescriptionNumber: this.formatPrescriptionNumber,
      });

      const compiledTemplate = this.$compile(
        `<div>${this.prescriptionTemplate}</div>`
      )(scope);

      return this.$timeout().then(() => {
        return compiledTemplate.html();
      });
    });
  }
}
