import { IOnChangesObject, isFunction } from "angular";
import { AlertHelperService } from "app/core/services/alert-helper.service";
import { GlAlert } from "models/alert.model";
import { IGlFormMode } from "models/gl-form-mode";
import { Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

export type PatientAlertsTarget = "patient_info" | "record";

export class PatientAlertsController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  patient: Patient;
  mode: IGlFormMode;

  // display for alerts on record pages are different
  target: PatientAlertsTarget = "patient_info";

  // patient alert selections
  patientAlertCategoryOptions =
    this.AlertHelperService.getAlertCategoryOptionsByKey("patient");
  patientAlertSeverityOptions =
    this.AlertHelperService.getGenericAlertLevelValues();

  // allergies from medical history
  allergiesList: string;

  // alerts
  patientAlerts: GlAlert[]; // from the patient object (for onchanges)
  patientAllergiesAsAlerts: GlAlert[]; // allergies as alerts (formatted)
  alertsToDisplay: GlAlert[]; // combined

  // callbacks
  onInsert: (...args: any[]) => void;
  onRemove: (...args: any[]) => void;

  constructor(private AlertHelperService: AlertHelperService) {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    // to help with combining allergies into alerts
    if (changes?.allergiesList) {
      this.formatAllergiesToAlerts();
      this.combineAlerts();
    }

    // any changes to patient alerts? update
    if (changes?.patientAlerts) {
      this.combineAlerts();
    }
  }

  // either patient_info or something not recognised
  isPatientInfoTarget() {
    return (
      this.target === "patient_info" ||
      !["patient_info", "record"].includes(this.target)
    );
  }

  // mostly for record pages and cosnent pages
  isRecordTarget() {
    return this.target === "record";
  }

  // add rows
  insertRow(index: number) {
    this.patient.data.alerts.splice(index + 1, 0, {});

    // callback
    if (isFunction(this.onInsert)) {
      this.onInsert();
    }
  }

  removeRow(index: number) {
    this.patient.data.alerts.splice(index, 1);

    // callback
    if (isFunction(this.onRemove)) {
      this.onRemove();
    }
  }

  // show patient alerts?
  showPatientAlerts() {
    return this.AlertHelperService.shouldShowAlerts(this.patient);
  }

  private formatAllergiesToAlerts() {
    // split linebreaks
    // filter out empty strings
    const formatted = (this.allergiesList?.split(/\r?\n/g) ?? []).filter(
      (a) => a?.length > 0
    );

    // then convert to alerts
    this.patientAllergiesAsAlerts = formatted?.map((allergy) => {
      return {
        type: "Allergy",
        level: "High",
        message: allergy,
      };
    });
  }

  private combineAlerts() {
    this.alertsToDisplay = [
      ...(this.patientAllergiesAsAlerts ?? []),
      ...(this.patient?.data?.alerts ?? []),
    ];
  }
}

export class PatientAlerts implements angular.IComponentOptions {
  static selector = "patientAlerts";
  static template = require("./patient-alerts.html");
  static controller = PatientAlertsController;
  static bindings = {
    patient: "<",
    mode: "@",
    target: "@?",
    onInsert: "&",
    onRemove: "&",
    patientAlerts: "<",
    allergiesList: "<",
  };
}
