import { User, GlUserTypeString } from "../../../../../models/user.model";
import { isFunction } from "angular";
import { GlPatientRecordWorkflowState, PatientRecord } from "../../../../../models/patient-record.model";

export class TechButtonController implements angular.IController {
  user: User;
  gotoState: ({ state }: { state: string; }) => void;
  record: PatientRecord;
  disableSave: boolean = true;

  // button functions
  completeRecord: () => void;
  reopenRecord: () => void;
  signRecord: () => void;
  save: () => void;

  constructor() {
    "ngInject";
  }

  userIsOptometrist() {
    return !!(this.user.type.name === "optometrist");
  }

  userIsAdministrator() {
    return !!this.user && this.user.type.name === "administrator";
  }

  canSign() {
    const allowedUserTypes: GlUserTypeString[] = [
      "ophthalmologist",
      "optometrist",
      "technician",
    ];
    return allowedUserTypes.includes(this.user.type.name);
  }

  fromSameClinic() {
    return (
      !!this.record &&
      !!this.user &&
      this.record.clinic.id === this.user.clinic.id
    );
  }

  canReOpen() {
    const authorizedUserTypes: GlUserTypeString[] = [
      "technician",
      "ophthalmologist",
      "optometrist",
    ];
    return (
      this.fromSameClinic() && authorizedUserTypes.includes(this.user.type.name)
    );
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  saveAndProgressClicked(userType: string) {
    // previously it just set the user type as the workflow state
    // now we can integrate it based on the 
    // current existing workflow state OR presenting complaint by default

    // Admin, Ophthal, Optom, Toy Room, Other
    const shorthandWorkflowState: string =
      this.getUserTypeShorthand(userType);
    // the usual codes like C, INJ etc...
    const shorthandCondition: string =
      this.getCurrentState(this.record.workflow_state);

    // call afterwards
    if (isFunction(this.gotoState)) {
      this.gotoState({ state: `${shorthandWorkflowState} (${shorthandCondition})` });
    }
  }

  // fetches current workflow state based on the presenting condition
  // of the record
  getCurrentState(workflowState: GlPatientRecordWorkflowState) {
    const re = /\((.*)\)/i;
    const wfs = workflowState?.match(re)?.[1];

    // there is a chance it could be set before
    const knownStateShorthands = [
      "INJ", "R", "G", "C", "DE", "O"
    ];

    // if workflow state isnt set beforehand and we dont 
    // get a match from above
    if (!wfs || !knownStateShorthands.includes(wfs)) {
      // default to the presenting complaint
      return this.getPresentingComplaintShorthandByKey(this?.record?.data?.presenting_complaint?.key);
    }

    // else if all ok, return the wfs shorthand by default
    return wfs;
  }

  getPresentingComplaintShorthandByKey(key: string) {
    switch (key) {
      case "glaucoma":
        return "G";
      case "retina":
        return "R";
      case "cataract":
        return "C";
      case "dry_eye":
        return "DE";
      default:
        return "O";
    }
  }

  getUserTypeShorthand(userType: string) {
    switch (userType) {
      case 'ophthalmologist':
        return 'Ophthal';
      case 'optometrist':
        return 'Optom';
      case 'toyroom':
        // technician is orthoptist
        return 'Toy Room';
      case 'administrator':
        return 'Admin';
      default:
        return 'Other';
    }
  }
}

export class TechButtonProgressComponent implements angular.IComponentOptions {
  static selector = "techButtonProgress";
  static transclude = {
    sendToButtons: "?sendToButtons",
  };
  static template = require("./tech-button-progress.html");
  static controller = TechButtonController;
  static bindings = {
    showLoader: "<",
    gotoState: "&",
    reopenRecord: "&",
    completeRecord: "&",
    signRecord: "&",
    save: "&",
    user: "<",
    record: "<",
    disableSave: "<?",
    lockInProgress: "<",
    reopenInProgress: "<",
    saveInProgress: "<",
  };
}
